import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Button, Icon, Select, Textarea } from "react-materialize";
import { useParams } from "react-router-dom";
import RequestService from "../../../../api/RequestService";
import { AuthContext } from "../../../../context";
import { parseForm } from "../../../../utils/service";
import useConfirm from "../../../../context/ConfirmDialog";

const NewComplaint = ({ targetObject = null }) => {
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const params = useParams();
  const confirm = useConfirm();
  const [objectOptions, setObjectOptions] = useState([]);

  useEffect(() => {
    getComplaintReasons();
  }, []);

  const getComplaintReasons = async () => {
    let response = (await RequestService.getComplaintReasons()).data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }
    let complaintsData = response.result;
    let options = [];
    complaintsData.forEach((complaint) => {
      options.push(
        <option value={complaint.id} key={complaint.id}>
          {complaint.reason}
        </option>
      );
    });

    setObjectOptions(options);
  };

  const createComplaint = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    sendFormData.append("object", targetObject ? targetObject : params.id);
    // for (let pair of sendFormData.entries()) {
    //   console.log(pair);
    // }
    const response = (
      await RequestService.sendForm(sendFormData, "/complaints/create")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      // alert(response.message);
      await confirm({
        description: response.message,
        isAlert: true,
      });
      return;
    }

    await confirm({
      description:
        "Жалоба отправлена и будет принята во внимание. Администрация сервиса оставляет за собой право действовать на свое усмотрение по отношению к объекту жалобы",
      isAlert: true,
    });
    // alert(
    //   "Жалоба отправлена и будет принята во внимание. Администрация сервиса оставляет за собой право действовать на свое усмотрение по отношению к объекту жалобы"
    // );
    window.location.reload();
  };

  return (
    <div>
      <form id="new-complaint" onSubmit={createComplaint}>
        <div className="row">
          <Select
            id="reason_id"
            name="reason_id"
            s={12}
            icon={<Icon>checklist</Icon>}
            label={"Причина жалобы"}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                closeOnClick: true,
                constrainWidth: true,
              },
            }}
            // value={objectId}
          >
            {objectOptions}
          </Select>

          <Textarea
            id="comment"
            name="comment"
            label={"Ваш комментарий"}
            icon={<Icon>description</Icon>}
            placeholder={"Необязательно"}
            s={12}
            // value={packageDescription}
            onChange={(e) => {
              //   setPackageDescription(e.target.value);
            }}
            style={{ marginBottom: "0px" }}
          />

          <div className="col s12 center-align">
            <Button flat className="iq-btn" type="submit">
              Отправить
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewComplaint;
