import React from "react";
import { useNavigate } from "react-router-dom";
import noImage from "../../../../images/no-image.png";
import { truncate } from "../../../../utils/service";

const BookmarkItem = ({ bookmark, onSurface }) => {
  const navigate = useNavigate();
  // console.log(project);

  return (
    <div className="row nm">
      <div className="col s12">
        <div
          className={
            onSurface
              ? "card mb30 hand-cursor bg animation-appear"
              : "card mb30 hand-cursor animation-appear"
          }
          onClick={() => {
            navigate("/" + bookmark.object_type + "s/" + bookmark.object_id);
          }}
        >
          <div className="card-content">
            <div className="row nm">
              <div className="col s12">
                <img
                  src={
                    bookmark.cover_img_url ? bookmark.cover_img_url : noImage
                  }
                  className={
                    !bookmark.cover_img_url ? "image-theme-filter" : null
                  }
                  alt="Обложка"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col s12 mt10">
                {bookmark.object_type === "event"
                  ? "Событие"
                  : bookmark.object_type === "project"
                  ? "Проект"
                  : bookmark.object_type === "user"
                  ? "Пользователь"
                  : "Неизвестно"}
              </div>
              <div className="col s12 l500 mt10">{bookmark.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookmarkItem;
