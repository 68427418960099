import React from "react";
import { useState, useEffect, useRef } from "react";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import RequestService from "../api/RequestService";
import ChatList from "../components/UI/ChatList/ChatList";
import useConfirm from "../context/ConfirmDialog";

const Messages = () => {
  const [chats, setChats] = useState([]);
  const [chatPage, setChatPage] = useState(1);
  const [chatsData, setChatsData] = useState(null);
  const lastChatElement = useRef();
  const confirm = useConfirm();

  const getUserChats = async (page = 1) => {
    if (chatsData && page > chatsData.total_pages) return;
    const response = (await RequestService.getChats(page)).data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }
    setChatsData(response.result);
    setChats([...chats, ...response.result.elements]);
  };

  const [fetchChats, isChatsLoading, chatsError] = useFetching(getUserChats);

  useEffect(() => {
    // console.log(projectPage);
    fetchChats(chatPage);
  }, [chatPage]);

  useObserver(
    lastChatElement,
    chatsData && chatPage < chatsData.total_pages,
    isChatsLoading,
    () => {
      setChatPage(chatPage + 1);
    }
  );

  return (
    <div>
      <RegularPage
        header={"Сообщения"}
        isDataExist={chatsData}
        isPrimaryPage={true}
        hasBaseCard={false}
      >
        <ChatList chats={chats} />
      </RegularPage>
      <div ref={lastChatElement}></div>
    </div>
  );
};

export default Messages;
