import moment from "moment";
import React from "react";
import { Button, Icon } from "react-materialize";
import { useNavigate } from "react-router-dom";
import { getSelectValues, parseForm, toDate } from "../../../utils/service";

const Filter = ({ title, resetPage, resetForm, classes, children }) => {
  const navigate = useNavigate();

  const setQueryFilters = (e) => {
    e.preventDefault();

    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    let urlSearchParams = new URLSearchParams();

    // ------
    // найдем названия полей, которые заполнены
    let filledParameters = [];
    for (let pair of sendFormData.entries()) {
      if (pair[0].split("-")[0] == "filter" && pair[1]) {
        // console.log(pair);
        filledParameters.push(pair[0]);
      }
    }
    // console.log(filledParameters);
    // чтобы определить типы полей (дата/массив - для массива нужна обработка ноды)
    let filledElements = [];
    filledParameters.forEach((param) => {
      filledElements.push(htmlForm.querySelector("#" + param));
    });
    // console.log(filledElements);

    // теперь с учетом типа поля нужно обработать поля и собрать в строку параметров
    filledElements.forEach((element) => {
      let elType = element.getAttribute("data-type");
      let elId = element.getAttribute("id");
      let elParamName = elId.split("-")[1];
      if (elType === "date") {
        urlSearchParams.append(
          elParamName,
          moment(toDate(element.value)).format("YYYY-MM-DD")
        );
      } else if (elType === "array") {
        let elArr = getSelectValues(element);
        // console.log(elArr);
        // console.log(interests);
        elArr.forEach((elArrItem) => {
          // for (let i = 0; i < interests.length; i++) {
          // if (elArrItem == interests[i].id) {
          urlSearchParams.append(elParamName + "[]", elArrItem);
          // break;
          // }
          // }
        });
      } else if (elType === "checkbox") {
        urlSearchParams.append(elParamName, element.checked ? 1 : 0);
      } else {
        urlSearchParams.append(elParamName, element.value);
      }
    });

    // console.log(urlSearchParams.toString());

    // let fields = htmlForm.getElementsByTagName("input");
    // console.log(value);
    // for (let item of inputs) {
    //   if (item.getAttribute("type") != "checkbox" && item.value) {
    //     console.log(item);
    //   }
    // }

    // if (htmlForm["filter-format"].value) {
    //   urlSearchParams.append("format", htmlForm["filter-format"].value);
    // }
    // if (htmlForm["filter-location"].value) {
    //   urlSearchParams.append("location", htmlForm["filter-location"].value);
    // }
    // if (htmlForm["filter-start_date"].value) {
    //   urlSearchParams.append(
    //     "start_date",
    //     moment(toDate(htmlForm["filter-start_date"].value)).format("YYYY-MM-DD")
    //   );
    // }
    // if (htmlForm["filter-end_date"].value) {
    //   urlSearchParams.append(
    //     "end_date",
    //     moment(toDate(htmlForm["filter-end_date"].value)).format("YYYY-MM-DD")
    //   );
    // }

    // if (htmlForm["filter-interests"].value) {
    //   let filterFrontInterestsArr = getSelectValues(
    //     htmlForm["filter-interests"]
    //   );
    //   // console.log(filterFrontInterestsArr);
    //   // console.log(interests);

    //   filterFrontInterestsArr.forEach((interestId) => {
    //     for (let i = 0; i < interests.length; i++) {
    //       if (interestId == interests[i].id) {
    //         urlSearchParams.append("interests[]", interests[i].name);
    //         break;
    //       }
    //     }
    //   });
    // }

    // console.log(urlSearchParams.toString());
    // setEvents([]);
    // setEventsData(null);
    resetPage();
    navigate("?" + urlSearchParams.toString());
  };

  return (
    <div className={classes ? classes : ""}>
      <form id="set-filter" onSubmit={setQueryFilters}>
        <div className="row mt20">
          <div className="col s12">
            <div className="card">
              <div className="card-content">
                <div className="row nm">
                  <div className="col s12 l500 mb15">{title}</div>
                  {children}

                  <div className="col s12 l4 center-align mt20">
                    <Button className="iq-btn" style={{ marginRight: "10px" }}>
                      Применить фильтр
                    </Button>
                    <Button
                      type="button"
                      className="iq-btn"
                      onClick={() => {
                        // document.querySelector("#set-filter").reset();
                        resetForm();
                        navigate("");
                      }}
                    >
                      <Icon>filter_alt_off</Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Filter;
