import React, { useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { useSearchParamsArray } from "../hooks/useSearchParamsArray";
import { AuthContext } from "../context";
import RequestService from "../api/RequestService";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import EventTiles from "../components/UI/Event/EventTiles";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import {
  TextInput,
  DatePicker,
  Autocomplete,
  Button,
  Icon,
  Select,
} from "react-materialize";
import M from "materialize-css";
import moment from "moment";
import { parseForm, toDate, getSelectValues } from "../utils/service";
import Filter from "../components/UI/Filter/Filter";

const EventsFlow = () => {
  const [eventsData, setEventsData] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventPage, setEventPage] = useState(1);
  const lastEventElement = useRef();
  const navigate = useNavigate();
  const { isAuth, setIsAuth, isLoading, setLoading } = useContext(AuthContext);
  const searchParamsArray = useSearchParamsArray();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cityTitle, setCityTitle] = useState("Город проведения");
  const [locationOptions, setLocationOptions] = useState({ тест: null });
  const [locationValue, setLocationValue] = useState("");
  const [interestOptions, setInterestOptions] = useState(null);
  const [interestIds, setInterestIds] = useState([""]);
  const [eventFormat, setEventFormat] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [interests, setInterests] = useState([]);

  const [selectState, setSelectState] = useState(null);

  const getEvents = async (page = 1, fromScratch = false) => {
    try {
      if (!fromScratch && eventsData && page > eventsData.total_pages) return;
      const filterParams = searchParamsArray.array;
      const urlSearchParams = new URLSearchParams();
      filterParams.forEach((filterParam) => {
        urlSearchParams.append(filterParam[0], filterParam[1]);
      });

      // console.log(filterParams);
      // console.log(urlSearchParams.toString());

      let response = (
        await RequestService.getEvents(null, page, urlSearchParams.toString())
      ).data; //
      response.result.elements.forEach((element) => {
        element.participation_packages = element.participation_packages.filter(
          (p) => !p.deleted_at
        );
      });
      // console.log(response);
      if (!response.response_code) {
        console.error(response);
        setPageError(response.message);
        return;
      }
      setPageError(null);
      setEventsData(response.result);
      let newEvents = fromScratch
        ? [...response.result.elements]
        : [...events, ...response.result.elements];
      setEvents(newEvents);
    } catch (error) {
      console.log(error);
      setPageError(error);
    }
  };

  const setFrontFilters = async () => {
    let interestsArray = await getInterests();
    // console.log(interestsArray);

    const filterParams = searchParamsArray.array;
    const urlSearchParams = new URLSearchParams();
    let tempInterestsIds = [];
    filterParams.forEach((filterParam) => {
      switch (filterParam[0]) {
        case "format":
          setEventFormat(filterParam[1]);
          break;

        case "location":
          setEventLocation(filterParam[1]);
          break;

        case "start_date":
          setEventStartDate(filterParam[1]);
          break;

        case "end_date":
          setEventEndDate(filterParam[1]);
          break;

        case "interests[]":
          // interestsArray.forEach((interest) => {
          // if (interest.name.toLowerCase() == filterParam[1].toLowerCase()) {
          // tempInterestsIds.push(interest.id);
          tempInterestsIds.push(filterParam[1]);
          // }
          // });
          break;

        default:
          break;
      }
    });

    // console.log(tempInterestsIds);
    setInterestIds(tempInterestsIds);
    // console.log(filterParams);
  };

  useEffect(() => {
    // console.log(interestIds);
    setSelectState(Math.random());
  }, [interestIds]);

  useEffect(() => {
    fetchEvents(1, true);
    setFrontFilters();
  }, [searchParams]);

  const onInputCity = async (e) => {
    // console.log(e.target.value);
    setEventLocation(e.target.value);
    setCityTitle("Поиск...");
    const response = (await RequestService.searchLocation(e.target.value)).data;
    // console.log(response);
    setCityTitle("Город проведения");
    if (!response.response_code) {
      return;
    }

    let list = {};
    if (response.result.length > 0) {
      response.result.forEach((place) => {
        list[
          place.name_ru // + " (" + place.region_ru + ", " + place.country_ru + ")"
        ] = null;
      });
    }
    setLocationOptions(list);
    // console.log(list);
  };

  const getInterests = async (e) => {
    const interestsResponse = (await RequestService.getInterests()).data;
    // console.log(interestsResponse);
    if (!interestsResponse.response_code) {
      return;
    }
    // console.log(interestsResponse.result);
    let interestOptions = [];
    interestOptions.push(
      <option disabled value="" key="0">
        Выберите один или несколько
      </option>
    );
    interestsResponse.result.forEach((interest) => {
      interestOptions.push(
        <option value={interest.id} key={interest.id}>
          {interest.name}
        </option>
      );
    });
    setInterests(interestsResponse.result);
    setInterestOptions(interestOptions);

    return interestsResponse.result;
  };

  useEffect(() => {
    if (document.getElementById("filter-location")) {
      let cityInputInstance = M.Autocomplete.getInstance(
        document.getElementById("filter-location")
      );

      setTimeout(() => {
        cityInputInstance.open();
      }, 300);
    }
  }, [locationOptions]);

  const [fetchEvents, isEventsLoading, eventsError] = useFetching(getEvents);

  useEffect(() => {
    // console.log(eventPage);
    if (eventPage == 1) {
      fetchEvents(eventPage, true);
    } else {
      fetchEvents(eventPage);
    }
  }, [eventPage]);

  useObserver(
    lastEventElement,
    eventsData && eventPage < eventsData.total_pages,
    isEventsLoading,
    () => {
      setEventPage(eventPage + 1);
    }
  );

  return (
    <div>
      <RegularPage
        error={pageError}
        isDataExist={1}
        hasBaseCard={false}
        header={"События"}
        isPrimaryPage={true}
      >
        <Filter
          title={"Фильтр по событиям"}
          resetPage={() => {
            setEventPage(1);
          }}
          resetForm={() => {
            setEventLocation("");
            setEventStartDate("");
            document.querySelector("#filter-start_date").value = "";
            setEventEndDate("");
            document.querySelector("#filter-end_date").value = "";
            setEventFormat("");
            setInterestIds([]);
          }}
        >
          <Select
            id="filter-format"
            name="filter-format"
            multiple={false}
            label={"Формат участия"}
            s={12}
            l={2}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                coverTrigger: false,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250,
              },
            }}
            // defaultValue={eventData.format ? eventData.format : null}
            value={eventFormat}
          >
            <option value="0">Любой</option>
            <option value="1">Офлайн</option>
            <option value="2">Онлайн</option>
            <option value="3">Смешанный</option>
          </Select>

          <Autocomplete
            id="filter-location"
            name="filter-location"
            options={{
              data: locationOptions,
              onAutocomplete: (location) => {
                setEventLocation(location);
              },
            }}
            title={cityTitle}
            label={"Город"}
            placeholder={"Екатеринбург"}
            s={10}
            l={3}
            onChange={onInputCity}
            value={eventLocation}
            onBlur={() => {
              // setGeoInputStates();
            }}
          />

          <div
            className="col l1 s2"
            onClick={() => {
              document.querySelector("#filter-location").value = "";
              setEventLocation("");
            }}
          >
            <Icon
              style={{
                position: "absolute",
                marginTop: "25px",
                cursor: "pointer",
              }}
            >
              cancel
            </Icon>
          </div>

          <DatePicker
            id="filter-start_date"
            name="filter-start_date"
            label={"Нач. дата (местная)"}
            placeholder={"10.03.2035"}
            data-type={"date"}
            s={10}
            l={2}
            options={{
              autoClose: true,
              // container: null,
              // defaultDate: moment(new Date()).format("DD.MM.YYYY"),
              defaultDate: eventStartDate
                ? moment(eventStartDate).toDate()
                : "",
              // disableDayFn: null,
              // disableWeekends: false,
              // events: [],
              firstDay: 1,
              format: "dd.mm.yyyy",
              i18n: {
                cancel: "Отмена",
                clear: "Очистить",
                done: "Выбрать",
                months: [
                  "Январь",
                  "Февраль",
                  "Март",
                  "Апрель",
                  "Май",
                  "Июнь",
                  "Июль",
                  "Август",
                  "Сентябрь",
                  "Октябрь",
                  "Ноябрь",
                  "Декабрь",
                ],
                monthsShort: [
                  "Января",
                  "Февраля",
                  "Марта",
                  "Апреля",
                  "Мая",
                  "Июня",
                  "Июля",
                  "Августа",
                  "Сентября",
                  "Октября",
                  "Ноября",
                  "Декабря",
                ],
                nextMonth: "›",
                previousMonth: "‹",
                weekdays: [
                  "Воскресенье",
                  "Понедельник",
                  "Вторник",
                  "Среда",
                  "Четверг",
                  "Пятница",
                  "Суббота",
                ],
                weekdaysAbbrev: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
                weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
              },
              setDefaultDate: true,
            }}
          />

          <div
            className="col l1 s2"
            onClick={() => {
              document.querySelector("#filter-start_date").value = "";
              setEventStartDate("");
            }}
          >
            <Icon
              style={{
                position: "absolute",
                marginTop: "25px",
                cursor: "pointer",
              }}
            >
              cancel
            </Icon>
          </div>

          <DatePicker
            id="filter-end_date"
            name="filter-end_date"
            label={"Кон. дата (местная)"}
            placeholder={"15.03.2035"}
            data-type={"date"}
            s={10}
            l={2}
            onInput={(e) => {
              // console.log(e.target.value);
            }}
            options={{
              autoClose: true,
              // container: null,
              //   defaultDate: moment(new Date()).format("DD.MM.YYYY"),
              defaultDate: eventEndDate ? moment(eventEndDate).toDate() : "",
              // disableDayFn: null,
              // disableWeekends: false,
              // events: [],
              firstDay: 1,
              format: "dd.mm.yyyy",
              i18n: {
                cancel: "Отмена",
                clear: "Очистить",
                done: "Выбрать",
                months: [
                  "Январь",
                  "Февраль",
                  "Март",
                  "Апрель",
                  "Май",
                  "Июнь",
                  "Июль",
                  "Август",
                  "Сентябрь",
                  "Октябрь",
                  "Ноябрь",
                  "Декабрь",
                ],
                monthsShort: [
                  "Января",
                  "Февраля",
                  "Марта",
                  "Апреля",
                  "Мая",
                  "Июня",
                  "Июля",
                  "Августа",
                  "Сентября",
                  "Октября",
                  "Ноября",
                  "Декабря",
                ],
                nextMonth: "›",
                previousMonth: "‹",
                weekdays: [
                  "Воскресенье",
                  "Понедельник",
                  "Вторник",
                  "Среда",
                  "Четверг",
                  "Пятница",
                  "Суббота",
                ],
                weekdaysAbbrev: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
                weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
              },
              setDefaultDate: true,
            }}
          />
          <div
            className="col l1 s2"
            onClick={() => {
              document.querySelector("#filter-end_date").value = "";
              setEventEndDate("");
            }}
          >
            <Icon
              style={{
                position: "absolute",
                marginTop: "25px",
                cursor: "pointer",
              }}
            >
              cancel
            </Icon>
          </div>

          <Select
            id="filter-interests"
            name="filter-interests"
            s={12}
            l={8}
            label={"Сфера интересов"}
            multiple
            value={interestIds}
            key={selectState}
            data-type={"array"}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                // autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                // coverTrigger: true,
                // hover: false,
                // inDuration: 150,
                // onCloseEnd: null,
                // onCloseStart: null,
                // onOpenEnd: null,
                // onOpenStart: null,
                // outDuration: 250,
              },
            }}
            // value={[""]}
          >
            {interestOptions}
          </Select>
        </Filter>
        <EventTiles
          events={events}
          isLoading={isEventsLoading}
          onSurface={false}
          hideOldAndNoDatetime={true}
        />
        {isEventsLoading ? <CircleLoader /> : null}
      </RegularPage>
      <div
        ref={lastEventElement}
        // style={{ backgroundColor: "red", height: "10px" }}
      ></div>
    </div>
  );
};

export default EventsFlow;
