import React from "react";
import { Button } from "react-materialize";
import PackageOptionList from "../PackageOption/PackageOptionList";
import { formatNumber } from "../../../../utils/service";
import RequestService from "../../../../api/RequestService";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../context";
import useConfirm from "../../../../context/ConfirmDialog";

const PackageScreen = ({ pack }) => {
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);

  // console.log(pack);
  const failure = (
    <div className="row center-align">
      <div className="col s12">Нет данных о пакете участия</div>
    </div>
  );
  if (!pack) return failure;
  if (!pack.hasOwnProperty("price")) return failure;

  const buyTicket = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.buyTicket(pack.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    if (response.result[0] == "/") {
      navigate(response.result);
      return;
    }

    await confirm({
      description:
        "Спасибо за заявку! Организатор может связаться с вами для уточнения деталей.",
      isAlert: true,
    });
    // alert(
    //   "Спасибо за заявку! Организатор может связаться с вами для уточнения деталей."
    // );

    if (response.result == 1) {
      navigate("/tickets");
    }
  };

  return (
    <div>
      {pack.description ? (
        <div className="row center-align mt35">
          <div className="col s12 xl500 mb20">«{pack.name}»</div>
          <div className="col s12 secondary-color mb15">{pack.description}</div>
        </div>
      ) : (
        <div className="row center-align mt35">
          <div className="col s12 secondary-color mb15">
            Дополнительной информации пока нет
          </div>
        </div>
      )}
      <PackageOptionList packageOptions={pack.options} />
      <div className="row center-align">
        <div className="col s12 s300">
          {pack.not_refundable_hours
            ? "Отмена участия с возвратом денежных средств доступна не позднее, чем за " +
              pack.not_refundable_hours +
              " часов до начала мероприятия"
            : "Отмена участия с возвратом денежных средств доступна только до начала мероприятия"}
        </div>
      </div>
      <div className="row nm mt15">
        <div className="col s12 center-align">
          <Button flat className="iq-btn" onClick={buyTicket}>
            {"Присоединиться " +
              (pack.price == 0
                ? "бесплатно"
                : "за " +
                  formatNumber(pack.price) +
                  " " +
                  (pack.currency_symbol
                    ? pack.currency_symbol
                    : pack.currency_code))}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PackageScreen;
