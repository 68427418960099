import React from "react";
import BookmarkItem from "../BookmarkItem/BookmarkItem";

const BookmarkList = ({ bookmarks, onSurface = false, oneCol = false }) => {
  const failure = (
    <div className="row nm">
      <div className="col s12 center-align">Пока пусто</div>
    </div>
  );
  // console.log(projectsData);
  if (!bookmarks || bookmarks.length === 0) return failure;

  let column = 1;
  let firstCol = [];
  let secondCol = [];
  let thirdCol = [];
  let commonCol = [];
  // console.log(projectsData);
  bookmarks.forEach((bookmark) => {
    switch (column) {
      case 1:
        firstCol.push(bookmark);
        column = 2;
        break;

      case 2:
        secondCol.push(bookmark);
        column = 3;
        break;

      case 3:
        thirdCol.push(bookmark);
        column = 1;
        break;

      default:
        break;
    }

    commonCol.push(bookmark);
  });

  return (
    <div className="row nm animation-appear">
      {oneCol ? (
        <div className="col s12">
          {bookmarks.map((el) => (
            <BookmarkItem
              bookmark={el}
              key={el.bookmark_id}
              onSurface={onSurface}
            />
          ))}
        </div>
      ) : (
        <div>
          <div className="col l4 hide-on-med-and-down">
            {firstCol.map((el) => {
              return (
                <BookmarkItem
                  bookmark={el}
                  key={el.bookmark_id}
                  onSurface={onSurface}
                />
              );
            })}
          </div>
          <div className="col l4 hide-on-med-and-down">
            {secondCol.map((el) => {
              return (
                <BookmarkItem
                  bookmark={el}
                  key={el.bookmark_id}
                  onSurface={onSurface}
                />
              );
            })}
          </div>
          <div className="col l4 hide-on-med-and-down">
            {thirdCol.map((el) => {
              return (
                <BookmarkItem
                  bookmark={el}
                  key={el.bookmark_id}
                  onSurface={onSurface}
                />
              );
            })}
          </div>
          <div className="col s12 hide-on-large-only">
            {bookmarks.map((el) => (
              <BookmarkItem
                bookmark={el}
                key={el.bookmark_id}
                onSurface={onSurface}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookmarkList;
