import React from "react";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { Icon, TextInput } from "react-materialize";
import { Link, useNavigate } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import { useContext } from "react";
import { AuthContext } from "../context";
import RequestService from "../api/RequestService";
import { parseForm } from "../utils/service";
import useConfirm from "../context/ConfirmDialog";

const ForgotPassword = () => {
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const confirm = useConfirm();

  const forgotPassword = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    let email = htmlForm["email"].value;
    let sendFormData = parseForm(htmlForm);
    const response = (
      await RequestService.sendForm(sendFormData, "/profile/forgotPassword")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    navigate("/login?after=forgot&email=" + email);
  };

  return (
    <RegularPage isDataExist={1} hasBaseCard={false}>
      <div className="row nm">
        <div className="col l3"></div>
        <div className="col s12 l6">
          <div className="card">
            <div className="card-content">
              <div className="row">
                <div className="col s12 xl500 center-align">
                  Изменение пароля
                </div>
              </div>
              <div className="row">
                <div className="col s12 center-align">
                  Укажите email, к которому привязан ваш аккаунт. Мы отправим на
                  него письмо со ссылкой на восстановление доступа.
                </div>
              </div>
              <form onSubmit={forgotPassword}>
                <div className="row nm">
                  <TextInput
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    s={12}
                    icon={<Icon>alternate_email</Icon>}
                    onChange={(e) => {
                      //   setLoginData({ ...loginData, login: e.target.value });
                    }}
                    required
                  />

                  <div className="col s12">
                    <Recaptcha
                      sitekey="6LenXsonAAAAAIJHpYPSTLIGP-aKhCet7Neq68nZ"
                      hl={"ru"}
                    />
                  </div>

                  <div className="col s12 center-align mt25">
                    <input
                      type="submit"
                      className="btn iq-btn btn-flat"
                      value="Отправить ссылку"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col l3"></div>
      </div>
    </RegularPage>
  );
};

export default ForgotPassword;
