import moment from "moment";
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { formatNumber } from "../utils/service";
import { Button, Icon } from "react-materialize";
import { AuthContext } from "../context";
import NoAvatar from "../images/no-avatar.png";
import useConfirm from "../context/ConfirmDialog";

const CheckTicket = () => {
  const [ticketData, setTicketData] = useState(null);
  const [ticketError, setTicketError] = useState(null);
  const params = useParams();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    checkTicket();
  }, []);

  const checkTicket = async () => {
    const response = (await RequestService.checkTicket(params.id)).data;
    // console.log(response);
    if (!response.response_code) {
      setTicketError(response.message);
      return;
    }
    setTicketData(response.result);
  };

  const checkUserIn = async () => {
    if (ticketData.status != 1) {
      // if (
      //   window.confirm(
      //     "Вы подтверждаете, что приняли у участника оплату " +
      //       formatNumber(ticketData.price) +
      //       "" +
      //       (ticketData.currency_symbol
      //         ? ticketData.currency_symbol
      //         : ticketData.currency_code) +
      //       "?"
      //   )
      // ) {
      const choice = await confirm({
        description:
          "Вы подтверждаете, что приняли у участника оплату " +
          formatNumber(ticketData.price) +
          "" +
          (ticketData.currency_symbol
            ? ticketData.currency_symbol
            : ticketData.currency_code) +
          "?",
      });
      if (choice) {
        checkingUserIn();
      }
    } else {
      checkingUserIn();
    }
  };

  const checkingUserIn = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.checkUserIn(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    checkTicket();
  };

  return (
    <RegularPage
      error={ticketError}
      isDataExist={ticketData}
      header={"Проверка участия"}
    >
      {ticketData ? (
        <div className="row nm mt25">
          {/* <div className="col s12">
            <div className="card alert-message">
              <div className="card-content">
                <div className="row nm">
                  <div className="col s12">
                    Для предотвращения подмены билета обращайте внимание на код
                    события — он уникален для каждого мероприятия и должен
                    совпадать с кодом на странице мероприятия (видит только
                    организатор).
                  </div>
                  <div className="col s12 center-align l500 mt15">
                    <i
                      className="material-icons alert-color"
                      style={{ position: "absolute", marginTop: "2px" }}
                    >
                      key
                    </i>{" "}
                    <span style={{ marginLeft: "35px" }}>{ticketData.key}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {ticketData.completed_at ? (
            <div className="col s12">
              <div
                className="card mb25"
                style={{ border: "var(--success-color) 2px solid" }}
              >
                <div className="card-content">
                  <div className="row nm">
                    <div className="col s12 center-align">
                      <Icon className="success-color large">how_to_reg</Icon>
                    </div>
                    <div className="col s12 center-align success-color xl500">
                      Участник уже отмечен
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {ticketData.cancelled_at ? (
            <div className="col s12">
              <div
                className="card mb25"
                style={{ border: "var(--error-color) 2px solid" }}
              >
                <div className="card-content">
                  <div className="row nm">
                    <div className="col s12 center-align">
                      <Icon className="error-color large">cancel</Icon>
                    </div>
                    <div className="col s12 center-align error-color xl500">
                      Участник вернул билет. Участие невозможно
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {!ticketData.is_owner &&
          ticketData.role != 1 &&
          ticketData.role != 2 ? (
            <div className="col s12 mb25">
              <div className="card alert-message">
                <div className="card-content">
                  <div className="row nm">
                    <div className="col s12">
                      Подробности данного участия доступны только организатору
                      при валидации данного билета. Организатор вправе отказать
                      в посещении мероприятия в случае наличия аргументированных
                      подозрений, что данный билет был украден.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {ticketData.is_owner ||
          ticketData.role == 1 ||
          ticketData.role == 2 ? (
            <div>
              <div className="col s12 mb25">
                <div className="card alert-message">
                  <div className="card-content">
                    <div className="row nm">
                      <div className="col s12">
                        Сверяйте фото участника здесь и в реальной жизни, чтобы
                        не допустить использование чужого билета.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col s12 mb10">
                <img
                  src={
                    !ticketData.avatar_img_url
                      ? NoAvatar
                      : ticketData.avatar_img_url
                  }
                  alt="Фото участника"
                  className={
                    !ticketData.avatar_img_url ? "image-theme-filter" : ""
                  }
                  style={{ width: "100%", maxWidth: "300px" }}
                />
              </div>
            </div>
          ) : null}

          <div className="col s12 xl500">{ticketData.name}</div>
          <div className="col s12">
            <span>Пакет «{ticketData.package_name}»</span>
          </div>
          <div className="col s12">
            <span
              className={
                ticketData.status == 1 ? "success-color" : "error-color"
              }
            >
              {ticketData.status == 1
                ? "Оплачено " +
                  formatNumber(ticketData.price) +
                  " " +
                  (ticketData.currency_symbol
                    ? ticketData.currency_symbol
                    : ticketData.currency_code)
                : "К оплате " +
                  formatNumber(ticketData.price) +
                  " " +
                  (ticketData.currency_symbol
                    ? ticketData.currency_symbol
                    : ticketData.currency_code)}
            </span>
          </div>
          <div className="col s12 mt15">
            <span className="m500">Событие: </span> {ticketData.object_name}
          </div>
          <div className="col s12">
            <span className="m500">Дата и время: </span>{" "}
            {moment(ticketData.object_datetime).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className="col s12">
            <span className="m500">Место:</span>{" "}
            {!ticketData.object_location
              ? "Не указано"
              : ticketData.object_location +
                (ticketData.object_address
                  ? ", " + ticketData.object_address
                  : "") +
                (ticketData.object_place ? ", " + ticketData.object_place : "")}
          </div>

          {(ticketData.is_owner ||
            ticketData.role == 1 ||
            ticketData.role == 2) &&
          !ticketData.completed_at &&
          !ticketData.cancelled_at ? (
            <div className="col s12 mt20 center-align">
              <Button
                className="iq-btn"
                onClick={() => {
                  checkUserIn();
                }}
              >
                <Icon left>how_to_reg</Icon>Отметить участника
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
    </RegularPage>
  );
};

export default CheckTicket;
