import React, { useContext, useEffect, useRef, useState } from "react";
import { Icon, Textarea, Button } from "react-materialize";
import { AuthContext } from "../../../context";
import { parseForm } from "../../../utils/service";
import RequestService from "../../../api/RequestService";
import useConfirm from "../../../context/ConfirmDialog";
import { useFetching } from "../../../hooks/useFetching";
import { useObserver } from "../../../hooks/useObserver";
import CircleLoader from "../CircleLoader/CircleLoader";
import Posts from "./Posts";
import M from "materialize-css";
import { useParams } from "react-router-dom";
import FilePreview from "../FilePreview/FilePreview";

const Wall = ({ isCurrentUser = 0, allPosts = false }) => {
  const [postText, setPostText] = useState("");
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();
  const [postsData, setPostsData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postsPage, setPostsPage] = useState(1);
  const lastPostsElement = useRef();
  const params = useParams();

  const [selectedFiles, setSelectedFiles] = useState();

  const getPosts = async (page = 1) => {
    if (page != 1 && postsData && page > postsData.total_pages) return;
    const response = allPosts
      ? (await RequestService.getRelevantPosts(page)).data
      : (await RequestService.getUserPosts(params.id, page)).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setPostsData(response.result);
    if (page == 1) {
      setPosts([...response.result.elements]);
    } else {
      setPosts([...posts, ...response.result.elements]);
    }
  };

  const sendPost = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    let sendFormData = parseForm(htmlForm);

    const response = (
      await RequestService.sendForm(sendFormData, "/posts/publish")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    setSelectedFiles(undefined);

    setPostText("");
    document.getElementById("post-text").style.height = 0;
    setPostsPage(1);
    fetchPosts(1);
  };

  const [fetchPosts, isPostsLoading, postsError] = useFetching(getPosts);
  useEffect(() => {
    fetchPosts(postsPage);
  }, [postsPage]);

  useObserver(
    lastPostsElement,
    postsData && postsPage < postsData.total_pages,
    isPostsLoading,
    () => {
      setPostsPage(postsPage + 1);
    }
  );

  // useEffect(() => {
  // if (!selectedFile) {
  //   setPreview(undefined);
  //   return;
  // }

  // const objectUrl = URL.createObjectURL(selectedFile);
  // setPreview(objectUrl);

  // return () => URL.revokeObjectURL(objectUrl);
  // console.log(selectedFiles);
  // }, [selectedFiles]);

  const onSelectFiles = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles(undefined);
      return;
    }

    const filesLimit = 8;

    if (e.target.files.length > filesLimit) {
      await confirm({
        description: "Нельзя прикреплять более " + filesLimit + " файлов",
        isAlert: true,
      });

      return;
    }

    let files = [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }

    // console.log(files);

    setSelectedFiles(files);
  };

  return (
    <div className="row nm">
      {isCurrentUser ? (
        <form onSubmit={sendPost} encType="multipart/form-data">
          <Textarea
            id="post-text"
            name="post-text"
            label={"Что нового?"}
            icon={<Icon>campaign</Icon>}
            className="mb5"
            placeholder={"до 3000 символов"}
            s={9}
            l={11}
            style={{ marginBottom: "0px" }}
            onChange={(e) => {
              setPostText(e.target.value);
            }}
            value={postText}
            required
          />
          <div className="col s3 l1 center-align mt20">
            <Button
              flat
              className="iq-btn col s12"
              type="submit"
              icon={<Icon>send</Icon>}
            ></Button>
          </div>
          <div className="col s12 mb25" style={{ marginTop: "-25px" }}>
            <div className="file-field col s12 input-field nm">
              <div className="btn iq-btn btn-flat pastel-btn">
                <i className="material-icons-round accent-color">
                  add_photo_alternate
                </i>
                <input
                  id="attachments"
                  type="file"
                  name="attachments"
                  multiple
                  onChange={onSelectFiles}
                  accept="image/jpeg,image/png,image/gif"
                />
              </div>
              <div className="file-path-wrapper hide">
                <input
                  className="file-path validate"
                  type="text"
                  placeholder="Прикрепите файл"
                />
              </div>
            </div>

            {selectedFiles
              ? selectedFiles.map((file) => (
                  <FilePreview
                    fileInputElementId={"attachments"}
                    removeFn={(fileName) => {
                      selectedFiles.length > 0
                        ? setSelectedFiles(
                            selectedFiles.filter((el) => el.name !== fileName)
                          )
                        : setSelectedFiles(undefined);
                    }}
                    file={file}
                    key={"preview-" + (Math.random() * 100000000).toFixed(0)}
                  />
                ))
              : null}
          </div>
        </form>
      ) : null}
      <div className="col s12">
        <Posts
          posts={posts}
          getPosts={() => {
            setPostsPage(1);
            fetchPosts(1);
          }}
          isPostsLoading={isPostsLoading}
          isCurrentUser={isCurrentUser}
        />
        {isPostsLoading ? <CircleLoader /> : null}
        <div ref={lastPostsElement}></div>
      </div>
    </div>
  );
};

export default Wall;
