import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import RequestService from "../api/RequestService";
import { useContext } from "react";
import { AuthContext } from "../context";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import Reviews from "../components/UI/Reviews/Reviews";

const ObjectReviews = () => {
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [reviewsData, setReviewsData] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [reviewsPage, setReviewsPage] = useState(1);
  const lastReviewsElement = useRef();
  const params = useParams();

  const getReviews = async (page = 1) => {
    if (page != 1 && reviewsData && page > reviewsData.total_pages) return;
    const response = (await RequestService.getReviews(params.id, page)).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setReviewsData(response.result);
    if (page == 1) {
      setReviews([...response.result.elements]);
    } else {
      setReviews([...reviews, ...response.result.elements]);
    }
  };

  const [fetchReviews, isReviewsLoading, reviewsError] =
    useFetching(getReviews);

  useEffect(() => {
    fetchReviews(reviewsPage);
  }, [reviewsPage]);

  useObserver(
    lastReviewsElement,
    reviewsData && reviewsPage < reviewsData.total_pages,
    isReviewsLoading,
    () => {
      setReviewsPage(reviewsPage + 1);
    }
  );

  return (
    <RegularPage
      //   error={postError}
      isDataExist={1}
      header={"Отзывы"}
      isPrimaryPage={true}
      hasBaseCard={true}
      backText={"Назад"}
      // backPath={"/posts"}
    >
      <div className="row nm">
        <div className="col s12">
          <Reviews reviews={reviews} isLoading={isReviewsLoading} />
          {isReviewsLoading ? <CircleLoader /> : null}
          <div ref={lastReviewsElement}></div>
        </div>
      </div>
    </RegularPage>
  );
};

export default ObjectReviews;
