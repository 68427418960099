import React from "react";
import { formatNumber, isNumber } from "../../../../utils/service";
import "materialize-css";
import { Button } from "react-materialize";
import moment from "moment";

const PackageButton = ({
  pack,
  marginBottom = 15,
  choosePackage,
  hasControls,
  editPackage,
  removePackage,
}) => {
  // console.log(pack);

  return (
    <div className="col s12" style={{ marginBottom: marginBottom + "px" }}>
      <div className="card pastel-btn">
        <div className="card-content">
          <div className="row nm">
            <div className="col s12 l500">
              {pack.name}
              <span className="tertiary-color">
                {" (" + (pack.age_limit ? pack.age_limit : 0) + "+)"}
              </span>
            </div>
          </div>
          <div className="row nm tertiary-color">
            {pack.datetime ? (
              <div className="col s12 mt15">
                <i
                  className="material-icons-round"
                  style={{ position: "absolute", marginTop: "-2px" }}
                >
                  schedule
                </i>
                <span style={{ marginLeft: "30px" }}>
                  {moment(pack.datetime).format("DD.MM.YYYY HH:mm") +
                    " (" +
                    (pack.object_timezone_description
                      ? pack.object_timezone_description
                      : "время местное") +
                    ")"}
                </span>
              </div>
            ) : null}
          </div>
          <div className="row nm tertiary-color">
            {pack.duration ? (
              <div className="col s12 mt15">
                <i
                  className="material-icons-round"
                  style={{ position: "absolute", marginTop: "-2px" }}
                >
                  hourglass_empty
                </i>
                <span style={{ marginLeft: "30px" }}>{pack.duration} мин.</span>
              </div>
            ) : null}
          </div>

          {pack.stop_sales_hours ? (
            <div className="row nm tertiary-color mt10">
              {pack.datetime ? (
                <div className="col s12">
                  <i
                    className="material-icons-outlined"
                    style={{ position: "absolute", marginTop: "-2px" }}
                  >
                    lock_clock
                  </i>
                  <span style={{ marginLeft: "30px" }}>
                    {"Запись закроется: " +
                      moment(pack.datetime)
                        .subtract(pack.stop_sales_hours, "h")
                        .format("DD.MM.YYYY HH:mm")}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="row tertiary-color mt10">
            {pack.datetime ? (
              <div className="col s12">
                <i
                  className="material-icons-outlined"
                  style={{ position: "absolute", marginTop: "-2px" }}
                >
                  confirmation_number
                </i>
                <span style={{ marginLeft: "30px" }}>
                  {isNumber(pack.tickets_available)
                    ? "Доступно мест: " + pack.tickets_available
                    : "Места не ограничены"}
                </span>
              </div>
            ) : null}
          </div>

          <div className="row mb5">
            <div className="col s12 center-align">
              <Button
                className="modal-trigger iq-btn"
                href="#modal-package"
                node="button"
                // style={{ width: "100%", padding: "0px" }}
                disabled={
                  isNumber(pack.tickets_available) &&
                  pack.tickets_available <= 0
                    ? true
                    : false
                }
                onClick={() => {
                  choosePackage(pack);
                }}
              >
                {pack.price == 0
                  ? "Участвовать бесплатно"
                  : "Купить за " +
                    formatNumber(pack.price) +
                    " " +
                    (pack.currency_symbol
                      ? pack.currency_symbol
                      : pack.currency_code)}
              </Button>
            </div>
          </div>

          {hasControls ? (
            <div className="row nm mt20">
              <div className="col s12 center-align">
                <span
                  className="iq-link"
                  onClick={() => {
                    if (editPackage) {
                      editPackage();
                    }
                  }}
                >
                  <i
                    className="material-icons tiny accent-color"
                    style={{ position: "absolute", marginTop: "3px" }}
                  >
                    edit
                  </i>
                  <span style={{ marginLeft: "20px" }}>Редактировать</span>
                </span>
                <span className="tertiary-color"> | </span>
                <span
                  className="iq-link"
                  onClick={() => {
                    if (removePackage) {
                      removePackage();
                    }
                  }}
                >
                  <i
                    className="material-icons tiny accent-color"
                    style={{ position: "absolute", marginTop: "3px" }}
                  >
                    delete
                  </i>
                  <span style={{ marginLeft: "20px" }}>Удалить</span>
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PackageButton;
