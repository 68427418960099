import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { AuthContext } from "../context";
import RequestService from "../api/RequestService";
import noImage from "../images/no-image.png";
import { useFetching } from "../hooks/useFetching";
import { parseForm } from "../utils/service";
import { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Icon,
  Select,
  Textarea,
  TextInput,
} from "react-materialize";
import M from "materialize-css";
import useConfirm from "../context/ConfirmDialog";

const ProfileEdit = () => {
  const [userData, setUserData] = useState(null);
  const [userError, setUserError] = useState(null);
  const navigate = useNavigate();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [locationOptions, setLocationOptions] = useState({});
  const [cityTitle, setCityTitle] = useState("Ваш город");
  const [locationValue, setLocationValue] = useState("");
  const [addressInputState, setAddressInputState] = useState(0);
  const [locationInputState, setLocationInputState] = useState(0);
  const [addressValue, setAddressValue] = useState("");
  const [interestOptions, setInterestOptions] = useState(null);
  const [interestIds, setInterestIds] = useState(null);
  const confirm = useConfirm();

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (document.getElementById("user-location")) {
      let cityInputInstance = M.Autocomplete.getInstance(
        document.getElementById("user-location")
      );

      setTimeout(() => {
        cityInputInstance.open();
      }, 300);
    }
  }, [locationOptions]);

  const getUser = async () => {
    const response = (await RequestService.getUser()).data;
    // console.log(response);
    if (!response.response_code) {
      setUserError(response.message);
      return;
    }
    setUserData(response.result);
    setLocationValue(response.result.location ? response.result.location : "");

    if (response.result.interests) {
      let tmpInterests = [];
      for (let i = 0; i < response.result.interests.length; i++) {
        tmpInterests.push(response.result.interests[i].interest_id);
      }
      // console.log(tmpInterests);
      setInterestIds(tmpInterests);
    }

    let interestsArray = await getInterests();
  };

  const getInterests = async (e) => {
    const interestsResponse = (await RequestService.getInterests()).data;
    // console.log(interestsResponse);
    if (!interestsResponse.response_code) {
      return;
    }
    // console.log(interestsResponse.result);
    let interestOptions = [];
    interestOptions.push(
      <option disabled value="" key="0">
        Выберите один или несколько
      </option>
    );
    interestsResponse.result.forEach((interest) => {
      interestOptions.push(
        <option value={interest.id} key={interest.id}>
          {interest.name}
        </option>
      );
    });
    // setInterests(interestsResponse.result);
    setInterestOptions(interestOptions);

    return interestsResponse.result;
  };

  const saveUserData = async (e) => {
    e.preventDefault();
    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    for (let pair of sendFormData.entries()) {
      // console.log(pair);
    }
    const response = (
      await RequestService.sendForm(sendFormData, "/profile/update")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    navigate(-1);
  };

  const onInputCity = async (e) => {
    // console.log(e);
    setLocationValue(e.target.value);
    setCityTitle("Поиск...");
    const response = (await RequestService.searchLocation(e.target.value)).data;
    // console.log(response);
    setCityTitle("Ваш город");
    if (!response.response_code) {
      return;
    }

    let list = {};
    if (response.result.length > 0) {
      response.result.forEach((place) => {
        list[
          place.name_ru + " (" + place.region_ru + ", " + place.country_ru + ")"
        ] = null;
      });
    }
    setLocationOptions(list);
    // console.log(list);
  };

  const setGeoInputStates = () => {
    setTimeout(() => {
      if (locationValue) {
        setLocationInputState(1);
      } else {
        setLocationInputState(0);
      }

      if (addressValue) {
        setAddressInputState(1);
      } else {
        setAddressInputState(0);
      }
    }, 100);
  };

  const [fetchUser, isUserDataLoading, userDataError] = useFetching(getUser);

  return (
    <RegularPage
      error={userError}
      isDataExist={userData}
      header={"Редактирование профиля"}
      backText={"Назад"}
    >
      {userData && userData.interests ? (
        <form
          id="new-event"
          onSubmit={saveUserData}
          encType="multipart/form-data"
        >
          <div className="row mt20">
            <TextInput
              id="first-name"
              name="first-name"
              icon="account_circle"
              s={12}
              l={4}
              label="Ваше имя"
              placeholder="Ефим"
              defaultValue={userData.first_name ? userData.first_name : null}
            />
            <TextInput
              id="last-name"
              name="last-name"
              icon="badge"
              s={12}
              l={4}
              label="Ваша фамилия"
              placeholder="Ефимов"
              defaultValue={userData.last_name ? userData.last_name : null}
            />

            <Select
              icon={<Icon>wc</Icon>}
              id="sex"
              name="sex"
              multiple={false}
              label={"Пол"}
              s={12}
              l={4}
              options={{
                classes: "",
                dropdownOptions: {
                  alignment: "left",
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  coverTrigger: false,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                },
              }}
              defaultValue={userData.sex ? userData.sex : null}
            >
              <option value="0">Не указан</option>
              <option value="1">М</option>
              <option value="2">Ж</option>
            </Select>

            <div className="col s12">
              <div className="row">
                <div className="card ">
                  <div className="card-content">
                    <div className="row nm">
                      <div className="col s12 l500 mb20">
                        Аватар пользователя
                      </div>
                      <div className="col l6 s12">
                        <div className="row mb10">
                          <div className="col s12">
                            <img
                              src={
                                userData.avatar_img_url
                                  ? userData.avatar_img_url
                                  : noImage
                              }
                              style={{
                                width: "100%",
                                maxHeight: "250px",
                                objectFit: "contain",
                              }}
                              className={
                                !userData.avatar_img_url
                                  ? "image-theme-filter"
                                  : ""
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        {userData.avatar_img_url_id ? (
                          <div className="row nm">
                            <div className="col s12 center-align">
                              <span
                                className="iq-link"
                                data-file-id={userData.avatar_img_url_id}
                                onClick={async (e) => {
                                  // console.log(e.currentTarget.getAttribute('data-file-id'));
                                  let response = (
                                    await RequestService.removeFromCloud(
                                      e.currentTarget.getAttribute(
                                        "data-file-id"
                                      )
                                    )
                                  ).data;
                                  // console.log(response);
                                  fetchUser();
                                }}
                              >
                                удалить аватар
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="file-field input-field col s12 l6 center-align">
                        <div className="btn iq-btn std btn-flat">
                          <span>Загрузить аватар</span>
                          <input
                            type="file"
                            name="avatar-img"
                            placeholder="Выберите изображение"
                          />
                        </div>
                        <div className="file-path-wrapper">
                          <input
                            className="file-path validate"
                            type="text"
                            placeholder="Выберите изображение"
                          />
                        </div>
                        <div>
                          Чтобы изменения вступили в силу, не забудьте нажать
                          внизу кнопку «Сохранить профиль»
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Autocomplete
              id="user-location"
              name="user-location"
              options={{
                data: locationOptions,
                onAutocomplete: (location) => {
                  setLocationValue(location);
                },
              }}
              title={cityTitle}
              placeholder={"Тула (Тульская область, Россия)"}
              icon={<Icon>place</Icon>}
              s={12}
              l={6}
              onChange={onInputCity}
              value={locationValue}
              onBlur={() => {
                setGeoInputStates();
              }}
            />

            <TextInput
              id="alias"
              name="alias"
              icon="alternate_email"
              s={12}
              l={6}
              label="Уникальное имя пользователя"
              placeholder="ghost24"
              defaultValue={userData.alias ? userData.alias : null}
            />

            <Textarea
              id="contact-info"
              name="contact-info"
              label={"Контактная информация"}
              icon={<Icon>call</Icon>}
              placeholder={
                "Опишите, как можно связаться с вами (телефон, email, VK, и т.д.)"
              }
              s={12}
              style={{ marginBottom: "0px" }}
              defaultValue={
                userData.contact_info ? userData.contact_info : null
              }
            />

            <Select
              id="user-interests"
              name="user-interests"
              s={12}
              icon={<Icon>checklist</Icon>}
              label={"Сфера интересов"}
              multiple
              value={interestIds}
              options={{
                classes: "",
                dropdownOptions: {
                  alignment: "left",
                  // autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  // coverTrigger: true,
                  // hover: false,
                  // inDuration: 150,
                  // onCloseEnd: null,
                  // onCloseStart: null,
                  // onOpenEnd: null,
                  // onOpenStart: null,
                  // outDuration: 250,
                },
              }}
              // value={[""]}
            >
              {interestOptions}
            </Select>

            <Textarea
              id="about-info"
              name="about-info"
              label={"О себе"}
              icon={<Icon>info</Icon>}
              placeholder={"Расскажите о себе"}
              s={12}
              style={{ marginBottom: "0px" }}
              defaultValue={userData.about_info ? userData.about_info : null}
            />

            <div className="col s12 center-align mt20">
              <Button flat className="iq-btn" type="submit">
                Сохранить
              </Button>
            </div>
          </div>
        </form>
      ) : null}
    </RegularPage>
  );
};

export default ProfileEdit;
