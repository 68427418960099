import React from "react";
import RegularPage from "../RegularPage/RegularPage";

const ErrorMessage = ({ error }) => {
  if (!error) {
    return;
  }

  return (
    <RegularPage isDataExist={1} hasBaseCard={false}>
      <div
        className="col s12"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="row center-align">
          <div className="col s12 error">
            <i className="material-icons-round" style={{ fontSize: "52px" }}>
              sentiment_very_dissatisfied
            </i>
          </div>
          <div className="col s12 error xxl500">{`Ошибка: ${error.message} (${error.code})`}</div>
          <div className="col s12 error">{window.location.href}</div>
          <div className="col s12 mt20">
            Если ошибка не исправляется, сделайте скриншот этой страницы и
            сообщите нам на почту {window.nott.info.support_email}. Также
            постарайтесь подробно описать при каких обстоятельствах/действиях
            возникла проблема — это поможет нам воспроизвести ошибку, чтобы
            оперативно ее исправить
          </div>
        </div>
      </div>
    </RegularPage>
  );
};

export default ErrorMessage;
