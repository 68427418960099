import React from "react";
import PackageOption from "./PackageOption";

const PackageOptionList = ({ packageOptions, marginBottom = 20 }) => {
  if (!Array.isArray(packageOptions)) {
    return (
      <div className="row nm">
        <div className="col s12">
          Не удалось разделить содержимое пакета по пунктам
        </div>
      </div>
    );
  }
  return (
    <div className="row nm">
      <div className="col s12">
        {packageOptions.map((el, index, arr) => {
          let optionMarginBottom = marginBottom;
          if (index === arr.length - 1) {
            optionMarginBottom = 0;
          }
          return (
            <PackageOption
              option={el}
              marginBottom={optionMarginBottom}
              key={el.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PackageOptionList;
