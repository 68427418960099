import React from "react";

const FullScreenImage = ({ imgUrl, closeImgFn }) => {
  return (
    <div
      style={{
        display: imgUrl ? "block" : "none",
        width: "100%",
        height: "100%",
        backgroundColor: "var(--bg-color-transparent)",
        position: "fixed",
        zIndex: "99999",
        top: "0",
        left: "0",
      }}
      onClick={() => {
        closeImgFn();
      }}
      className="center-align"
    >
      <img
        src={imgUrl}
        alt="Изображение"
        style={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
          maxWidth: "90%"
        }}
      />
    </div>
  );
};

export default FullScreenImage;
