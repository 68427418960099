import React from "react";
import { isEmpty } from "../../../utils/service";
import CircleLoader from "../CircleLoader/CircleLoader";
import EventItem from "./EventItem";

const EventList = ({ events, setMapState, mapState, onSurface = false }) => {
  // console.log(events);
  const failure = (
    <div>
      <div className="row nm">
        <div className="col s12 center-align mt15">
          <div className="card blur-card animation-appear">
            <div className="card-content">
              <div className="row nm">
                <div className="col s12">Не нашли событий</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  if (!events) {
    return failure;
  }
  // if (events.hasOwnProperty("elements")) {
  if (events.length == 0) {
    return failure;
  }
  // }

  return (
    <div>
      {events.length > 0 ? // events.map((elem) => (
      //   <EventItem
      //     event={elem}
      //     key={elem.id}
      //     setMapState={setMapState}
      //     mapState={mapState}
      //     onSurface={onSurface}
      //   />
      // ))
      null : (
        <div>
          <div className="row nm">
            <div className="col s12 center-align mt15">
              <div className="card blur-card animation-appear">
                <div className="card-content">
                  <div className="row nm">
                    <CircleLoader />
                  </div>
                  <div className="row nm">
                    <div className="col s12 secondary-color">
                      Ищем события...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventList;
