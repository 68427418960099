import React from "react";
import { useState, useEffect } from "react";
import LogoLight from "../images/logo-light.png";
import LogoDark from "../images/logo-dark.png";
import { Icon, TextInput } from "react-materialize";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../context";
import { applyTheme, getTheme } from "../utils/service";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import useConfirm from "../context/ConfirmDialog";

const Registration = () => {
  const { isAuth, setIsAuth, isLoading, setLoading } = useContext(AuthContext);
  const [logoData, setLogoData] = useState(LogoLight);
  const navigate = useNavigate();
  const confirm = useConfirm();

  const signup = async (e) => {
    e.preventDefault();

    let email = e.target["email"].value;
    let promocode = e.target["promo_code"].value;
    setLoading(true);
    let response = (await RequestService.signup(email, promocode)).data;
    // console.log(response);
    setLoading(false);
    if (response.response_code == 1) {
      navigate("/login?after=signup&email=" + email);
    } else {
      await confirm({
        description: "Что-то пошло не так: " + response.message,
        isAlert: true,
      });
      // alert("Что-то пошло не так: " + response.message);
    }
  };

  useEffect(() => {
    applyTheme();
    const theme = getTheme();
    if (theme === "dark") {
      setLogoData(LogoDark);
    } else {
      setLogoData(LogoLight);
    }
  }, []);

  return (
    <RegularPage isDataExist={1} hasBaseCard={false}>
      <div className="row nm">
        <div className="col l3"></div>
        <div className="col s12 l6">
          <div className="card">
            <div className="card-content">
              <div className="row nm xl500 center-align hide-on-med-and-down">
                <div className="col s12 center-align">
                  <img
                    src={logoData}
                    alt="Logo"
                    style={{ width: "25%", maxWidth: "200px" }}
                    className="mt15"
                  />
                </div>
              </div>
              <div className="row nm hide-on-large-only">
                <div className="col s12 xl500 center-align">Регистрация</div>
              </div>
              <div className="row nm mt20 hide-on-med-and-down">
                <div className="col s12 xl500 center-align">Регистрация</div>
              </div>
              <div className="row mt25">
                <div className="col s12 center-align">
                  Мы отправим вам письмо. Если в течение 20 минут ничего не
                  пришло, а в папке «спам» нашего письма нет, то напишите нам в
                  чат.
                </div>
              </div>
              <form onSubmit={signup}>
                <div className="row nm">
                  <TextInput
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="yourmail@domain.com"
                    s={12}
                    icon={<Icon>alternate_email</Icon>}
                    required
                  />

                  <TextInput
                    name="promo_code"
                    label="Промокод"
                    placeholder="Если у вас есть"
                    s={12}
                    icon={<Icon>stars</Icon>}
                  />

                  <div className="col s12 center-align mt15">
                    <input
                      type="submit"
                      className="btn iq-btn btn-flat"
                      value="Создать аккаунт"
                    />
                  </div>
                </div>
              </form>
              <div className="row nm center-align mt25">
                <div className="col s12">
                  <Link to="/login" className="iq-link">
                    Уже с нами? Входите здесь
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col l6"></div>
      </div>
    </RegularPage>
  );
};

export default Registration;
