import moment from "moment";
import React from "react";

const Message = ({ isOwner, name, text, datetime }) => {
  return (
    <div>
      {isOwner ? (
        <div className="row mt10 mb10">
          <div className="col s4"></div>
          <div className="col s8 card accent-color-bg primary-color">
            <div className="card-content" style={{ padding: "15px" }}>
              <div className="row nm">
                <div className="col s12">
                  <div className="m700 mb10">{name}</div>
                  <div>{text}</div>
                  <div className="right s300 mt10">
                    {moment(datetime).format("DD.MM.YYYY HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mt10 mb10">
          <div className="col s8 card secondary-color">
            <div className="card-content" style={{ padding: "15px" }}>
              <div className="row nm">
                <div className="col s12">
                  <div className="m700 mb10">{name}</div>
                  <div>{text}</div>
                  <div className="right s300 mt10">
                    {moment(datetime).format("DD.MM.YYYY HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col s4"></div>
        </div>
      )}
    </div>
  );
};

export default Message;
