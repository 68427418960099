import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, TextInput } from "react-materialize";
import { useParams, useSearchParams, Link } from "react-router-dom";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { formatNumber } from "../utils/service";
import noImage from "../images/no-image.png";

const BuyPackage = () => {
  const params = useParams();
  const [packageError, setPackageError] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [userData, setUserData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setPayScreen();
  }, []);

  const setPayScreen = async () => {
    let username = searchParams.get("user");
    let packageId = params.id;
    if (!username) {
      // setPackageError(
      //   "В заказе не обнаружено имя пользователя, на которого будет оформлена покупка"
      // );
      // return;
    }
    if (!packageId) {
      setPackageError("В заказе не обнаружен идентификатор пакета участия");
      return;
    }
    await getPackage(packageId);
    if (username) {
      await getUser(username);
    }
  };

  const getPackage = async (packageId) => {
    let response = (await RequestService.getPackage(packageId)).data;
    // console.log(response);
    if (!response.response_code) {
      setPackageError(response.message);
      return;
    }
    if (response.result.currency_id != 1) {
      setPackageError("Оплата пакета участия недоступна");
      return;
    }
    if (response.result.currency_id == 1 && response.result.price < 10) {
      setPackageError("Оплата пакета участия недоступна #2");
      return;
    }
    if (response.result.object_type == 3) {
      setPackageError("Оплата пакета участия недоступна #3");
      return;
    }

    setPackageData(response.result);
  };

  const getUser = async (username) => {
    const response = (await RequestService.getUser(username)).data;
    // console.log(response);
    if (!response.response_code) {
      setPackageError(response.message);
      return;
    }
    setUserData(response.result);
  };

  return (
    <RegularPage
      error={packageError}
      isDataExist={
        searchParams.get("user") ? packageData && userData : packageData
      }
      header={"Покупка пакета участия"}
      backText={"Назад"}
    >
      {(searchParams.get("user") && packageData && userData) ||
      (!searchParams.get("user") && packageData) ? (
        <div>
          <div className="row mt25 secondary-color">
            <div className="col s12">
              Пожалуйста, внимательно проверьте детали перед оплатой участия.
              После оплаты организатор получит соответствующую информацию. Для
              посещения мероприятия вам понадобится электронный билет, который
              будет храниться в разделе «Мои участия».
            </div>
          </div>
          <div className="row mt35">
            <div className="col s12 l6 mb10 center-align">
              <img
                src={
                  packageData.object_cover_img_url
                    ? packageData.object_cover_img_url
                    : noImage
                }
                className={
                  !packageData.object_cover_img_url
                    ? "image-theme-filter"
                    : null
                }
                alt="Изображение события"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  borderRadius: "15px",
                }}
              />
            </div>
            <div className="col s12 l6">
              <span className="m500">Событие/проект:</span>{" "}
              <Link to={"/events/" + packageData.parent_id} className="iq-link">
                «{packageData.object_name}»
              </Link>
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Пакет участия:</span> «{packageData.name}»
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Цена:</span>{" "}
              {formatNumber(packageData.price)} {packageData.currency_symbol}
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Формат участия:</span>{" "}
              {packageData.object_format == 1
                ? "Офлайн"
                : packageData.object_format == 2
                ? "Онлайн"
                : "Смешанный (онлайн + офлайн)"}
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Дата и время:</span>{" "}
              {moment(packageData.object_datetime).format("DD.MM.YYYY HH:mm") +
                " (" +
                (packageData.object_timezone_description
                  ? packageData.object_timezone_description
                  : "время местное") +
                ")"}
            </div>
            <div className="col s12 l6 mt10">
              <span className="m500">Место:</span>{" "}
              {packageData.object_location +
                "; " +
                packageData.object_address +
                "; " +
                packageData.object_place}
            </div>
            {userData ? (
              <div className="col s12 l6 mt10">
                <span className="m500">Участник:</span>{" "}
                {userData.first_name +
                  " " +
                  userData.last_name +
                  " (@" +
                  userData.alias +
                  ")"}
              </div>
            ) : null}
            <div
              className="col s12 center-align alert-message mt25"
              style={{ padding: "15px", borderRadius: "15px" }}
            >
              Перед тем, как нажать на кнопку «Оплатить», всегда проверяйте, что
              в адресной строке браузера на данный момент указан корректный
              адрес нашего сервиса (доменное имя) —{" "}
              <span className="m500 accent-color">nott.one</span>. Никогда не
              оплачивайте участие на похожих сайтах — это мошенники.
            </div>
            <form method="POST" action="https://yoomoney.ru/quickpay/confirm">
              {!searchParams.get("user") ? (
                <div>
                  <div className="col s12 l500 mt25">
                    Укажите email, на который вы оформляете покупку
                  </div>
                  <div className="col s12 secondary-color mt10">
                    Мы автоматически создадим вам аккаунт Nott.one, куда добавим
                    вашу покупку в раздел «Мои участия».
                  </div>
                  <div className="col l3"></div>
                  <div className="input-field col s12 l6">
                    <input
                      placeholder="youremail@example.com"
                      id="customer-email"
                      name="customer-email"
                      type="text"
                      onChange={(e) => {
                        setCustomerEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="col l3"></div>
                </div>
              ) : null}
              <input type="hidden" name="receiver" value="4100116599534554" />
              <input
                type="hidden"
                name="label"
                value={
                  "nott@@" +
                  params.id +
                  "@@" +
                  (searchParams.get("user")
                    ? searchParams.get("user")
                    : customerEmail)
                }
              />
              <input type="hidden" name="quickpay-form" value="button" />
              <input
                type="hidden"
                name="sum"
                value={packageData.price}
                data-type="number"
              />
              <input
                type="hidden"
                name="successURL"
                value="https://nott.one/thanks"
              />
              <input type="radio" name="paymentType" value="AC" />
              <div className="col s12 center-align mt25">
                После нажатия кнопки «Оплатить», вы будете перенаправлены на
                защищенную страницу сервиса{" "}
                <a
                  href="https://yoomoney.ru"
                  className="iq-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  ЮMoney
                </a>{" "}
                для оплаты.
              </div>
              <div className="col s12 mt35 center-align">
                <Button flat large className="iq-btn">
                  Оплатить {formatNumber(packageData.price)}{" "}
                  {packageData.currency_symbol}
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </RegularPage>
  );
};

export default BuyPackage;
