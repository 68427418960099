import React, { useContext, useEffect, useRef, useState } from "react";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { Tab, Tabs, Button, Icon, TextInput } from "react-materialize";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context";
import RequestService from "../api/RequestService";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import UserSmallCardList from "../components/UI/Users/UserSmallCard/UserSmallCardList";
import { copyTextToClipboard, formatNumber, parseForm } from "../utils/service";
import useConfirm from "../context/ConfirmDialog";

const Referral = () => {
  const [followersData, setFollowersData] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [followerPage, setFollowerPage] = useState(1);
  const lastFollowerElement = useRef();
  const [partnerCodeElements, setPartnerCodeElements] = useState([]);
  const [codeName, setCodeName] = useState("");
  const [codeComment, setCodeComment] = useState("");

  const [followerRequestsData, setFollowerRequestsData] = useState(null);

  const navigate = useNavigate();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  const getFollowers = async (page = 1) => {
    try {
      if (followersData && page > followersData.total_pages) return;
      let response = (await RequestService.getReferrals(page)).data;
      // console.log(response);
      if (!response.response_code) {
        console.error(response);
        return;
      }
      setPageError(null);
      setFollowersData(response.result);
    } catch (error) {
      // console.log(error);
      setPageError(error);
    }
  };

  const removeCode = async (code) => {
    // if (
    //   window.confirm(
    //     "Вы действительно хотите удалить промокод " + code.toUpperCase() + "?"
    //   )
    // ) {
    const choice = await confirm({
      description:
        "Вы действительно хотите удалить промокод " + code.toUpperCase() + "?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.removePartnerCode(code)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      getPartnerCodes();
    }
  };

  const createCode = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    // for (let pair of sendFormData.entries()) {
    //   console.log(pair);
    // }
    const response = (
      await RequestService.sendForm(sendFormData, "/referrals/createCode")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    setCodeName("");
    setCodeComment("");

    getPartnerCodes();
  };

  const getPartnerCodes = async () => {
    const response = (await RequestService.getPartnerCodes()).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }

    let partnerCodes = response.result;

    let partnerCodesArr = [];
    let counter = 1;
    partnerCodes.forEach((partnerCode) => {
      partnerCodesArr.push(
        <div className="col s12" key={counter}>
          <div
            className="divider mt15 mb15"
            style={{ backgroundColor: "var(--text-tertiary-color)" }}
          ></div>
          <div className="row nm">
            <div className="col s12">
              <span className="m500">{counter}. </span>
              <span>{partnerCode.code.toUpperCase()}</span>
            </div>
            <div className="col s12">
              <span className="m500">Реферальная ссылка: </span>
              {partnerCode.referral_link}
            </div>
            <div className="col s12">
              <span className="m500">Комментарий: </span>
              {partnerCode.comment ? partnerCode.comment : "комментария нет"}
            </div>
            <div className="col s12 mt15">
              <Button
                className="iq-btn"
                onClick={() => {
                  copyTextToClipboard(
                    partnerCode.referral_link,
                    "Ссылка с промокодом " +
                      partnerCode.code.toUpperCase() +
                      " скопирована!"
                  );
                }}
              >
                <Icon left>content_copy</Icon>
                Скопировать ссылку
              </Button>
              <Button
                className="iq-btn pastel-btn hide-on-med-and-down"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  removeCode(partnerCode.code.toUpperCase());
                }}
              >
                Удалить код и ссылку
              </Button>
            </div>
            <div className="col s12 mt15 hide-on-large-only">
              <Button
                className="iq-btn pastel-btn"
                onClick={() => {
                  removeCode(partnerCode.code.toUpperCase());
                }}
              >
                Удалить код и ссылку
              </Button>
            </div>

            {/* {partnerCode.is_current_session == false ? (
              <div className="col s12 mt10">
                <span
                  className="iq-link"
                  onClick={() => {
                    closeSession(partnerCode.id);
                  }}
                >
                  <i
                    className="material-icons tiny accent-color"
                    style={{ position: "absolute", marginTop: "3px" }}
                  >
                    cancel
                  </i>
                  <span style={{ marginLeft: "20px" }}>Завершить сеанс</span>
                </span>
              </div>
            ) : null} */}
          </div>
        </div>
      );
      counter++;
    });

    setPartnerCodeElements(partnerCodesArr);
  };

  const [fetchFollowers, isFollowersLoading, followersError] =
    useFetching(getFollowers);

  useEffect(() => {
    // console.log(projectPage);
    fetchFollowers(followerPage);
    getPartnerCodes();
  }, [followerPage]);

  useObserver(
    lastFollowerElement,
    followersData && followerPage < followersData.total_pages,
    isFollowersLoading,
    () => {
      setFollowerPage(followerPage + 1);
    }
  );

  return (
    <RegularPage
      error={null}
      isDataExist={true}
      header={"Партнерская программа"}
      isPrimaryPage={false}
      hasBaseCard={true}
      backText={"Назад"}
    >
      <Tabs className="tabs-fixed-width mt25" scope="tabs-tickets">
        {/* <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title="Описание"
          className="mt30"
        >
          1
        </Tab> */}
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Ссылки и промокоды"}
          className="mt30"
        >
          <div className="row">
            <div className="col s12 secondary-color">
              Вы можете создавать до 10 партнерских кодов (еще мы их называем
              промокодами). Длина каждого промокода может быть не менее 4
              символов (латинские буквы, цифры, символ нижнего подчеркивания).
              При регистрации в Nott.one пользователь скоро сможет указать
              промокод — в таком случае он станет вашим рефералом. Также вы
              можете поделиться одной из своих реферальных ссылок — они
              генерируются просто — созданный вами промокод добавляется в виде
              GET-параметра «from». Если пользователь перейдет по вашей ссылке и
              зарегистрируется в Nott.one, то он тоже станет вашим рефералом.
            </div>
          </div>
          <form id="new-package" onSubmit={createCode}>
            <div className="row">
              <TextInput
                id="code"
                name="code"
                icon="handshake"
                s={12}
                l={4}
                label="Код партнера"
                placeholder="YOUR_CODE"
                value={codeName}
                onChange={(e) => {
                  setCodeName(e.target.value);
                }}
                required
              />

              <TextInput
                id="comment"
                name="comment"
                icon="notes"
                s={12}
                l={8}
                label="Комментарий"
                placeholder="Необязательно"
                value={codeComment}
                onChange={(e) => {
                  setCodeComment(e.target.value);
                }}
              />

              <div className="col s12 center-align mt20">
                <Button flat className="iq-btn" type="submit">
                  Создать промокод
                </Button>
              </div>
            </div>
          </form>
          <div className="row mt30 mb10">
            <div className="col s12 l500">Ваши промокоды и ссылки</div>
          </div>
          <div className="row nm">
            {partnerCodeElements.length > 0 ? (
              partnerCodeElements
            ) : (
              <div className="col s12 center-align">
                Активных промокодов и ссылок нет
              </div>
            )}
          </div>
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Рефералы"}
          className="mt30"
        >
          {isFollowersLoading ? (
            <CircleLoader />
          ) : (
            <div>
              {followersData ? (
                <div className="row">
                  <div className="col s12 l500 center-align">
                    Всего {formatNumber(followersData.elements.length)}{" "}
                    рефералов
                  </div>
                </div>
              ) : null}
              <UserSmallCardList usersData={followersData} isPastel={true} />
            </div>
          )}
          <div
            ref={lastFollowerElement}
            // style={{ backgroundColor: "red", height: "10px" }}
          ></div>
        </Tab>
        {/* <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Статистика"}
          className="mt30"
        >
          4
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Выплаты"}
          className="mt30"
        >
          5
        </Tab> */}
      </Tabs>
    </RegularPage>
  );
};

export default Referral;
