import React from "react";
import { useNavigate } from "react-router-dom";
import CircleLoader from "../CircleLoader/CircleLoader";

const RegularPage = ({
  error,
  isDataExist,
  header,
  backText = null,
  backPath = -1,
  isPrimaryPage = false,
  hasBaseCard = true,
  hasAppearanceAnimation = false,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="regular-page"
      style={{
        paddingTop: isPrimaryPage ? "0px" : "35px",
      }}
    >
      <div className="container">
        {isPrimaryPage ? (
          <div className="row row mb30 mt10">
            <div className="col s12 xl500">
              <h1 style={{ marginBottom: 0 }} className="truncate">
                {header}
              </h1>
            </div>
          </div>
        ) : null}
        {error ? (
          <div
            className="row"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="col s12 center-align">
              <div className="xl500">{error}</div>
            </div>
          </div>
        ) : isDataExist ? (
          hasBaseCard ? (
            <div className="row">
              <div className="col s12">
                <div
                  className={
                    hasAppearanceAnimation ? "card animation-appear" : "card"
                  }
                >
                  <div className="card-content">
                    {backText ? (
                      <div className="row">
                        <div className="col s12">
                          <button
                            className="btn flat-btn iq-btn pastel-btn"
                            onClick={() => {
                              navigate(backPath);
                            }}
                          >
                            <i className="material-icons-round left">
                              arrow_back_ios
                            </i>{" "}
                            {backText}
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {!isPrimaryPage ? (
                      <div className="row nm">
                        <div className="col s12 xl500">{header}</div>
                      </div>
                    ) : null}
                    <div>{children}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>{children}</div>
          )
        ) : (
          <div
            className="row"
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="col s12 center-align">
              <CircleLoader />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegularPage;
