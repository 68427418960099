import React, { useEffect, useState } from "react";

const FilePreview = ({ fileInputElementId, removeFn, file }) => {
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return preview ? (
    <div className="col s12 l3 center-align" style={{ position: "relative" }}>
      <img
        src={preview}
        style={{
          height: "100%",
          width: "100%",
          marginTop: "30px",
        }}
        alt="Ваше изображение"
      />
      <div
        style={{
          position: "absolute",
          width: "25px",
          height: "25px",
          backgroundColor: "var(--accent-color)",
          borderRadius: "50px",
          left: "0px",
          top: "17px",
          cursor: "pointer",
        }}
        onClick={() => {
          let fileInput = document.getElementById(fileInputElementId);
          fileInput.value = fileInput.defaultValue;
          removeFn(file.name);
        }}
      >
        <i
          className="material-icons-round"
          style={{
            color: "var(--text-primary-color)",
            fontSize: "20px",
            marginLeft: "0px",
            marginTop: "2.5px",
          }}
        >
          close
        </i>
      </div>
    </div>
  ) : null;
};

export default FilePreview;
