import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { AuthContext } from "../context";
import useConfirm from "../context/ConfirmDialog";

const SettingsPrivacy = () => {
  const [userData, setUserData] = useState(null);
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  const getUser = async () => {
    const response = (await RequestService.getUser()).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setUserData(response.result);
  };

  const saveAccountType = async (isPrivate) => {
    setFullScreenLoading(true);
    const response = (await RequestService.setAccountType(isPrivate)).data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    await getUser();
    setFullScreenLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <RegularPage
      isDataExist={userData}
      hasBaseCard={true}
      header={"Конфиденциальность"}
      isPrimaryPage={false}
      backText={"Назад"}
      hasAppearanceAnimation={true}
    >
      {userData ? (
        <div className="row mt25">
          <div className="col s12 l500">
            <i
              className="material-icons-outlined accent-color"
              style={{ position: "absolute", marginTop: "3px" }}
            >
              visibility_off
            </i>
            <span style={{ marginLeft: "35px" }}>Закрытый профиль</span>
          </div>
          <div className="col s12 mt10">
            Вы можете сделать свой профиль непубличным — в этом случае ваши
            события и проекты, а также общую активность смогут видеть только
            подписчики. Чтобы стать вашим подписчиком, другим пользователям
            нужно будет подать заявку, а вам — одобрить ее.
          </div>
          <div className="col s12 mt20">
            <div className="switch">
              <label style={{ fontSize: "100%" }}>
                <input
                  checked={userData.is_private == 1 ? "checked" : ""}
                  type="checkbox"
                  id="settings-is-private"
                  name="settings-is-private"
                  onChange={(e) => {
                    e.target.checked ? saveAccountType(1) : saveAccountType(0);
                  }}
                />
                <span className="lever" style={{ margiLeft: "0px" }}></span>
                <span className="tertiary-color">Закрытый аккаунт</span>
              </label>
            </div>
          </div>
        </div>
      ) : null}
    </RegularPage>
  );
};

export default SettingsPrivacy;
