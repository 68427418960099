import React from "react";
import noImage from "../../../images/no-image.png";
import { formatNumber, toTimeZone } from "../../../utils/service";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EventItem = ({
  event,
  setMapState = null,
  mapState = null,
  onSurface = false,
  width = null,
  withInterests = true,
}) => {
  const navigate = useNavigate();
  const pp = event.participation_packages.sort((a, b) => a.price - b.price)[0];
  const minPrice = pp ? pp.price : null;
  let participantsCount = 0;
  if (event.participation_packages) {
    event.participation_packages.forEach((pack) => {
      participantsCount += pack.participants_count;
    });
  }

  return (
    // <div className="col s12 l4">
    <div
      className={
        onSurface
          ? "card mb30 hand-cursor bg animation-appear"
          : "card mb30 blur-card hand-cursor animation-appear"
      }
      onMouseOver={() =>
        setMapState && mapState
          ? setMapState({
              ...mapState,
              center: [event.latitude, event.longitude],
              zoom: 16,
            })
          : null
      }
      onClick={() => {
        navigate("/events/" + event.id);
      }}
      style={width ? { width: width } : {}}
    >
      <div className="card-content">
        <div className="row nm">
          {event.is_owner ||
          event.is_participant ||
          event.role == 1 ||
          event.role == 2 ? (
            <div className="col s12 center-align mb25">
              {event.is_participant ? (
                <span className="pastel-chip pastel-shadow">Вы участник</span>
              ) : (
                ""
              )}
              {event.is_owner ? (
                <span className="pastel-chip pastel-shadow">
                  Вы организатор
                </span>
              ) : (
                ""
              )}
              {event.role == 1 ? (
                <span className="pastel-chip pastel-shadow">Вы контролер</span>
              ) : (
                ""
              )}
              {event.role == 2 ? (
                <span className="pastel-chip pastel-shadow">Вы редактор</span>
              ) : (
                ""
              )}
            </div>
          ) : null}
          <div className="col s12">
            <img
              src={event.cover_img_url ? event.cover_img_url : noImage}
              className={!event.cover_img_url ? "image-theme-filter" : null}
              alt="Обложка мероприятия"
              style={{ width: "100%", borderRadius: "15px" }}
            />
          </div>
          <div className="col s12 l500 mt10">{event.name}</div>
          {minPrice ? (
            <div className="col s12">
              От {formatNumber(minPrice)}{" "}
              {pp.currency_symbol ? pp.currency_symbol : pp.currency_code}
            </div>
          ) : null}
          {event.interests.length > 0 && withInterests ? (
            <div className="col s12 mt10">
              {event.interests.map((interest) => (
                <div className="chip" key={interest.name}>
                  {"#" + interest.name}
                </div>
              ))}
            </div>
          ) : null}
          {event.datetime ? (
            <div
              className={
                new Date().getTime() > new Date(event.datetime).getTime()
                  ? "col s12 mt10 error-color"
                  : "col s12 mt10"
              }
            >
              <i
                className="material-icons-round"
                style={{ position: "absolute", marginTop: "-2px" }}
              >
                event
              </i>
              <span style={{ marginLeft: "30px" }}>
                {moment(event.datetime).format("DD.MM.YYYY HH:mm")}
              </span>
            </div>
          ) : null}
          {/* {participantsCount ? (
            <div className="col s12 mt10">
              <i
                className="material-icons-round"
                style={{ position: "absolute", marginTop: "-2px" }}
              >
                groups
              </i>

              <span style={{ marginLeft: "30px" }}>
                {participantsCount} чел. уже идут
              </span>
            </div>
          ) : null} */}
          {event.location ? (
            <div className="col s12 mt10">
              <i
                className="material-icons-outlined"
                style={{ position: "absolute", marginTop: "-2px" }}
              >
                place
              </i>
              <span style={{ marginLeft: "30px" }}>{event.location}</span>
            </div>
          ) : null}

          {event.format ? (
            <div className="col s12 mt10">
              <i
                className="material-icons-round"
                style={{ position: "absolute", marginTop: "-2px" }}
              >
                maps_home_work
              </i>
              <span style={{ marginLeft: "30px" }}>
                {event.format == 1
                  ? "Офлайн"
                  : event.format == 2
                  ? "Онлайн"
                  : "Смешанный"}{" "}
                формат участия
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default EventItem;
