import React from "react";
import SquareIconButton from "../SquareIconButton/SquareIconButton";

const ButtonPanel = ({ btnsArr, panelColor = false }) => {
  let btns = [];
  for (let i = 0; i < btnsArr.length; i++) {
    if (btnsArr[i] === null) {
      continue;
    }
    let mrl = 0;
    if (btnsArr.length > 1) {
      mrl = 10;
    }
    btns.push(
      <SquareIconButton
        iconName={btnsArr[i].icon_name}
        title={btnsArr[i].title}
        key={btnsArr[i].icon_name + "_" + btnsArr[i].title}
        onClick={btnsArr[i].on_click}
        marginRL={mrl}
        panelColor={panelColor}
        disabled={btnsArr[i].disabled}
        classes={btnsArr[i].classes}
        attributes={btnsArr[i].attributes}
        modal={btnsArr[i].modal}
      />
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {btns}
    </div>
  );
};

export default ButtonPanel;
