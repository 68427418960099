import React, { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-materialize";
import { useNavigate } from "react-router-dom";
import RequestService from "../api/RequestService";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import UserSmallCardList from "../components/UI/Users/UserSmallCard/UserSmallCardList";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import { AuthContext } from "../context";
import { formatNumber } from "../utils/service";

const Subscriptions = () => {
  const [followersData, setFollowersData] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [followerPage, setFollowerPage] = useState(1);
  const lastFollowerElement = useRef();

  const [followerRequestsData, setFollowerRequestsData] = useState(null);

  const navigate = useNavigate();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);

  const getFollowers = async (page = 1) => {
    try {
      if (followersData && page > followersData.total_pages) return;
      let response = (await RequestService.getSubscriptions(page)).data;
      //   console.log(response);
      if (!response.response_code) {
        console.error(response);
        return;
      }
      setPageError(null);
      setFollowersData(response.result);
    } catch (error) {
      // console.log(error);
      setPageError(error);
    }
  };

  const [fetchFollowers, isFollowersLoading, followersError] =
    useFetching(getFollowers);

  useEffect(() => {
    // console.log(projectPage);
    fetchFollowers(followerPage);
  }, [followerPage]);

  useObserver(
    lastFollowerElement,
    followersData && followerPage < followersData.total_pages,
    isFollowersLoading,
    () => {
      setFollowerPage(followerPage + 1);
    }
  );

  return (
    <div>
      <RegularPage
        error={pageError}
        isDataExist={1}
        hasBaseCard={true}
        header={"Подписки"}
        isPrimaryPage={false}
        backText={"Назад"}
      >
        {isFollowersLoading ? (
          <CircleLoader />
        ) : (
          <div className="mt25">
            {followersData ? (
              <div className="row">
                <div className="col s12 l500 center-align">
                  Всего {formatNumber(followersData.elements.length)}{" "}
                  подписок
                </div>
              </div>
            ) : null}
            <UserSmallCardList usersData={followersData} isPastel={true} />
          </div>
        )}
        <div
          ref={lastFollowerElement}
          // style={{ backgroundColor: "red", height: "10px" }}
        ></div>
      </RegularPage>
    </div>
  );
};

export default Subscriptions;
