import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Icon, TextInput, Button } from "react-materialize";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { AuthContext } from "../context";
import { parseForm } from "../utils/service";
import { UAParser } from "ua-parser-js";
import moment from "moment";
import useConfirm from "../context/ConfirmDialog";

const SettingsPrivacy = () => {
  const [userData, setUserData] = useState(null);
  const [sessionElements, setSessionElements] = useState([]);
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  const getUser = async () => {
    const response = (await RequestService.getUser()).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }
    setUserData(response.result);
  };

  const getUserActiveSessions = async () => {
    const response = (await RequestService.getUserActiveSessions()).data;
    // console.log(response);
    if (!response.response_code) {
      return;
    }

    let sessions = response.result;

    let sessionsArr = [];
    let counter = 1;
    sessions.forEach((session) => {
      let deviceInfo = session.user_agent
        ? new UAParser(session.user_agent).getResult()
        : null;

      sessionsArr.push(
        <div className="col s12" key={session.id}>
          <div
            className="divider mt15 mb15"
            style={{ backgroundColor: "var(--text-tertiary-color)" }}
          ></div>
          <div className="row nm">
            {session.is_current_session == true ? (
              <div className="col s12 mb10">
                <span className="accent-counter">Текущий сеанс</span>
              </div>
            ) : null}

            <div className="col s12">
              <span className="m500">{counter}. </span>
              {deviceInfo
                ? deviceInfo.device.vendor +
                  " " +
                  deviceInfo.device.model +
                  " (" +
                  deviceInfo.os.name +
                  " " +
                  deviceInfo.os.version +
                  ", " +
                  deviceInfo.browser.name +
                  " " +
                  deviceInfo.browser.version +
                  ")"
                : // ? new UAParser(session.user_agent).os
                  "Устройство не опознано"}
            </div>
            <div className="col s12">
              <span className="m500">IP: </span>
              {session.ip}
            </div>
            <div className="col s12">
              <span className="m500">Завершится: </span>
              {moment(session.refresh_token_expires).format(
                "DD.MM.YYYY HH:mm:ss"
              )}
            </div>

            {session.is_current_session == false ? (
              <div className="col s12 mt10">
                <span
                  className="iq-link"
                  onClick={() => {
                    closeSession(session.id);
                  }}
                >
                  <i
                    className="material-icons tiny accent-color"
                    style={{ position: "absolute", marginTop: "3px" }}
                  >
                    cancel
                  </i>
                  <span style={{ marginLeft: "20px" }}>Завершить сеанс</span>
                </span>
              </div>
            ) : null}
          </div>
        </div>
      );
      counter++;
    });

    setSessionElements(sessionsArr);
  };

  const changePassword = async (e) => {
    e.preventDefault();

    // if (
    //   window.confirm(
    //     "При изменении пароля все ваши сеансы, кроме текущего, будут завершены (на закрытие сессий требуется от нескольких секунд до 10 минут). Вам потребуется авторизоваться на других устройствах заново."
    //   )
    // ) {
    const choice = await confirm({
      description:
        "При изменении пароля все ваши сеансы, кроме текущего, будут завершены (на закрытие сессий требуется от нескольких секунд до 10 минут). Вам потребуется авторизоваться на других устройствах заново.",
    });
    if (choice) {
      setFullScreenLoading(true);
      let htmlForm = e.currentTarget;
      let sendFormData = parseForm(htmlForm);

      const response = (
        await RequestService.sendForm(sendFormData, "/profile/changePassword")
      ).data;
      // console.log(response);
      setFullScreenLoading(false);

      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      htmlForm.reset();

      await confirm({
        description:
          "Пароль успешно изменен. Все сеансы, кроме текущего, были закрыты.",
        isAlert: true,
      });
      // alert(
      //   "Пароль успешно изменен. Все сеансы, кроме текущего, были закрыты."
      // );
      getUserActiveSessions();
    }
  };

  const closeSessionsExceptCurrent = async () => {
    // if (
    //   window.confirm(
    //     "Все сеансы, кроме текущего, будут завершены (на закрытие сессий требуется от нескольких секунд до 10 минут). На всех других устройствах вам придется заново ввести пароль для входа в аккаунт. Вы действительно хотите продолжить?"
    //   )
    // ) {
    const choice = await confirm({
      description:
        "Все сеансы, кроме текущего, будут завершены (на закрытие сессий требуется от нескольких секунд до 10 минут). На всех других устройствах вам придется заново ввести пароль для входа в аккаунт. Вы действительно хотите продолжить?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.resetSessions()).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      getUserActiveSessions();
    }
  };

  const closeSession = async (sessionId) => {
    // if (
    //   window.confirm(
    //     "Вы действительно хотите завершить сессию? На закрытие сессии потребуется от нескольких секунд до 10 минут."
    //   )
    // ) {
    const choice = await confirm({
      description:
        "Вы действительно хотите завершить сессию? На закрытие сессии потребуется от нескольких секунд до 10 минут.",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.resetSession(sessionId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      getUserActiveSessions();
    }
  };

  useEffect(() => {
    getUser();
    getUserActiveSessions();
  }, []);

  return (
    <RegularPage
      isDataExist={userData}
      hasBaseCard={true}
      header={"Безопасность"}
      isPrimaryPage={false}
      backText={"Назад"}
      hasAppearanceAnimation={true}
    >
      {userData ? (
        <div className="row mt25">
          <div className="col s12 l500">
            <i
              className="material-icons-outlined accent-color"
              style={{ position: "absolute", marginTop: "3px" }}
            >
              key
            </i>
            <span style={{ marginLeft: "35px" }}>Новый пароль</span>
          </div>
          <div className="col s12 mt10">
            Вы можете задать новый пароль, вместо существующего. Пароль должен
            быть не менее 8 символов. Не используйте простые пароли, иначе
            злоумышленнику будет просто подобрать комбинацию для вашего
            аккаунта.
          </div>
          <div className="col l3"></div>
          <div className="col s12 l6">
            <div className="row mt10">
              <form onSubmit={changePassword}>
                <TextInput
                  id="password-current"
                  name="password-current"
                  type="password"
                  label="Текущий пароль"
                  s={12}
                  icon={<Icon>key</Icon>}
                  onChange={(e) => {
                    //   setLoginData({ ...loginData, login: e.target.value });
                  }}
                  required
                />

                <TextInput
                  id="password-1"
                  name="password-1"
                  type="password"
                  label="Новый пароль"
                  s={12}
                  icon={<Icon>lock_outlined</Icon>}
                  onChange={(e) => {
                    //   setLoginData({ ...loginData, login: e.target.value });
                  }}
                  required
                />

                <TextInput
                  id="password-2"
                  name="password-2"
                  type="password"
                  label="Подтвердите пароль"
                  s={12}
                  icon={<Icon>lock</Icon>}
                  onChange={(e) => {
                    //   setLoginData({ ...loginData, login: e.target.value });
                  }}
                  required
                />

                <div className="col s12 center-align mt15">
                  <input
                    type="submit"
                    className="btn iq-btn btn-flat"
                    value="Сохранить пароль"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="col l3"></div>

          <div className="col s12 l500 mt20">
            <i
              className="material-icons-outlined accent-color"
              style={{ position: "absolute", marginTop: "3px" }}
            >
              devices
            </i>
            <span style={{ marginLeft: "35px" }}>Активные сеансы</span>
          </div>
          <div className="col s12 mt10">
            Ниже указаны ваши текущие активные сеансы. Вы можете завершить все,
            кроме текущего.
          </div>
          <div className="col s12 mt15 center-align">
            <Button className="iq-btn" onClick={closeSessionsExceptCurrent}>
              Завершить все, кроме текущего
            </Button>
          </div>
          <div className="col s12 mt10">
            <div className="row nm">{sessionElements}</div>
          </div>
        </div>
      ) : null}
    </RegularPage>
  );
};

export default SettingsPrivacy;
