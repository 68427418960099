import React from "react";
import CircleLoader from "../CircleLoader/CircleLoader";

const FullScreenLoader = ({ state }) => {
  //   console.log(state);
  if (state) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "visible";
    return;
  }

  return (
    <div
      style={{
        backgroundColor: "var(--bg-color-transparent)",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: "9999",
        left: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overscrollBehavior: "contain",
      }}
    >
      <CircleLoader />
    </div>
  );
};

export default FullScreenLoader;
