import React from "react";
import ChatItem from "../ChatItem/ChatItem";

const ChatList = ({ chats, onSurface = false }) => {
  const failure = (
    <div className="row nm">
      <div className="col s12 center-align">Пока пусто</div>
    </div>
  );
  if (!chats || chats.length === 0) return failure;
  // console.log(chats);

  return (
    <div className="row nm animation-appear">
      <div className="col s12">
        {chats.map((el) => (
          <ChatItem chat={el} key={el.id} onSurface={onSurface} />
        ))}
      </div>
    </div>
  );
};

export default ChatList;
