import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import ErrorMessage from "../components/UI/ErrorMessage/ErrorMessage";
import ProjectList from "../components/UI/Project/ProjectList";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { Button, Icon, Select } from "react-materialize";
import { useFetching } from "../hooks/useFetching";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import ProjectTiles from "../components/UI/Project/ProjectTiles";
import { useObserver } from "../hooks/useObserver";
import { AuthContext } from "../context";
import { useSearchParamsArray } from "../hooks/useSearchParamsArray";
import Filter from "../components/UI/Filter/Filter";

const Projects = () => {
  const [projectsData, setProjectsData] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectPage, setProjectPage] = useState(1);
  const lastProjectElement = useRef();
  const navigate = useNavigate();
  const { isAuth, setIsAuth, isLoading, setLoading } = useContext(AuthContext);
  const searchParamsArray = useSearchParamsArray();
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectMoneyRequired, setProjectMoneyRequired] = useState("");
  const [projectTeamRequired, setProjectTeamRequired] = useState("");
  const [interestOptions, setInterestOptions] = useState(null);
  const [interestIds, setInterestIds] = useState([""]);
  const [interests, setInterests] = useState([]);

  const [selectState, setSelectState] = useState(null);

  const getProjects = async (page = 1, fromScratch = false) => {
    try {
      if (!fromScratch && projectsData && page > projectsData.total_pages)
        return;
      const filterParams = searchParamsArray.array;
      const urlSearchParams = new URLSearchParams();
      filterParams.forEach((filterParam) => {
        urlSearchParams.append(filterParam[0], filterParam[1]);
      });

      let response = (
        await RequestService.getProjects(null, page, urlSearchParams.toString())
      ).data; //
      // console.log(response);
      if (!response.response_code) {
        console.error(response);
        setPageError(response.message);
        return;
      }
      setPageError(null);
      setProjectsData(response.result);
      let newProjects = fromScratch
        ? [...response.result.elements]
        : [...projects, ...response.result.elements];
      setProjects(newProjects);
    } catch (error) {
      // console.log(error);
      setPageError(error);
    }
  };

  const setFrontFilters = async () => {
    let interestsArray = await getInterests();
    // console.log(interestsArray);

    const filterParams = searchParamsArray.array;
    const urlSearchParams = new URLSearchParams();
    let tempInterestsIds = [];
    filterParams.forEach((filterParam) => {
      switch (filterParam[0]) {
        case "money_required":
          setProjectMoneyRequired(filterParam[1]);
          break;

        case "team_required":
          setProjectTeamRequired(filterParam[1]);
          break;

        case "interests[]":
          tempInterestsIds.push(filterParam[1]);
          break;

        default:
          break;
      }
    });

    // console.log(tempInterestsIds);
    setInterestIds(tempInterestsIds);
    // console.log(filterParams);
  };

  const getInterests = async (e) => {
    const interestsResponse = (await RequestService.getInterests()).data;
    // console.log(interestsResponse);
    if (!interestsResponse.response_code) {
      return;
    }
    // console.log(interestsResponse.result);
    let interestOptions = [];
    interestOptions.push(
      <option disabled value="" key="0">
        Выберите один или несколько
      </option>
    );
    interestsResponse.result.forEach((interest) => {
      interestOptions.push(
        <option value={interest.id} key={interest.id}>
          {interest.name}
        </option>
      );
    });
    setInterests(interestsResponse.result);
    setInterestOptions(interestOptions);

    return interestsResponse.result;
  };

  const [fetchProjects, isProjectsLoading, projectsError] =
    useFetching(getProjects);

  useEffect(() => {
    // console.log(projectPage);
    if (projectPage == 1) {
      fetchProjects(projectPage, true);
    } else {
      fetchProjects(projectPage);
    }
  }, [projectPage]);

  useEffect(() => {
    fetchProjects(1, true);
    setFrontFilters();
  }, [searchParams]);

  useEffect(() => {
    // console.log(interestIds);
    setSelectState(Math.random());
  }, [interestIds]);

  useObserver(
    lastProjectElement,
    projectsData && projectPage < projectsData.total_pages,
    isProjectsLoading,
    () => {
      setProjectPage(projectPage + 1);
    }
  );

  return (
    <div>
      <RegularPage
        error={pageError}
        isDataExist={1}
        hasBaseCard={false}
        header={"Проекты"}
        isPrimaryPage={true}
      >
        <Filter
          title={"Фильтр по проектам"}
          resetPage={() => {
            setProjectPage(1);
          }}
          resetForm={() => {
            setProjectMoneyRequired("");
            setProjectTeamRequired("");
            setInterestIds([]);
          }}
        >
          <Select
            id="filter-money_required"
            name="filter-money_required"
            multiple={false}
            label={"Ищут финансирование"}
            s={12}
            l={6}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                coverTrigger: false,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250,
              },
            }}
            // defaultValue={eventData.format ? eventData.format : null}
            value={projectMoneyRequired}
          >
            <option value="">Не важно</option>
            <option value="1">Да</option>
            <option value="0">Нет</option>
          </Select>

          <Select
            id="filter-team_required"
            name="filter-team_required"
            multiple={false}
            label={"Ищут команду"}
            s={12}
            l={6}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                coverTrigger: false,
                hover: false,
                inDuration: 150,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 250,
              },
            }}
            // defaultValue={eventData.format ? eventData.format : null}
            value={projectTeamRequired}
          >
            <option value="">Не важно</option>
            <option value="1">Да</option>
            <option value="0">Нет</option>
          </Select>

          <Select
            id="filter-interests"
            name="filter-interests"
            s={12}
            l={8}
            label={"Сфера интересов"}
            multiple
            value={interestIds}
            key={selectState}
            data-type={"array"}
            options={{
              classes: "",
              dropdownOptions: {
                alignment: "left",
                // autoTrigger: true,
                closeOnClick: true,
                constrainWidth: true,
                // coverTrigger: true,
                // hover: false,
                // inDuration: 150,
                // onCloseEnd: null,
                // onCloseStart: null,
                // onOpenEnd: null,
                // onOpenStart: null,
                // outDuration: 250,
              },
            }}
            // value={[""]}
          >
            {interestOptions}
          </Select>
        </Filter>
        {isAuth ? (
          <div className="row mb30 mt30">
            <div className="col s12 center-align">
              <Button
                className="iq-btn"
                icon={<Icon left>add_circle</Icon>}
                onClick={() => {
                  navigate("/projects/create");
                }}
                flat
              >
                Создать новый проект
              </Button>
            </div>
          </div>
        ) : null}
        <ProjectTiles
          projects={projects}
          onSurface={false}
          isLoading={isProjectsLoading}
        />
        {isProjectsLoading ? <CircleLoader /> : null}
      </RegularPage>
      <div
        ref={lastProjectElement}
        // style={{ backgroundColor: "red", height: "10px" }}
      ></div>
    </div>
  );
};

export default Projects;
