import React from "react";
import { useNavigate } from "react-router-dom";
import useConfirm from "../../../../context/ConfirmDialog";

const SectionButton = ({ iconName, text, pathTo = null }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  return (
    <div
      className="card hand-cursor bg"
      onClick={() => {
        if (pathTo) {
          navigate(pathTo);
        } else {
          confirm({
            description: "Ссылка пока неактивна",
            isAlert: true,
          });
          // alert("Ссылка пока неактивна");
        }
      }}
    >
      <div className="card-content">
        <div className="row nm secondary-color">
          <div className="col s12">
            <i
              className="material-icons-round accent-color"
              style={{ position: "absolute" }}
            >
              {iconName}
            </i>
            <span className="m500" style={{ marginLeft: "45px" }}>
              {text}
            </span>
            <i
              className="material-icons-round"
              style={{ position: "absolute", right: "15px" }}
            >
              arrow_forward_ios
            </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionButton;
