import React, { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-materialize";
import { useNavigate } from "react-router-dom";
import RequestService from "../api/RequestService";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import UserSmallCardList from "../components/UI/Users/UserSmallCard/UserSmallCardList";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import { AuthContext } from "../context";
import { formatNumber } from "../utils/service";
import useConfirm from "../context/ConfirmDialog";

const Followers = () => {
  const [followersData, setFollowersData] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [followerPage, setFollowerPage] = useState(1);
  const lastFollowerElement = useRef();
  const confirm = useConfirm();

  const [followerRequestsData, setFollowerRequestsData] = useState(null);

  const navigate = useNavigate();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);

  const getFollowers = async (page = 1, fromScratch = false) => {
    try {
      if (!fromScratch && followersData && page > followersData.total_pages)
        return;
      if (fromScratch) {
        setFollowerPage(1);
        page = 1;
      }
      let response = (await RequestService.getFollowers(page)).data; //
      // console.log(response);
      if (!response.response_code) {
        console.error(response);
        return;
      }
      setPageError(null);
      setFollowersData(response.result);
    } catch (error) {
      // console.log(error);
      setPageError(error);
    }
  };

  const getFollowerRequests = async () => {
    try {
      let response = (await RequestService.getFollowerRequests()).data;
      // console.log(response);
      if (!response.response_code) {
        console.error(response);
        return;
      }

      setFollowerRequestsData(response.result);
    } catch (error) {
      // console.log(error);
      setPageError(error);
    }
  };

  const approveSubscription = async (user) => {
    setFullScreenLoading(true);
    const response = (await RequestService.approveSubscription(user)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      // alert(response.message);
      await confirm({
        description: response.message,
        isAlert: true,
      });
      return;
    }

    fetchFollowers(followerPage, true);
    fetchFollowerRequests();
  };

  const rejectSubscription = async (user) => {
    setFullScreenLoading(true);
    const response = (await RequestService.rejectSubscription(user)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      // alert(response.message);
      await confirm({
        description: response.message,
        isAlert: true,
      });
      return;
    }

    fetchFollowers(followerPage, true);
    fetchFollowerRequests();
  };

  const [fetchFollowers, isFollowersLoading, followersError] =
    useFetching(getFollowers);

  const [
    fetchFollowerRequests,
    isFollowerRequestsLoading,
    followerRequestsError,
  ] = useFetching(getFollowerRequests);

  useEffect(() => {
    // console.log(followerPage);
    fetchFollowers(followerPage);
  }, [followerPage]);

  useEffect(() => {
    fetchFollowerRequests();
  }, []);

  useObserver(
    lastFollowerElement,
    followersData && followerPage < followersData.total_pages,
    isFollowersLoading,
    () => {
      setFollowerPage(followerPage + 1);
    }
  );

  return (
    <div>
      <RegularPage
        error={pageError}
        isDataExist={1}
        hasBaseCard={true}
        header={"Подписчики"}
        isPrimaryPage={false}
        backText={"Назад"}
      >
        {followerRequestsData && followerRequestsData.elements.length > 0 ? (
          <span
            className="accent-counter xs300 error-bg"
            style={{
              position: "absolute",
              marginTop: "-10px",
              right: "12px",
              zIndex: "999",
            }}
          >
            +{followerRequestsData.elements.length}
          </span>
        ) : null}
        <Tabs className="tabs-fixed-width mt25" scope="tabs-22">
          <Tab
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false,
            }}
            title="Подписчики"
            className="mt30"
          >
            {isFollowersLoading ? (
              <CircleLoader />
            ) : (
              <div>
                {followersData ? (
                  <div className="row">
                    <div className="col s12 l500 center-align">
                      Всего {formatNumber(followersData.elements.length)}{" "}
                      подписчиков
                    </div>
                  </div>
                ) : null}
                <UserSmallCardList usersData={followersData} isPastel={true} />
              </div>
            )}
            <div
              ref={lastFollowerElement}
              // style={{ backgroundColor: "red", height: "10px" }}
            ></div>
          </Tab>
          <Tab
            options={{
              duration: 300,
              onShow: null,
              responsiveThreshold: Infinity,
              swipeable: false,
            }}
            title={"Заявки"}
            className="mt30"
          >
            {isFollowerRequestsLoading ? (
              <CircleLoader />
            ) : (
              <UserSmallCardList
                usersData={followerRequestsData}
                isPastel={true}
                actionBtnData1={{
                  text: "одобрить",
                  action: (user) => {
                    approveSubscription(user);
                  },
                }}
                actionBtnData2={{
                  text: "отклонить",
                  action: (user) => {
                    rejectSubscription(user);
                  },
                }}
              />
            )}
          </Tab>
        </Tabs>
      </RegularPage>
    </div>
  );
};

export default Followers;
