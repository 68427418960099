import moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import noAvatar from "../../../images/no-avatar.png";
import useConfirm from "../../../context/ConfirmDialog";
import RequestService from "../../../api/RequestService";
import { AuthContext } from "../../../context";
import { Modal, Button, Icon } from "react-materialize";
import { copyTextToClipboard } from "../../../utils/service";
import M from "materialize-css";

const Reviews = ({ reviews, isLoading, onSurface = true }) => {
  const confirm = useConfirm();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);

  // console.log(posts);

  const failure = (
    <div>
      <div className="row nm mt15">
        <div className="col s12 center-align">
          Информации нет или при загрузке произошла ошибка
        </div>
      </div>
    </div>
  );
  if (!reviews && !isLoading) {
    return failure;
  }
  if (reviews && !isLoading) {
    if (reviews.length == 0) {
      return failure;
    }
  }
  let flow = null;
  if (reviews) {
    // console.log(posts);

    let elements = [];
    for (let i = 0; i < reviews.length; i++) {
      elements.push(
        <div className="col s12" key={Math.random() * 100000}>
          <div
            className={
              onSurface
                ? "card mb30 bg animation-appear"
                : "card mb30 animation-appear"
            }
          >
            <div className="card-content">
              <div className="row nm">
                <Link to={"/users/" + reviews[i].alias}>
                  <div className="col s3 l1">
                    <div
                      className="circular-image pastel-shadow"
                      style={{
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <div
                        className={
                          reviews[i].avatar_img_url
                            ? "circular-image"
                            : "circular-image image-theme-filter"
                        }
                        style={{
                          backgroundImage:
                            "url(" +
                            (reviews[i].avatar_img_url
                              ? reviews[i].avatar_img_url
                              : noAvatar) +
                            ")",
                          height: "45px",
                          width: "45px",
                        }}
                      ></div>
                    </div>
                  </div>
                </Link>
                <div className="col s9 l11">
                  <Link to={"/users/" + reviews[i].alias}>
                    <div className="row nm">
                      <div className="col s12 m500">{reviews[i].name}</div>
                      <div className="col s12 s300 mt5 tertiary-color">
                        {moment(reviews[i].timestamp).format(
                          "DD.MM.YYYY HH:mm"
                        ) + " (МСК)"}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col s12 mt20">
                  <i
                    className="material-icons alert"
                    style={{ fontSize: "42px", width: "42px" }}
                  >
                    {reviews[i].grade >= 1 ? "star" : "star_outlined"}
                  </i>
                  <i
                    className="material-icons alert"
                    style={{ fontSize: "42px", width: "42px" }}
                  >
                    {reviews[i].grade >= 2 ? "star" : "star_outlined"}
                  </i>
                  <i
                    className="material-icons alert"
                    style={{ fontSize: "42px", width: "42px" }}
                  >
                    {reviews[i].grade >= 3 ? "star" : "star_outlined"}
                  </i>
                  <i
                    className="material-icons alert"
                    style={{ fontSize: "42px", width: "42px" }}
                  >
                    {reviews[i].grade >= 4 ? "star" : "star_outlined"}
                  </i>
                  <i
                    className="material-icons alert"
                    style={{ fontSize: "42px", width: "42px" }}
                  >
                    {reviews[i].grade >= 5 ? "star" : "star_outlined"}
                  </i>
                </div>
                <div className="col s12 secondary-color mt15">
                  <span className="m700">Встреча:</span>{" "}
                  {"«" +
                    reviews[i].package_name +
                    "», " +
                    moment(reviews[i].package_datetime).format(
                      "DD.MM.YYYY HH:mm"
                    )}
                </div>
                <div className="col s12 secondary-color mt15">
                  <span className="m700">Положительные моменты:</span>{" "}
                  {reviews[i].positive}
                </div>
                <div className="col s12 secondary-color mt15">
                  <span className="m700">Отрицательные моменты:</span>{" "}
                  {reviews[i].negative}
                </div>
                <div className="col s12 secondary-color mt15">
                  <span className="m700">Комментарий:</span>{" "}
                  {reviews[i].comment}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    flow = elements;
  } else {
    return (
      <div className="row nm">
        <div className="col s12 center-align">Ошибка</div>
      </div>
    );
  }

  return <div className="row nm mt15">{flow}</div>;
};

export default Reviews;
