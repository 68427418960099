import React from "react";
import moment from "moment";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import noImage from "../images/no-image.png";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import {
  TextInput,
  DatePicker,
  TimePicker,
  Autocomplete,
  Button,
  Icon,
  Textarea,
  Select,
} from "react-materialize";
import M from "materialize-css";
import { parseForm, strToNumber } from "../utils/service";
import { useFetching } from "../hooks/useFetching";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import { AuthContext } from "../context";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import useConfirm from "../context/ConfirmDialog";

const EditEvent = () => {
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const params = useParams();
  const confirm = useConfirm();
  const [eventData, setEventData] = useState(null);
  const [eventError, setEventError] = useState(null);
  const [locationOptions, setLocationOptions] = useState({ тест: null });
  const [cityTitle, setCityTitle] = useState("Город проведения");

  const [locationValue, setLocationValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [placeValue, setPlaceValue] = useState("");
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  const [addressInputState, setAddressInputState] = useState(0);
  const [locationInputState, setLocationInputState] = useState(0);
  const [interestOptions, setInterestOptions] = useState(null);
  const [interestIds, setInterestIds] = useState(null);
  const [timezonesOptions, setTimezonesOptions] = useState(null);
  const [timezoneValue, setTimezoneValue] = useState("0");

  const [ymapState, setYmapState] = useState({
    center: [0, 0], //[55.755864, 37.617698],
    zoom: 1,
    // controls: ["zoomControl"],
  });
  const [placemarks, setPlacemarks] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // fetchEvent();
    // setFullScreenLoading(true);
    // getAgeLimits();
    getEvent();
    setMap();
    getTimezones();
  }, []);

  useEffect(() => {
    if (
      locationValue &&
      addressValue &&
      // !latitudeValue &&
      // !longitudeValue &&
      addressInputState &&
      locationInputState
    ) {
      // console.log(document.activeElement.getAttribute("id"));
      // console.log("получаем геодата");
      setAddressInputState(0);
      setLocationInputState(0);
      setGeoData(locationValue + " " + addressValue);
      setMap();
    }
  }, [
    addressValue,
    locationValue,
    // latitudeValue,
    // longitudeValue,
    addressInputState,
    locationInputState,
  ]);

  const getEvent = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.getEvent(params.id)).data;
    // console.log(response);
    if (!response.response_code) {
      setEventError(response.message);
      return;
    }
    const pp = response.result.participation_packages.sort(
      (a, b) => a.price - b.price
    )[0];
    const minPrice = pp ? pp.price : null;
    let participantsCount = 0;

    if (response.result.participation_packages) {
      response.result.participation_packages.forEach((pack) => {
        participantsCount += pack.participants_count;
      });
    }

    response.result.participants_count = participantsCount;
    // console.log(response.result);
    setEventData(response.result);

    if (response.result.location) {
      setLocationValue(response.result.location);
    }
    if (response.result.address) {
      setAddressValue(response.result.address);
    }
    if (response.result.place) {
      setPlaceValue(response.result.place);
    }
    if (response.result.latitude && response.result.longitude) {
      setLatitudeValue(String(Number(response.result.latitude)));
      setLongitudeValue(String(Number(response.result.longitude)));
      setMap(response.result.latitude, response.result.longitude);
    }

    // if (response.result.age_limit_id) {
    //   setEventAgeLimit(String(response.result.age_limit_id));
    // }

    if (response.result.timezone) {
      setTimezoneValue(response.result.timezone);
    }

    if (response.result.interests) {
      let tmpInterests = [];
      for (let i = 0; i < response.result.interests.length; i++) {
        tmpInterests.push(response.result.interests[i].interest_id);
      }
      setInterestIds(tmpInterests);
    }

    // console.log(moment(response.result.datetime).format('hh:mm'));
    const interestsResponse = (await RequestService.getInterests()).data;
    if (!interestsResponse.response_code) {
      return;
    }
    // console.log(interestsResponse.result);
    let interestOptions = [];
    interestOptions.push(
      <option disabled value="" key="0">
        Выберите один или несколько
      </option>
    );
    interestsResponse.result.forEach((interest) => {
      interestOptions.push(
        <option value={interest.id} key={interest.id}>
          {interest.name}
        </option>
      );
    });
    setInterestOptions(interestOptions);
    setFullScreenLoading(false);
  };

  // const getAgeLimits = async () => {
  //   let ageLimitResponse = (await RequestService.getAgeLimits()).data;
  //   if (!ageLimitResponse.response_code) {
  //     console.error(ageLimitResponse);
  //     return;
  //   }
  //   let ageLimitsData = ageLimitResponse.result;
  //   // console.log(ageLimitsData);
  //   let limitOptions = [];
  //   ageLimitsData.forEach((limit) => {
  //     limitOptions.push(
  //       <option
  //         value={limit.id}
  //         key={limit.id + "-" + (Math.random() * 10000000).toFixed(0)}
  //       >
  //         {limit.age + "+"}
  //       </option>
  //     );
  //   });

  //   setAgeLimitOptions(limitOptions);

  //   await getEvent();
  //   setFullScreenLoading(false);
  // };

  const saveEventData = async (e) => {
    e.preventDefault();
    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    // console.log(htmlForm);

    let sendFormData = parseForm(htmlForm);
    for (let pair of sendFormData.entries()) {
      // console.log(pair);
    }
    const response = (
      await RequestService.sendForm(sendFormData, "/events/update")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    navigate(-1);
  };

  useEffect(() => {
    if (document.getElementById("event-location")) {
      let cityInputInstance = M.Autocomplete.getInstance(
        document.getElementById("event-location")
      );

      setTimeout(() => {
        cityInputInstance.open();
      }, 300);
    }
  }, [locationOptions]);

  const onInputCity = async (e) => {
    // console.log(e);
    setLocationValue(e.target.value);
    setCityTitle("Поиск...");
    const response = (await RequestService.searchLocation(e.target.value)).data;
    // console.log(response);
    setCityTitle("Город проведения");
    if (!response.response_code) {
      return;
    }

    let list = {};
    if (response.result.length > 0) {
      response.result.forEach((place) => {
        list[
          place.name_ru + " (" + place.region_ru + ", " + place.country_ru + ")"
        ] = null;
      });
    }
    setLocationOptions(list);
    // console.log(list);
  };

  const setGeoData = async (address) => {
    const response = (await RequestService.getGeoDataByAddress(address)).data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description:
          "Не удалось найти адрес на карте. Задайте координаты вручную",
        isAlert: true,
      });
      // alert("Не удалось найти адрес на карте. Задайте координаты вручную");
      return;
    }

    if (!response.result.lat || !response.result.lon) {
      await confirm({
        description:
          "Не удалось найти адрес на карте. Задайте координаты вручную",
        isAlert: true,
      });
      // alert("Не удалось найти адрес на карте. Задайте координаты вручную");
    }

    setLatitudeValue(String(Number(response.result.lat)));
    setLongitudeValue(String(Number(response.result.lon)));
    setMap(response.result.lat, response.result.lon);
  };

  const setMap = (latitude = null, longitude = null) => {
    let lat = "55.752004";
    let lon = "37.617734";
    if (latitudeValue) {
      lat = latitudeValue;
    }
    if (longitudeValue) {
      lon = longitudeValue;
    }
    if (latitude) {
      lat = latitude;
    }
    if (longitude) {
      lon = longitude;
    }

    // console.log(lat, lon);

    let newPlacemarks = [];
    newPlacemarks.push(
      <Placemark
        modules={["geoObject.addon.balloon"]}
        geometry={[lat, lon]}
        properties={{
          balloonContentBody: "Место проведения",
        }}
        options={{
          preset: "islands#dotIcon",
          iconColor: "#3d50fa",
        }}
        key={lat + "" + lon}
      />
    );

    setPlacemarks(newPlacemarks);
    setYmapState({
      ...ymapState,
      center: [lat, lon],
      zoom: 15,
    });
  };

  const setGeoInputStates = () => {
    setTimeout(() => {
      // console.log("inpu states");
      if (locationValue) {
        setLocationInputState(1);
      } else {
        setLocationInputState(0);
      }

      if (addressValue) {
        setAddressInputState(1);
      } else {
        setAddressInputState(0);
      }
    }, 100);
  };

  const getTimezones = async () => {
    let timezonesResponse = (await RequestService.getTimezones()).data;
    if (!timezonesResponse.response_code) {
      console.error(timezonesResponse);
      return;
    }
    let timezonesResponseData = timezonesResponse.result;
    // console.log(timezonesResponseData);
    let options = [];
    options.push(
      <option value={0} key={0}>
        Местный (по месту события)
      </option>
    );
    for (let key in timezonesResponseData) {
      options.push(
        <option
          value={timezonesResponseData[key]}
          key={timezonesResponseData[key]}
        >
          {key}
        </option>
      );
    }

    setTimezonesOptions(options);
  };

  // const [fetchEvent, isEventDataLoading, eventDataError] =
  //   useFetching(getEvent);

  return (
    // <div style={{ paddingLeft: "220px", paddingTop: "35px" }}>
    //   <div className="container">
    //     {eventError ? (
    //       <div
    //         className="row"
    //         style={{
    //           height: "100vh",
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //       >
    //         <div className="col s12 center-align">
    //           <div className="xl500">{eventError}</div>
    //         </div>
    //       </div>
    //     ) : eventData ? (
    //       <div className="row">
    //         <div className="col s12">
    //           <div className="card">
    //             <div className="card-content">
    //               <div className="row">
    //                 <div className="col s12">
    //                   <button
    //                     className="btn flat-btn iq-btn pastel-btn"
    //                     onClick={() => {
    //                       navigate(-1);
    //                     }}
    //                   >
    //                     <i className="material-icons-round left">
    //                       arrow_back_ios
    //                     </i>{" "}
    //                     К событию
    //                   </button>
    //                 </div>
    //               </div>
    <RegularPage
      error={eventError}
      isDataExist={eventData}
      header={"Редактирование события"}
      backText={"Назад"}
    >
      {eventData && eventData.interests ? (
        <form
          id="new-event"
          onSubmit={saveEventData}
          encType="multipart/form-data"
        >
          <div className="row">
            <TextInput
              id="event-name"
              name="event-name"
              icon="local_activity"
              s={12}
              label="Название события"
              placeholder="Выставка кошек"
              defaultValue={eventData.name ? eventData.name : null}
            />

            <div className="col s12">
              <div className="row">
                <div className="card ">
                  <div className="card-content">
                    <div className="row nm">
                      <div className="col s12 l500 mb20">
                        Обложка мероприятия
                      </div>
                      <div className="col l6 s12">
                        <div className="row mb10">
                          <div className="col s12">
                            <img
                              src={
                                eventData.cover_img_url
                                  ? eventData.cover_img_url
                                  : noImage
                              }
                              style={{
                                width: "100%",
                                maxHeight: "250px",
                                objectFit: "contain",
                              }}
                              className={
                                !eventData.cover_img_url
                                  ? "image-theme-filter"
                                  : ""
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        {eventData.cover_img_url_id ? (
                          <div className="row nm">
                            <div className="col s12 center-align">
                              <span
                                className="iq-link"
                                data-file-id={eventData.cover_img_url_id}
                                onClick={async (e) => {
                                  // console.log(e.currentTarget.getAttribute('data-file-id'));
                                  let response = (
                                    await RequestService.removeFromCloud(
                                      e.currentTarget.getAttribute(
                                        "data-file-id"
                                      )
                                    )
                                  ).data;
                                  // console.log(response);
                                  await getEvent();
                                }}
                              >
                                удалить обложку
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="file-field input-field col s12 l6 center-align">
                        <div className="btn iq-btn std btn-flat">
                          <span>Загрузить обложку события</span>
                          <input
                            type="file"
                            name="event-cover-img"
                            placeholder="Выберите изображение"
                          />
                        </div>
                        <div className="file-path-wrapper">
                          <input
                            className="file-path validate"
                            type="text"
                            placeholder="Выберите изображение"
                          />
                        </div>
                        <div>
                          Чтобы изменения вступили в силу, не забудьте нажать
                          внизу кнопку «Сохранить мероприятие»
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Autocomplete
              id="event-location"
              name="event-location"
              options={{
                data: locationOptions,
                onAutocomplete: (location) => {
                  setLocationValue(location);
                },
              }}
              title={cityTitle}
              placeholder={"Тула, Тульская область, Россия"}
              icon={<Icon>place</Icon>}
              s={12}
              l={6}
              onChange={onInputCity}
              value={locationValue}
              onBlur={() => {
                setGeoInputStates();
              }}
            />

            <TextInput
              id="event-address"
              name="event-address"
              icon={<Icon>apartment</Icon>}
              s={12}
              l={6}
              label="Адрес места"
              placeholder="ул. Ленина, 8"
              onChange={(e) => {
                setAddressValue(e.target.value);
              }}
              onBlur={() => {
                setGeoInputStates();
              }}
              value={addressValue}
              type="text"
            />

            <TextInput
              id="event-place"
              name="event-place"
              icon={<Icon>my_location</Icon>}
              s={12}
              label="Место проведения"
              defaultValue={placeValue}
              placeholder="3 этаж, зал «Меркурий»"
            />

            <div className="col s12 mb15">
              По вашему адресу ниже мы генерируем географические координаты для
              отображения места события на карте. Изменение города и адреса выше
              приведет к автоматическому изменению координат. Если координаты
              были определены неверно (неправильно ставится маркер на карте) или
              вообще не были определены, вам нужно уточнить их вручную. Найти
              координаты нужного адреса можно в{" "}
              <a
                href="https://yandex.ru/maps"
                className="iq-link"
                target="_blank"
                rel="noreferrer"
              >
                картографическом сервисе
              </a>
              .
            </div>

            <div className="col s12 l500">Проверьте место события на карте</div>

            <TextInput
              id="event-latitude"
              name="event-latitude"
              icon={<Icon>near_me</Icon>}
              l={6}
              s={12}
              label="Широта"
              placeholder="43.184775"
              inputMode="decimal"
              onChange={(e) => {
                let val = strToNumber(e.target.value);
                setLatitudeValue(val);
                setMap(val);
              }}
              value={latitudeValue}
            />

            <TextInput
              id="event-longitude"
              name="event-longitude"
              // icon={<Icon>near_me</Icon>}
              s={12}
              l={6}
              label="Долгота"
              placeholder="131.91689"
              inputMode="decimal"
              onChange={(e) => {
                let val = strToNumber(e.target.value);
                setLongitudeValue(val);
                setMap(null, val);
              }}
              value={longitudeValue}
            />

            <div className="col s12 mt15 mb20">
              {/* {ymapState.center[0] != 0 &&
                            ymapState.center[1] != 0 ? ( */}
              <YMaps>
                <Map
                  defaultState={{
                    center: [55.755864, 37.617698],
                    zoom: 12,
                    controls: ["zoomControl"],
                  }}
                  state={ymapState}
                  width={"100%"}
                  height={400}
                  modules={["control.ZoomControl"]}
                >
                  {placemarks}
                </Map>
              </YMaps>
              {/* ) : null} */}
            </div>

            <Select
              icon={<Icon>maps_home_work</Icon>}
              id="event-format"
              name="event-format"
              multiple={false}
              label={"Формат участия"}
              s={12}
              l={3}
              options={{
                classes: "",
                dropdownOptions: {
                  alignment: "left",
                  autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  coverTrigger: false,
                  hover: false,
                  inDuration: 150,
                  onCloseEnd: null,
                  onCloseStart: null,
                  onOpenEnd: null,
                  onOpenStart: null,
                  outDuration: 250,
                },
              }}
              defaultValue={eventData.format ? eventData.format : null}
            >
              <option value="1">Офлайн</option>
              <option value="2">Онлайн</option>
              <option value="3">Смешанный</option>
            </Select>

            <Select
              id="event-interests"
              name="event-interests"
              s={12}
              l={9}
              icon={<Icon>checklist</Icon>}
              label={"Сфера интересов"}
              multiple
              value={interestIds}
              options={{
                classes: "",
                dropdownOptions: {
                  alignment: "left",
                  // autoTrigger: true,
                  closeOnClick: true,
                  constrainWidth: true,
                  // coverTrigger: true,
                  // hover: false,
                  // inDuration: 150,
                  // onCloseEnd: null,
                  // onCloseStart: null,
                  // onOpenEnd: null,
                  // onOpenStart: null,
                  // outDuration: 250,
                },
              }}
              // value={[""]}
            >
              {interestOptions}
            </Select>

            <div className="col s12 mb15">
              Вы можете уточнить вручную часовой пояс вашего события. Обычно мы
              определяем его автоматически по координатам места проведения
              мероприятия, однако, у онлайн-событий нет конкретного места
              проведения на карте мира, поэтому ручная установка часового пояса
              полезна, как правило для событий, которые проводятся онлайн. Если
              вы проводите мероприятие офлайн, то устанавливать часовой пояс
              необязательно.
            </div>

            <Select
              id="event-timezone"
              name="event-timezone"
              s={12}
              l={12}
              icon={<Icon>language</Icon>}
              label={"Часовой пояс"}
              options={{
                classes: "",
                dropdownOptions: {
                  alignment: "left",
                  //   closeOnClick: true,
                  constrainWidth: true,
                },
              }}
              value={timezoneValue}
              onChange={(e) => {
                // console.log(e.target.value);
                setTimezoneValue(e.target.value);
              }}
            >
              {timezonesOptions}
            </Select>

            {/* <div className="file-field center-align col s12 input-field">
                          <div className="btn iq-btn btn-flat">
                            <i className="material-icons left accent-color">
                              upload
                            </i>
                            <span>Загрузить обложку события</span>
                            <input
                              id="form-button-upload-cover-img"
                              name="form-button-upload-cover-img"
                              type="file"
                              required=""
                            />
                          </div>
                          <div className="file-path-wrapper hide">
                            <input
                              className="file-path validate"
                              type="text"
                              placeholder="Прикрепите файл"
                            />
                          </div>
                        </div> */}

            <TextInput
              id="event-audience"
              name="event-audience"
              s={12}
              l={12}
              icon={<Icon>groups</Icon>}
              label="Для кого"
              placeholder="Для какой аудитории это мероприятие"
              defaultValue={eventData.audience ? eventData.audience : null}
            />

            {/* <div class="file-field input-field col s12">
                          <div class="btn iq-btn std btn-flat">
                            <span>Файл</span>
                            <input
                              type="file"
                              name="aq-question-img"
                              placeholder="Загрузите картинку для вопроса"
                            />
                          </div>
                          <div class="file-path-wrapper">
                            <input
                              class="file-path validate"
                              type="text"
                              placeholder="Загрузите картинку для вопроса"
                            />
                          </div>
                        </div> */}

            <Textarea
              id="event-description"
              name="event-description"
              label={"Подробное описание"}
              icon={<Icon>description</Icon>}
              placeholder={
                "Пришло время для ежегодной конференции по информационным технологиям..."
              }
              s={12}
              data-length={1000}
              style={{ marginBottom: "0px" }}
              defaultValue={
                eventData.description ? eventData.description : null
              }
            />

            <input
              type="hidden"
              name="event-id"
              id="event-id"
              value={params.id}
            />

            <div className="col s12 center-align mt20">
              <Button flat className="iq-btn" type="submit">
                Сохранить мероприятие
              </Button>
            </div>
          </div>
        </form>
      ) : null}
    </RegularPage>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     ) : (
    //       <div
    //         className="row"
    //         style={{
    //           height: "100vh",
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //       >
    //         <div className="col s12 center-align">
    //           <CircleLoader />
    //         </div>
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
};

export default EditEvent;
