import React from "react";
import { useNavigate } from "react-router-dom";
import noImage from "../../../images/no-image.png";
import { truncate } from "../../../utils/service";

const ProjectItem = ({ project, onSurface = false }) => {
  const navigate = useNavigate();
  // console.log(project);

  return (
    <div className="row nm">
      <div className="col s12">
        <div
          className={
            onSurface
              ? "card mb30 hand-cursor bg animation-appear"
              : "card mb30 hand-cursor animation-appear"
          }
          onClick={() => {
            navigate("/projects/" + project.id);
          }}
        >
          <div className="card-content">
            <div className="row nm">
              <div className="col s12">
                <img
                  src={project.cover_img_url ? project.cover_img_url : noImage}
                  className={
                    !project.cover_img_url ? "image-theme-filter" : null
                  }
                  alt="Обложка проекта"
                  style={{ width: "100%", borderRadius: "15px" }}
                />
              </div>
              <div className="col s12 l500 mt10">{project.name}</div>
              {project.interests.length > 0 ? (
                <div className="col s12 mt10">
                  {project.interests.map((interest) => (
                    <div className="chip" key={interest.name}>
                      {"#" + interest.name}
                    </div>
                  ))}
                </div>
              ) : null}
              {project.team_required ? (
                <div className="col s12 mt10">
                  <i
                    className="material-icons-round"
                    style={{ position: "absolute", marginTop: "-2px" }}
                  >
                    groups
                  </i>

                  <span style={{ marginLeft: "30px" }}>Собираем команду</span>
                </div>
              ) : null}
              {project.money_required ? (
                <div className="col s12 mt10">
                  <i
                    className="material-icons-round"
                    style={{ position: "absolute", marginTop: "-2px" }}
                  >
                    payment
                  </i>

                  <span style={{ marginLeft: "30px" }}>Ищем инвестиции</span>
                </div>
              ) : null}
              {project.description ? (
                <div className="col s12 mt10">
                  {truncate(project.description, 80)}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
