import React from "react";
import { useState, useRef, useEffect } from "react";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import BookmarkList from "../components/UI/Bookmarks/BookmarkList/BookmarkList";
import CircleLoader from "../components/UI/CircleLoader/CircleLoader";
import { useFetching } from "../hooks/useFetching";
import { useObserver } from "../hooks/useObserver";
import RequestService from "../api/RequestService";
import useConfirm from "../context/ConfirmDialog";

const Bookmarks = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarkPage, setBookmarkPage] = useState(1);
  const [bookmarksData, setBookmarksData] = useState(null);
  const lastBookmarkElement = useRef();
  const confirm = useConfirm();

  const getUserBookmarks = async (page = 1) => {
    if (bookmarksData && page > bookmarksData.total_pages) return;
    const response = (await RequestService.getBookmarks(page)).data;
    // console.log(response);
    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }
    setBookmarksData(response.result);
    setBookmarks([...bookmarks, ...response.result.elements]);
  };

  const [fetchBookmarks, isBookmarksLoading, bookmarksError] =
    useFetching(getUserBookmarks);

  useEffect(() => {
    // console.log(projectPage);
    fetchBookmarks(bookmarkPage);
  }, [bookmarkPage]);

  useObserver(
    lastBookmarkElement,
    bookmarksData && bookmarkPage < bookmarksData.total_pages,
    isBookmarksLoading,
    () => {
      setBookmarkPage(bookmarkPage + 1);
    }
  );

  return (
    <div>
      <RegularPage
        error={bookmarksError}
        isDataExist={bookmarksData}
        header={"Закладки"}
        isPrimaryPage={true}
        hasBaseCard={false}
      >
        <BookmarkList bookmarks={bookmarks} onSurface={false} />
        {isBookmarksLoading ? <CircleLoader /> : null}
      </RegularPage>
      <div ref={lastBookmarkElement}></div>
    </div>
  );
};

export default Bookmarks;
