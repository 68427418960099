import React from "react";
import { TextInput, Button } from "react-materialize";
import { useNavigate, useSearchParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import { parseForm } from "../utils/service";
import { AuthContext } from "../context";
import { useContext } from "react";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import useConfirm from "../context/ConfirmDialog";

const CreateEventProject = ({ mode = "event" }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  const createEventDraft = async (e) => {
    e.preventDefault();
    // console.log(e.target['event-name'].value);
    setFullScreenLoading(true);
    let response = (
      await RequestService.createEvent(
        e.target["event-name"].value,
        new URLSearchParams(
          Object.fromEntries(searchParams.entries())
        ).toString()
      )
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    navigate("/events/" + response.result);
  };

  const createProjectDraft = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    let sendFormData = parseForm(htmlForm);
    // for (let pair of sendFormData.entries()) {
    //   console.log(pair);
    // }
    const response = (
      await RequestService.sendForm(sendFormData, "/projects/create")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    navigate("/projects/" + response.result);
  };

  return (
    <RegularPage
      header={"Создание " + (mode === "project" ? "проекта" : "события")}
      backText={"Назад"}
      isDataExist={1}
      isPrimaryPage={true}
    >
      <div className="row">
        <div className="col s12 secondary-color">
          Создание {mode === "project" ? "проекта" : "события"} начинается с
          черновика — мы поместим его в ваш профиль и пока не будем публиковать.
          Для начала просто придумайте название.
        </div>
      </div>
      <form
        id="new-event"
        onSubmit={mode === "project" ? createProjectDraft : createEventDraft}
      >
        <div className="row">
          <TextInput
            id="event-name"
            name="object-name"
            icon="local_activity"
            s={12}
            label={"Название " + (mode === "project" ? "проекта" : "события")}
            placeholder={
              mode === "project" ? "Цифровой ассистент" : "Выставка кошек"
            }
            required
          />
          <div className="col s12 center-align mt20">
            <Button flat className="iq-btn" type="submit">
              Создать черновик
            </Button>
          </div>
        </div>
      </form>
    </RegularPage>
  );
};

export default CreateEventProject;
