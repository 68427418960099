import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import { privateRoutes, publicRoutes } from "../router";
import { AuthContext } from "../context";
import CircleLoader from "./UI/CircleLoader/CircleLoader";
import Navbar from "./UI/Navbar/Navbar";
import ErrorMessage from "./UI/ErrorMessage/ErrorMessage";
import Event from "../pages/Event";

const AppRouter = () => {
  const { isAuth, isLoading, globalError } = useContext(AuthContext);

  if (isLoading) {
    // console.info('Работает индикатор загрузки');
    return <CircleLoader status={isLoading} />;
  }

  if (globalError) {
    return <ErrorMessage error={globalError} />
  }

  // console.log(isAuth, isLoading);

  return isAuth ? (
    <Routes>
      {privateRoutes.map((route) => (
        <Route element={route.element} path={route.path} key={route.path} />
      ))}
      <Route path="/*" element={<Navigate to="/events" replace />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map((route) => (
        <Route element={route.element} path={route.path} key={route.path} />
      ))}
      <Route path="/*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AppRouter;
