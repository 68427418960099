import React from "react";
import SectionButton from "../components/UI/Button/SectionButton/SectionButton";
import RegularPage from "../components/UI/RegularPage/RegularPage";

const Settings = () => {
  return (
    <RegularPage
      isDataExist={1}
      hasBaseCard={true}
      header={"Настройки"}
      isPrimaryPage={false}
      backText={"Назад"}
      hasAppearanceAnimation={true}
    >
      <div className="row nm mt25">
        <div className="col s12 l6 mb25">
          <SectionButton
            iconName={"security"}
            text={"Конфиденциальность"}
            pathTo={"/settings/privacy"}
          />
        </div>
        <div className="col s12 l6 mb25">
          <SectionButton
            iconName={"lock"}
            text={"Безопасность"}
            pathTo={"/settings/security"}
          />
        </div>
        <div className="col s12 l6 mb25">
          <SectionButton
            iconName={"notifications"}
            text={"Уведомления"}
            pathTo={"/settings/notifications"}
          />
        </div>
        <div className="col s12 l6 mb25">
          <SectionButton
            iconName={"verified"}
            text={"Верификация"}
            pathTo={"/settings/verification"}
          />
        </div>
        <div className="col s12 l6 mb25">
          <SectionButton
            iconName={"design_services"}
            text={"Скоро появится..."}
          />
        </div>
      </div>
    </RegularPage>
  );
};

export default Settings;
