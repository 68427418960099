import { useSearchParams } from "react-router-dom";

export const useSearchParamsArray = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = {
        object: {},
        array: []
    };
    
    for (let entry of searchParams.entries()) {
        params.array.push(entry);
        params.object[entry[0]] = entry[1];
    }

    return params;
}