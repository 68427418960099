import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import { AuthContext } from "../context";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import useConfirm from "../context/ConfirmDialog";
import UserSmallCardList from "../components/UI/Users/UserSmallCard/UserSmallCardList";

const MeetingParticipants = () => {
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [participantsData, setParticipantsData] = useState(null);

  const params = useParams();

  const navigate = useNavigate();
  const confirm = useConfirm();

  useEffect(() => {
    getMeetingParticipants();
  }, []);

  const getMeetingParticipants = async () => {
    let response = (await RequestService.getMeetingParticipants(params.id))
      .data;
    // console.log(response.result);
    setParticipantsData(response);

    if (!response.response_code) {
      console.error(response);
      return;
    }
  };

  return !isFullScreenLoading ? (
    <RegularPage
      isDataExist={participantsData}
      error={participantsData ? participantsData.message : null}
      backText={"Назад"}
      header={"Участники встречи"}
    >
      {participantsData ? (
        <div className="mt25">
          <UserSmallCardList users={participantsData.result} isPastel={true} />
        </div>
      ) : null}
    </RegularPage>
  ) : null;
};

export default MeetingParticipants;
