import React from "react";

const PackageOption = ({ option, marginBottom = 15 }) => {
  return (
    <div className="row nm" style={{ marginBottom: marginBottom + "px" }}>
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="row nm">
              <div className="col s12">
                <i
                  className={option.is_available == 1 ? "material-icons success-color" : "material-icons error-color"}
                  style={{ position: "absolute", marginTop: "-2px" }}
                >
                  {option.is_available == 1 ? "check_circle" : "cancel" }
                </i>
                <span className="m500" style={{ marginLeft: "35px" }}>
                  {option.name}
                </span>
              </div>
            </div>
            {option.description ? (
              <div className="row nm mt15">
                <div className="col s12">{option.description}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageOption;
