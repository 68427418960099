import React, { useEffect, useState } from "react";

const Alert = ({
  isOpen = false,
  onClose,
  onConfirm,
  title = "Вы уверены?",
  description = "Описание",
  confirmBtnLabel = "Да",
  isAlert = false,
}) => {
  if (isAlert) {
    title = "Уведомление";
    confirmBtnLabel = "Ок";
  }

  return (
    <div>
      <div
        className="confirm-container"
        style={{ display: isOpen ? "flex" : "none" }}
        onClick={onClose}
      ></div>
      <div
        className="confirm-dialog"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        {title ? <div className="l500 mb25">{title}</div> : null}
        <div className="secondary-color">{description}</div>
        <div className="mt40">
          <button className="btn iq-btn right" onClick={onConfirm}>
            {confirmBtnLabel}
          </button>
          {!isAlert ? (
            <button
              className="btn iq-btn pastel-btn right"
              style={{ marginRight: "10px" }}
              onClick={onClose}
            >
              Отмена
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Alert;
