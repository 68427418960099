import React from "react";
import { Button, Modal } from "react-materialize";

const SquareIconButton = ({ iconName, title, ...props }) => {
  let modalId = "modal-" + (Math.random() * 100000000000).toFixed(0);
  if (props.modal) {
    let obj = {
      ...props.attributes,
      href: "#" + modalId,
    };
    props.attributes = obj;
    props.classes += " modal-trigger";
  }
  return (
    <span
      {...props.attributes}
      className={
        "pastel-btn sq-btn center-align " + (props.classes ? props.classes : "")
      }
      style={
        props.disabled
          ? {
              marginRight: props.marginRL ? props.marginRL + "px" : "0px",
              marginLeft: props.marginRL ? props.marginRL + "px" : "0px",
              marginTop: "7px",
              marginBottom: "7px",
              color: "var(--disabled-bg-color)",
            }
          : !props.panelColor
          ? {
              marginRight: props.marginRL ? props.marginRL + "px" : "0px",
              marginLeft: props.marginRL ? props.marginRL + "px" : "0px",
              marginTop: "7px",
              marginBottom: "7px",
            }
          : {
              marginRight: props.marginRL ? props.marginRL + "px" : "0px",
              marginLeft: props.marginRL ? props.marginRL + "px" : "0px",
              backgroundColor: "var(--surface-bg-color)",
              marginTop: "7px",
              marginBottom: "7px",
            }
      }
      onClick={props.onClick}
    >
      <div>
        <i
          className="material-icons-round"
          style={{
            fontSize: "35px",
            verticalAlign: "middle",
          }}
        >
          {iconName}
        </i>
      </div>
      <div style={{ fontSize: "13px" }}>{title}</div>
      {props.modal ? (
        <Modal
          actions={[
            <Button flat modal="close" node="button" className="iq-btn">
              Закрыть
            </Button>,
          ]}
          bottomSheet={false}
          fixedFooter
          header={props.modal.title}
          id={modalId}
          open={false}
          options={{
            dismissible: true,
            endingTop: "10%",
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            opacity: 0.5,
            outDuration: 250,
            preventScrolling: true,
            startingTop: "4%",
          }}
        >
          {props.modal.content}
        </Modal>
      ) : null}
    </span>
  );
};

export default SquareIconButton;
