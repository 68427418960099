import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { TextInput, Textarea, Icon, Button, Select } from "react-materialize";
import RequestService from "../api/RequestService";
import noImage from "../images/no-image.png";
import { AuthContext } from "../context";
import { parseForm, strToNumber } from "../utils/service";
import useConfirm from "../context/ConfirmDialog";

const EditProject = () => {
  const [projectError, setProjectError] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [interestOptions, setInterestOptions] = useState(null);
  const [interestIds, setInterestIds] = useState(null);
  const [projectCurrency, setProjectCurrency] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState(null);
  const confirm = useConfirm();

  useEffect(() => {
    // setFullScreenLoading(true);
    // getProject();
    getCurrencies();
  }, []);

  const saveProjectData = async (e) => {
    e.preventDefault();
    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    let sendFormData = parseForm(htmlForm);
    const response = (
      await RequestService.sendForm(sendFormData, "/projects/update")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    navigate("/projects/" + params.id);
  };

  const getProject = async () => {
    const response = (await RequestService.getProject(params.id)).data;
    // console.log(response);
    if (!response.response_code) {
      setProjectError(response.message);
      return;
    }

    if (response.result.interests) {
      let tmpInterests = [];
      for (let i = 0; i < response.result.interests.length; i++) {
        tmpInterests.push(response.result.interests[i].interest_id);
      }
      setInterestIds(tmpInterests);
    }

    // console.log(moment(response.result.datetime).format('hh:mm'));
    const interestsResponse = (await RequestService.getInterests()).data;
    if (!interestsResponse.response_code) {
      return;
    }
    // console.log(interestsResponse.result);
    let interestOptions = [];
    interestOptions.push(
      <option disabled value="" key="0">
        Выберите один или несколько
      </option>
    );
    interestsResponse.result.forEach((interest) => {
      interestOptions.push(
        <option value={interest.id} key={interest.id}>
          {interest.name}
        </option>
      );
    });
    setInterestOptions(interestOptions);

    setProjectCurrency(String(response.result.currency_id));

    setProjectData(response.result);
  };

  const getCurrencies = async () => {
    let response = (await RequestService.getCurrencies()).data;
    if (!response.response_code) {
      console.error(response);
      return;
    }
    let currenciesData = response.result;
    // console.log(currenciesData);
    // setEvents(allEventsData);
    let currencyOptions = [];
    currenciesData.forEach((currency) => {
      currencyOptions.push(
        <option value={currency.id} key={currency.id}>
          {currency.symbol + " (" + currency.code + ")" + " " + currency.name}
        </option>
      );
    });

    setCurrencyOptions(currencyOptions);

    await getProject();
    // setFullScreenLoading(false);
  };

  return (
    <RegularPage
      error={projectError}
      isDataExist={projectData}
      header={"Редактирование проекта"}
      backText={"Назад"}
    >
      <div className="row">
        <div className="col s12">
          <form onSubmit={saveProjectData} encType="multipart/form-data">
            <div className="row nm">
              <TextInput
                id="project-name"
                name="project-name"
                icon="local_activity"
                s={12}
                label="Название проекта"
                placeholder="Кофейня для собак"
                defaultValue={
                  projectData && projectData.name ? projectData.name : null
                }
              />

              <div className="col s12">
                <div className="row nm">
                  <div className="card ">
                    <div className="card-content">
                      <div className="row nm">
                        <div className="col s12 l500 mb20">Обложка проекта</div>
                        <div className="col l6 s12">
                          <div className="row mb10">
                            <div className="col s12">
                              <img
                                src={
                                  projectData && projectData.cover_img_url
                                    ? projectData.cover_img_url
                                    : noImage
                                }
                                style={{
                                  width: "100%",
                                  maxHeight: "250px",
                                  objectFit: "contain",
                                }}
                                className={
                                  projectData && !projectData.cover_img_url
                                    ? "image-theme-filter"
                                    : ""
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          {projectData && projectData.cover_img_url_id ? (
                            <div className="row nm">
                              <div className="col s12 center-align">
                                <span
                                  className="iq-link"
                                  data-file-id={projectData.cover_img_url_id}
                                  onClick={async (e) => {
                                    // console.log(e.currentTarget.getAttribute('data-file-id'));
                                    let response = (
                                      await RequestService.removeFromCloud(
                                        e.currentTarget.getAttribute(
                                          "data-file-id"
                                        )
                                      )
                                    ).data;
                                    // console.log(response);
                                    getProject();
                                  }}
                                >
                                  удалить обложку
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="file-field input-field col s12 l6 center-align">
                          <div className="btn iq-btn std btn-flat">
                            <span>Загрузить обложку проекта</span>
                            <input
                              type="file"
                              name="project-cover-img"
                              placeholder="Выберите изображение"
                            />
                          </div>
                          <div className="file-path-wrapper">
                            <input
                              className="file-path validate"
                              type="text"
                              placeholder="Выберите изображение"
                            />
                          </div>
                          <div>
                            Чтобы изменения вступили в силу, не забудьте нажать
                            внизу кнопку «Сохранить проект»
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col s12 l500">Подробности проекта</div>

              <Select
                id="project-interests"
                name="project-interests"
                s={12}
                icon={<Icon>checklist</Icon>}
                label={"Сфера интересов"}
                multiple
                value={interestIds}
                options={{
                  classes: "",
                  dropdownOptions: {
                    alignment: "left",
                    closeOnClick: true,
                    constrainWidth: true,
                  },
                }}
              >
                {interestOptions}
              </Select>

              <Textarea
                id="project-description"
                name="project-description"
                label={"Подробное описание"}
                icon={<Icon>description</Icon>}
                placeholder={
                  "Подробно опишите, в чем заключается суть вашего проекта"
                }
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.description
                    ? projectData.description
                    : null
                }
              />

              <Textarea
                id="project-audience"
                name="project-audience"
                label={"Целевая аудитория"}
                icon={<Icon>groups</Icon>}
                placeholder={"Опишите целевую аудиторию"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.audience
                    ? projectData.audience
                    : null
                }
              />

              <Textarea
                id="project-uniqueness"
                name="project-uniqueness"
                label={"Уникальность продукта"}
                icon={<Icon>auto_awesome</Icon>}
                placeholder={
                  "По нашей технологии собаки начинают разговаривать"
                }
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.uniqueness
                    ? projectData.uniqueness
                    : null
                }
              />

              <Textarea
                id="project-market-volume"
                name="project-market-volume"
                label={"Емкость рынка"}
                icon={<Icon>store</Icon>}
                placeholder={"Опишите емкость рынка"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.market_volume
                    ? projectData.market_volume
                    : null
                }
              />

              <Textarea
                id="project-scalability"
                name="project-scalability"
                label={"Масштабируемость"}
                icon={<Icon>zoom_out_map</Icon>}
                placeholder={"Опишите потенциал масштабируемости"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.scalability
                    ? projectData.scalability
                    : null
                }
              />

              <Textarea
                id="project-breakeven-point"
                name="project-breakeven-point"
                label={"Точка безубыточности"}
                icon={<Icon>multiline_chart</Icon>}
                placeholder={"При каком объеме продаж прибыль будет нулевая?"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.breakeven_point
                    ? projectData.breakeven_point
                    : null
                }
              />

              <Textarea
                id="project-profitability"
                name="project-profitability"
                label={"Доходность"}
                icon={<Icon>payment</Icon>}
                placeholder={"Какова потенциальная доходность?"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.profitability
                    ? projectData.profitability
                    : null
                }
              />

              <Textarea
                id="project-stages"
                name="project-stages"
                label={"Этапы и сроки"}
                icon={<Icon>123</Icon>}
                placeholder={"Расскажите об этапах реализации"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.stages ? projectData.stages : null
                }
              />

              <Textarea
                id="project-state"
                name="project-state"
                label={"Текущее состояние"}
                icon={<Icon>view_timeline</Icon>}
                placeholder={"Что уже есть?"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.state ? projectData.state : null
                }
              />

              <Textarea
                id="project-team"
                name="project-team"
                label={"Текущая команда"}
                icon={<Icon>group</Icon>}
                placeholder={"Расскажите о текущей команде проекта"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.team ? projectData.team : null
                }
              />

              <Textarea
                id="project-team-required"
                name="project-team-required"
                label={"Кто нужен в команду?"}
                icon={<Icon>person_search</Icon>}
                placeholder={"Инвестор, программист и т.д."}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.team_required
                    ? projectData.team_required
                    : null
                }
              />

              <Textarea
                id="project-money-required"
                name="project-money-required"
                label={"Нужны инвестиции?"}
                icon={<Icon>person_search</Icon>}
                placeholder={"Укажите сумму"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.money_required
                    ? String(Number(projectData.money_required))
                    : null
                }
              />

              <Select
                id="project-currency"
                name="project-currency"
                s={12}
                icon={<Icon>currency_bitcoin</Icon>}
                label={"Валюта инвестиций"}
                options={{
                  classes: "",
                  dropdownOptions: {
                    alignment: "left",
                    constrainWidth: true,
                  },
                }}
                value={projectCurrency}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setProjectCurrency(e.target.value);
                }}
              >
                {currencyOptions}
              </Select>

              <Textarea
                id="project-risks"
                name="project-risks"
                label={"Риски проекта"}
                icon={<Icon>warning_amber</Icon>}
                placeholder={"Какие есть риски у проекта?"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.risks ? projectData.risks : null
                }
              />

              <Textarea
                id="project-additionally"
                name="project-additionally"
                label={"Дополнительно"}
                icon={<Icon>info_outlined</Icon>}
                placeholder={"Расскажите то, что хочется добавить"}
                s={12}
                style={{ marginBottom: "0px" }}
                defaultValue={
                  projectData && projectData.additionally
                    ? projectData.additionally
                    : null
                }
              />

              <input
                type="hidden"
                name="project-id"
                id="project-id"
                value={params.id}
              />

              <div className="col s12 center-align mt20">
                <Button flat className="iq-btn" type="submit">
                  Сохранить проект
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </RegularPage>
  );
};

export default EditProject;
