import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import RequestService from "../api/RequestService";
import { useContext } from "react";
import { AuthContext } from "../context";
import noAvatar from "../images/no-avatar.png";
import moment from "moment";
import { Modal, Button, Icon, Carousel } from "react-materialize";
import NewComplaint from "../components/UI/Complaints/NewComplaint/NewComplaint";
import {
  copyTextToClipboard,
  textWithLinks,
  youtubeVideoIdParser,
} from "../utils/service";
import FullScreenImage from "../components/UI/Image/FullScreenImage";

const Post = () => {
  const params = useParams();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const [postData, setPostData] = useState(null);
  const [postError, setPostError] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState(null);
  const [openedImgUrl, setOpenedImgUrl] = useState(null);

  useEffect(() => {
    getPost();
  }, []);

  const getPost = async () => {
    setFullScreenLoading(true);
    const response = (await RequestService.getPost(params.id)).data;
    setFullScreenLoading(false);
    // console.log(response);
    if (!response.response_code) {
      setPostError(response.message);
      return;
    }

    let ytLink = response.result.text
      .split(" ")
      .filter(
        (word) =>
          word.includes("https://www.youtube.com/watch") ||
          word.includes("https://youtu.be")
      )[0];
    // console.log(ytLink);
    setYoutubeLink(ytLink);

    response.result.text = textWithLinks(response.result.text);

    setPostData(response.result);
  };

  return (
    <RegularPage
      error={postError}
      isDataExist={postData}
      header={"Публикация"}
      isPrimaryPage={true}
      hasBaseCard={false}
      // backText={"Все публикации"}
      // backPath={"/posts"}
    >
      {postData ? (
        <div>
          <div className="row">
            <div className="col s12">
              <div className="card mb30 animation-appear">
                <div className="card-content">
                  <div className="row nm">
                    <Link to={"/users/" + postData.alias}>
                      <div className="col s3 l1">
                        <div
                          className="circular-image pastel-shadow"
                          style={{
                            width: "45px",
                            height: "45px",
                          }}
                        >
                          <div
                            className={
                              postData.avatar_img_url
                                ? "circular-image"
                                : "circular-image image-theme-filter"
                            }
                            style={{
                              backgroundImage:
                                "url(" +
                                (postData.avatar_img_url
                                  ? postData.avatar_img_url
                                  : noAvatar) +
                                ")",
                              height: "45px",
                              width: "45px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </Link>
                    <div className="col s9 l11">
                      <Link to={"/users/" + postData.alias}>
                        <div className="row nm">
                          <div className="col s12 m500">{postData.name}</div>
                          <div className="col s12 s300 mt5 tertiary-color">
                            {moment(postData.timestamp).format(
                              "DD.MM.YYYY HH:mm"
                            ) + " (МСК)"}
                          </div>
                        </div>
                      </Link>
                    </div>
                    {postData.edited_at ? (
                      <div
                        className="col s12 mt15 s300"
                        style={{ fontStyle: "italic" }}
                      >
                        {"изменено " +
                          moment(postData.edited_at).format(
                            "DD.MM.YYYY HH:mm"
                          ) +
                          " (МСК)"}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col s12 secondary-color mt15">
                      {postData.text}
                    </div>

                    {youtubeLink ? (
                      <div className="col s12 mt25">
                        <div style={{ width: "100%", aspectRatio: "16 / 9" }}>
                          <iframe
                            src={
                              "https://www.youtube.com/embed/" +
                              youtubeVideoIdParser(youtubeLink)
                            }
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          ></iframe>
                        </div>
                      </div>
                    ) : null}

                    {postData.files.length > 0 ? (
                      <div className="col s12">
                        <div className="mt20">
                          <Carousel
                            carouselId={"carousel-1"}
                            className="white-text center"
                            options={{
                              fullWidth: true,
                              indicators: true,
                              noWrap: true,
                            }}
                          >
                            {postData.files.map((f) => (
                              <div key={f}>
                                <div
                                  className="carousel-img-bg"
                                  style={{
                                    backgroundImage: "url(" + f + ")",
                                  }}
                                ></div>
                                <img
                                  src={f}
                                  alt=""
                                  style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={() => {
                                    setOpenedImgUrl(f);
                                  }}
                                />
                              </div>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    ) : null}

                    <div className="col s12 right-align mt35">
                      <Button
                        className="btn btn-flat pastel-btn"
                        onClick={() => {
                          copyTextToClipboard(
                            window.location.origin + "/posts/" + postData.id,
                            "Ссылка на запись скопирована!"
                          );
                        }}
                      >
                        <i className="material-icons-round">ios_share</i>
                      </Button>
                      <Button
                        className="btn btn-flat pastel-btn modal-trigger"
                        style={{ marginLeft: "10px" }}
                        href="#modal-post-complaint"
                      >
                        <i className="material-icons-round">thumb_down</i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            actions={[
              <Button flat modal="close" node="button" className="iq-btn">
                Закрыть
              </Button>,
            ]}
            bottomSheet={false}
            fixedFooter
            header={"Пожаловаться"}
            id="modal-post-complaint"
            open={false}
            options={{
              dismissible: true,
              endingTop: "10%",
              inDuration: 250,
              onCloseEnd: null,
              onCloseStart: null,
              onOpenEnd: null,
              onOpenStart: null,
              opacity: 0.5,
              outDuration: 250,
              preventScrolling: true,
              startingTop: "4%",
            }}
          >
            <NewComplaint targetObject={params.id} />
          </Modal>

          <FullScreenImage
            imgUrl={openedImgUrl}
            closeImgFn={() => setOpenedImgUrl(null)}
          />
        </div>
      ) : null}
    </RegularPage>
  );
};

export default Post;
