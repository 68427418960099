import React from "react";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { Link } from "react-router-dom";

const Help = () => {
  function SvgComponent(props) {
    return (
      <svg
        className="animated"
        id="freepik_stories-to-do-list"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        {...props}
      >
        <style>
          {
            "@keyframes zoomOut{0%{opacity:0;transform:scale(1.5)}to{opacity:1;transform:scale(1)}}@keyframes zoomIn{0%{opacity:0;transform:scale(.5)}to{opacity:1;transform:scale(1)}}@keyframes slideRight{0%{opacity:0;transform:translateX(30px)}to{opacity:1;transform:translateX(0)}}@keyframes slideLeft{0%{opacity:0;transform:translateX(-30px)}to{opacity:1;transform:translateX(0)}}svg#freepik_stories-to-do-list:not(.animated) .animable{opacity:0}"
          }
        </style>
        <g
          id="freepik--background-simple--inject-75"
          style={{
            transformOrigin: "251.315117px 205.23307px",
            animation: "1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomOut",
            animationDelay: "0s",
          }}
          className="animable"
        >
          <path
            d="M243.6 327.37C159.16 315 63.76 275.8 73.8 179.67 82.92 92.45 181.32 48 258.87 101.28c33.44 23 39.58 63.31 77.5 81.69s93 19.87 93.19 75.25c.24 85.78-130.39 77.29-185.96 69.15z"
            style={{
              transformOrigin: "251.315117px 205.23307px",
            }}
            id="elclgobmzkxtl"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M243.6 327.37C159.16 315 63.76 275.8 73.8 179.67 82.92 92.45 181.32 48 258.87 101.28c33.44 23 39.58 63.31 77.5 81.69s93 19.87 93.19 75.25c.24 85.78-130.39 77.29-185.96 69.15z"
            style={{
              transformOrigin: "251.315117px 205.23307px",
            }}
            className="animable"
            fill="#fff"
            opacity={0.9}
            id="elk08q7ovp13r"
          />
        </g>
        <g
          id="freepik--Shadow--inject-75"
          style={{
            transformOrigin: "250px 416.239983px",
            animation: "1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn",
            animationDelay: "0s",
          }}
          className="animable"
        >
          <ellipse
            id="freepik--path--inject-75"
            cx={250}
            cy={416.24}
            rx={193.89}
            ry={11.32}
            style={{
              transformOrigin: "250px 416.24px",
            }}
            className="animable"
            fill="#f5f5f5"
          />
        </g>
        <g
          id="freepik--to-do-list--inject-75"
          style={{
            transformOrigin: "312.736847px 238.998993px",
            animation:
              "1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight",
            animationDelay: "0s",
          }}
          className="animable"
        >
          <path
            d="M239.41 129.51h164.65a5.94 5.94 0 016 6.38l-13.43 216.33a6.91 6.91 0 01-6.78 6.39H225.19a6 6 0 01-6-6.39l13.42-216.33a6.92 6.92 0 016.8-6.38z"
            style={{
              transformOrigin: "314.626854px 244.059761px",
            }}
            id="elnd0ulybmq"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M239.41 129.51h164.65a5.94 5.94 0 016 6.38l-13.43 216.33a6.91 6.91 0 01-6.78 6.39H225.19a6 6 0 01-6-6.39l13.42-216.33a6.92 6.92 0 016.8-6.38z"
            style={{
              transformOrigin: "314.626854px 244.059761px",
            }}
            className="animable"
            opacity={0.4}
            id="el77jq03tw8ox"
          />
          <path
            d="M235.63 129.51h164.65a5.94 5.94 0 016 6.38l-13.43 216.33a6.91 6.91 0 01-6.79 6.39H221.41a6 6 0 01-6-6.39l13.42-216.33a6.91 6.91 0 016.8-6.38z"
            style={{
              transformOrigin: "310.846832px 244.059761px",
            }}
            id="elteuhpwlhvwe"
            className="animable"
            fill="#3d50fa"
          />
          <path
            style={{
              transformOrigin: "310.974991px 244.090004px",
            }}
            className="animable"
            opacity={0.4}
            id="elb612wimho68"
            d="M224.34 346.93L384.61 348.85 397.61 139.33 237.22 139.33 224.34 346.93z"
          />
          <path
            d="M219.66 344.81H382c8.94-81.91 11.69-126.13 12.91-208H232.57c-1.22 81.87-3.96 126.09-12.91 208z"
            style={{
              transformOrigin: "307.285004px 240.809998px",
            }}
            id="eld56bt20yj0f"
            className="animable"
            fill="#fff"
          />
          <path
            d="M375.49 193.75h-27.95a2 2 0 01-1.5-.65 2.08 2.08 0 01-.56-1.54l1.73-27.94a2.07 2.07 0 012.06-1.94h28a2.07 2.07 0 012.06 2.19l-1.74 27.95a2 2 0 01-2.1 1.93zm-25.75-4.12h23.81l1.45-23.82h-23.79z"
            style={{
              transformOrigin: "362.405106px 177.716286px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elarai7qlgefv"
          />
          <path
            d="M372.84 236.32H344.9a2.07 2.07 0 01-1.5-.65 2.05 2.05 0 01-.56-1.54l1.73-27.95a2.06 2.06 0 012.06-1.93h27.95a2 2 0 011.5.65 2.08 2.08 0 01.56 1.54l-1.74 27.94a2.06 2.06 0 01-2.06 1.94zm-25.75-4.13h23.81l1.48-23.82h-23.81z"
            style={{
              transformOrigin: "359.739639px 220.284943px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="ellkbuh286c5d"
          />
          <path
            d="M370.2 278.88h-27.94a2.06 2.06 0 01-1.51-.65 2 2 0 01-.55-1.54l1.73-27.94a2.07 2.07 0 012.06-1.94h27.95a2.07 2.07 0 011.5.65A2.11 2.11 0 01374 249l-1.74 28a2.06 2.06 0 01-2.06 1.88zm-25.75-4.12h23.81l1.48-23.82h-23.81z"
            style={{
              transformOrigin: "357.098267px 262.845001px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elvhckiuwmlra"
          />
          <path
            d="M367.56 321.45h-27.94a2.1 2.1 0 01-1.51-.65 2 2 0 01-.55-1.54l1.73-27.95a2.07 2.07 0 012.06-1.93h27.95a2 2 0 011.5.65 2.08 2.08 0 01.56 1.54l-1.74 27.94a2.07 2.07 0 01-2.06 1.94zm-25.75-4.13h23.81l1.48-23.82h-23.81z"
            style={{
              transformOrigin: "354.458572px 305.414948px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elkhx7hontq0p"
          />
          <path
            d="M259.88 174.06h63.29a3.78 3.78 0 003.71-3.49 3.25 3.25 0 00-3.28-3.49h-63.28a3.76 3.76 0 00-3.71 3.49 3.25 3.25 0 003.27 3.49z"
            style={{
              transformOrigin: "291.745026px 170.569946px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elzsd11jd0hlp"
          />
          <path
            d="M271 188.36h51.25a3.78 3.78 0 003.71-3.49 3.25 3.25 0 00-3.27-3.5h-51.23a3.78 3.78 0 00-3.71 3.5 3.25 3.25 0 003.25 3.49z"
            style={{
              transformOrigin: "296.855377px 184.864937px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elotdpgrba5r"
          />
          <path
            d="M256.79 223.94h63.29a3.25 3.25 0 013.27 3.49 3.78 3.78 0 01-3.71 3.49h-63.29a3.25 3.25 0 01-3.27-3.49 3.78 3.78 0 013.71-3.49z"
            style={{
              transformOrigin: "288.215027px 227.430023px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elu7qeut7gk2"
          />
          <path
            d="M269.71 209.64H321a3.25 3.25 0 013.28 3.49 3.78 3.78 0 01-3.71 3.5h-51.29a3.25 3.25 0 01-3.28-3.5 3.78 3.78 0 013.71-3.49z"
            style={{
              transformOrigin: "295.139618px 213.135002px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elcu2fjj0euvm"
          />
          <path
            d="M254.6 259.19h63.29a3.78 3.78 0 003.71-3.49 3.25 3.25 0 00-3.28-3.49H255a3.78 3.78 0 00-3.71 3.49 3.25 3.25 0 003.31 3.49z"
            style={{
              transformOrigin: "286.444977px 255.700264px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="el6vhbotyxjwk"
          />
          <path
            d="M291.54 273.49H317a3.78 3.78 0 003.71-3.49 3.26 3.26 0 00-3.28-3.5H292a3.79 3.79 0 00-3.71 3.5 3.25 3.25 0 003.25 3.49z"
            style={{
              transformOrigin: "304.499985px 269.994949px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elxnj5bdiweud"
          />
          <path
            d="M265.75 273.49h13.43a3.78 3.78 0 003.71-3.49 3.26 3.26 0 00-3.28-3.5h-13.43a3.79 3.79 0 00-3.71 3.5 3.25 3.25 0 003.28 3.49z"
            style={{
              transformOrigin: "272.679993px 269.995071px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elwys3iv96sug"
          />
          <path
            d="M252 301.76h63.29a3.78 3.78 0 003.71-3.49 3.26 3.26 0 00-3.28-3.5h-63.33a3.78 3.78 0 00-3.71 3.5 3.25 3.25 0 003.32 3.49z"
            style={{
              transformOrigin: "283.839951px 298.265442px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="eltw55h8fca5e"
          />
          <path
            d="M263.11 316.06h51.25a3.79 3.79 0 003.71-3.5 3.25 3.25 0 00-3.28-3.49h-51.25a3.78 3.78 0 00-3.71 3.49 3.26 3.26 0 003.28 3.5z"
            style={{
              transformOrigin: "288.950012px 312.564941px",
            }}
            className="animable"
            fill="#3d50fa"
            opacity={0.3}
            id="elr1ctay29tdo"
          />
          <path
            d="M360.11 186.1a2 2 0 01-1.16-.36l-10.77-7.35a2.07 2.07 0 012.33-3.41l9.2 6.29c3.36-4.05 12.94-15 20.91-18.59a2.06 2.06 0 111.68 3.77c-8.7 3.87-20.45 18.72-20.56 18.87a2.08 2.08 0 01-1.63.78z"
            style={{
              transformOrigin: "365.458832px 174.300423px",
            }}
            id="el6fpwyilun0d"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M355.1 227.84a2.09 2.09 0 01-1.44-.57l-9.37-9a2.07 2.07 0 112.87-3l8 7.69c3.95-3.53 15-12.93 23.36-15.21a2.06 2.06 0 111.09 4c-9.12 2.49-22.93 15.43-23.07 15.56a2 2 0 01-1.44.53z"
            style={{
              transformOrigin: "362.393768px 217.759125px",
            }}
            id="el2bypnfb72ge"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M337.57 141.73h3a6.12 6.12 0 006.18-6.06v-5.58a.61.61 0 00-.62-.61h-8.48a.7.7 0 00-.69.69l.04 10.95a.61.61 0 00.57.61z"
            style={{
              transformOrigin: "341.854996px 135.60511px",
            }}
            className="animable"
            opacity={0.4}
            id="elmpwg642k67"
          />
          <path
            d="M291.55 119.39h51.06a4.93 4.93 0 015 5.76l-1.53 11.37a6.83 6.83 0 01-6.54 5.76h-51.08a4.93 4.93 0 01-5-5.76l1.54-11.37a6.83 6.83 0 016.55-5.76z"
            style={{
              transformOrigin: "315.535019px 130.834991px",
            }}
            id="elq1u6uf61oge"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M291.55 119.39h51.06a4.93 4.93 0 015 5.76l-1.53 11.37a6.83 6.83 0 01-6.54 5.76h-51.08a4.93 4.93 0 01-5-5.76l1.54-11.37a6.83 6.83 0 016.55-5.76z"
            style={{
              transformOrigin: "315.535019px 130.834991px",
            }}
            className="animable"
            fill="#fff"
            opacity={0.6}
            id="el3tyaycb9z4"
          />
          <path
            d="M341.29 124.89h-23a1.55 1.55 0 110-3.09h23a1.55 1.55 0 010 3.09z"
            style={{
              transformOrigin: "329.665588px 123.345005px",
            }}
            className="animable"
            fill="#fff"
            opacity={0.5}
            id="el1e9vexlb5x"
          />
          <path
            d="M310.64 124.89h-2.42a1.55 1.55 0 110-3.09h2.42a1.55 1.55 0 110 3.09z"
            style={{
              transformOrigin: "309.430008px 123.345005px",
            }}
            className="animable"
            fill="#fff"
            opacity={0.5}
            id="el3cre62g6fr3"
          />
        </g>
        <g
          id="freepik--Character--inject-75"
          style={{
            transformOrigin: "159.22081px 258.229988px",
            animation: "1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft",
            animationDelay: "0s",
          }}
          className="animable"
        >
          <path
            style={{
              transformOrigin: "186.060005px 292.295006px",
            }}
            id="el81egghf6myx"
            className="animable"
            fill="#3d50fa"
            d="M169.46 346.65L179.33 348.54 202.66 238.82 188.17 236.05 169.46 346.65z"
          />
          <path
            style={{
              transformOrigin: "210.469994px 158.379997px",
            }}
            id="eldy4gnvdje56"
            className="animable"
            fill="#263238"
            d="M204.15 162.64L215.97 158.75 216.79 154.46 205.78 154.12 204.15 162.64z"
          />
          <path
            style={{
              transformOrigin: "203.150002px 195.139999px",
            }}
            id="el9e9cc5xp64t"
            className="animable"
            fill="#3d50fa"
            d="M203.85 160.14L217.31 155.76 203.48 234.52 188.99 231.76 203.85 160.14z"
          />
          <path
            style={{
              transformOrigin: "195.825px 235.285px",
            }}
            className="animable"
            fill="#263238"
            transform="rotate(-169.028)"
            id="el3vwc3uhjhuk"
            d="M189.05 233.1H202.60000000000002V237.47H189.05z"
          />
          <path
            d="M171.24 359.64c-.13.65.14 1.25.6 1.34s.94-.37 1.06-1-.15-1.25-.61-1.34-.93.36-1.05 1z"
            style={{
              transformOrigin: "172.067924px 359.809921px",
            }}
            id="el4257d6g8plu"
            className="animable"
            fill="#3d50fa"
          />
          <path
            style={{
              transformOrigin: "174.395004px 353.464996px",
            }}
            id="ell2du8pukrn"
            className="animable"
            fill="#263238"
            d="M170.56 359.72L173.5 360.28 179.33 348.54 169.46 346.65 170.56 359.72z"
          />
          <path
            d="M188.34 211.82a1.25 1.25 0 01-1.13-1.77c6.2-13.29 11.23-41.05 11.91-47.65a5.39 5.39 0 013.24-4.41l3.27-1.41a1.24 1.24 0 111 2.28l-3.27 1.41a2.93 2.93 0 00-1.75 2.39c-.71 6.93-5.7 34.68-12.12 48.44a1.25 1.25 0 01-1.15.72z"
            style={{
              transformOrigin: "197.235909px 184.147507px",
            }}
            id="elwiesdjrrfrl"
            className="animable"
            fill="#263238"
          />
          <path
            d="M142.27 158.79c-2.49 4.27-5 8.8-7.37 13.24-1.17 2.24-2.31 4.48-3.4 6.73s-2.11 4.52-3 6.78l-.17.41a.61.61 0 00-.07.21 5 5 0 00-.07 1 15.14 15.14 0 00.42 2.94 50 50 0 002.2 6.76c.89 2.3 1.91 4.61 3 6.93s2.2 4.64 3.29 6.9l-4.75 3.24a86.84 86.84 0 01-9.41-13.27 48.71 48.71 0 01-3.65-7.76 25.79 25.79 0 01-1.2-4.72 15.21 15.21 0 01-.12-2.93 12.65 12.65 0 01.24-1.72c.06-.29.16-.62.25-.93l.16-.51a110.51 110.51 0 016-15.21 142.3 142.3 0 017.79-14.19z"
            style={{
              transformOrigin: "130.108238px 183.310013px",
            }}
            id="eldgapykmj9wd"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            d="M136.35 209.25l4.47 3.48-6.82 5.64s-3.11-3-2.63-6.69z"
            style={{
              transformOrigin: "136.069824px 213.809998px",
            }}
            id="elbtubxkxwb6w"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            style={{
              transformOrigin: "138.315002px 218.195px",
            }}
            id="elpnootlonbf"
            className="animable"
            fill="#ffc3bd"
            d="M142.64 217.81L138.09 223.66 133.99 218.37 140.82 212.73 142.64 217.81z"
          />
          <path
            d="M168 117.11c.13.6.55 1 .94 1s.62-.64.5-1.24-.55-1-.94-.95-.58.58-.5 1.19z"
            style={{
              transformOrigin: "168.726936px 117.012897px",
            }}
            id="elgpqu48g3h8u"
            className="animable"
            fill="#263238"
          />
          <path
            d="M168.53 118.15a22.22 22.22 0 003.95 4.59 3.57 3.57 0 01-2.79 1.13z"
            style={{
              transformOrigin: "170.504997px 121.012272px",
            }}
            id="el49nlegdu56"
            className="animable"
            fill="#ed847e"
          />
          <path
            d="M165.14 114.2a.37.37 0 01-.24-.1.35.35 0 010-.51 3.54 3.54 0 013.08-1.2.36.36 0 11-.12.71 2.86 2.86 0 00-2.43 1 .36.36 0 01-.29.1z"
            style={{
              transformOrigin: "166.535553px 113.284489px",
            }}
            id="el2inzjzvh9ym"
            className="animable"
            fill="#263238"
          />
          <path
            d="M150.79 125.62c1.1 5.61 2.2 15.88-1.72 19.62 0 0 1.53 5.68 11.94 5.68 11.45 0 5.48-5.68 5.48-5.68-6.25-1.5-6.09-6.13-5-10.49z"
            style={{
              transformOrigin: "158.557838px 138.27px",
            }}
            id="ely9bohhp6hug"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            d="M146.85 145.66c-.83-2 1-8 2.85-8.57 2.09-.64 10-.28 14 1.37 2.81 2.84 3.62 8.73 3.62 8.73z"
            style={{
              transformOrigin: "156.982178px 141.990845px",
            }}
            id="elrkrt2xga"
            className="animable"
            fill="#263238"
          />
          <path
            d="M114.15 407.52a2.46 2.46 0 001.9-.1.72.72 0 00.18-.84.86.86 0 00-.47-.54c-1-.49-3.41.48-3.51.52a.17.17 0 00-.11.16.19.19 0 00.1.17 9 9 0 001.91.63zm1.24-1.22l.22.07a.49.49 0 01.27.31c.1.33 0 .43-.06.46-.41.35-1.94 0-3-.4a5.88 5.88 0 012.57-.44z"
            style={{
              transformOrigin: "114.217499px 406.783295px",
            }}
            id="elsyugkpii0v9"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M112.28 406.91h.09c.87-.25 2.72-1.57 2.74-2.44a.58.58 0 00-.46-.58 1 1 0 00-.81.06c-1 .52-1.67 2.65-1.69 2.74a.17.17 0 000 .18zm2.17-2.71h.08c.22.08.22.19.22.23 0 .52-1.24 1.55-2.13 2a4.36 4.36 0 011.39-2.16.68.68 0 01.44-.07z"
            style={{
              transformOrigin: "113.617176px 405.370697px",
            }}
            id="elm38ba8g7i2j"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M169.44 408.81c1 0 1.94-.14 2.25-.58a.67.67 0 000-.79.89.89 0 00-.58-.42c-1.27-.35-3.95 1.17-4.06 1.24a.18.18 0 00-.09.19.17.17 0 00.14.14 13.19 13.19 0 002.34.22zm1.22-1.49a1.29 1.29 0 01.35.05.49.49 0 01.35.25c.13.22.09.34 0 .4-.36.5-2.29.51-3.72.31a8.1 8.1 0 013.02-1.01z"
            style={{
              transformOrigin: "169.387932px 407.88887px",
            }}
            id="elrhv0ksjolv"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M167.13 408.6h.07c.93-.41 2.76-2.07 2.62-2.94 0-.2-.18-.45-.68-.5a1.28 1.28 0 00-1 .31c-1 .8-1.18 2.86-1.19 2.95a.16.16 0 00.07.16.15.15 0 00.11.02zm1.87-3.11h.11c.32 0 .34.16.35.2.09.52-1.16 1.82-2.1 2.39a4.25 4.25 0 011-2.36 1 1 0 01.64-.23z"
            style={{
              transformOrigin: "168.387718px 406.876877px",
            }}
            id="eltiueahes0ep"
            className="animable"
            fill="#3d50fa"
          />
          <path
            style={{
              transformOrigin: "162.920006px 399.550003px",
            }}
            id="elglwm26qdjk"
            className="animable"
            fill="#ffc3bd"
            d="M158.71 408.42L166.37 408.42 167.13 390.68 159.47 390.68 158.71 408.42z"
          />
          <path
            style={{
              transformOrigin: "109.990002px 397.419998px",
            }}
            id="el59l3hyyunsq"
            className="animable"
            fill="#ffc3bd"
            d="M103.43 404.87L111.12 406.49 116.55 389.97 108.85 388.35 103.43 404.87z"
          />
          <path
            d="M112 405.74l-8.41-1.82a.67.67 0 00-.75.37l-2.92 6.34a1.13 1.13 0 00.81 1.57c2.95.58 4.41.72 8.11 1.52 2.27.49 7.41 1.74 10.55 2.42s4.23-2.34 3-2.9c-5.49-2.51-7.79-4.72-9.27-6.78a1.88 1.88 0 00-1.12-.72z"
            style={{
              transformOrigin: "111.352772px 410.071808px",
            }}
            id="el4xbxxp8jyue"
            className="animable"
            fill="#263238"
          />
          <path
            d="M166.32 407.53H158a.67.67 0 00-.65.52l-1.51 6.81a1.1 1.1 0 001.1 1.36c3-.05 7.38-.22 11.17-.22 4.43 0 2.71.24 7.91.24 3.15 0 4-3.18 2.7-3.47-6-1.31-5.33-1.45-10.51-4.64a3.5 3.5 0 00-1.89-.6z"
            style={{
              transformOrigin: "167.557091px 411.884979px",
            }}
            id="elxrhl2yv33cm"
            className="animable"
            fill="#263238"
          />
          <path
            d="M135.35 147c-7.18 1.64-13.09 20-13.09 20l14.41 11a31.51 31.51 0 007.65-14.16c2.47-9.67-1.63-18.56-8.97-16.84z"
            style={{
              transformOrigin: "133.650177px 162.392662px",
            }}
            id="el6japrrpz3hq"
            className="animable"
            fill="#263238"
          />
          <path
            d="M141.51 160.45c-3.9 3.85-5.17 10.88-5.48 17l.64.49a31.4 31.4 0 007.65-14.16c.15-.57.27-1.15.38-1.71z"
            style={{
              transformOrigin: "140.364998px 169.195px",
            }}
            className="animable"
            opacity={0.2}
            id="elrul33skle2c"
          />
          <path
            d="M178.27 147.36s8.15 9.21.45 66.21h-41c-.28-6.29 3.67-37-2.4-66.6a106.75 106.75 0 0113.72-1.73 148.77 148.77 0 0117.42 0 76.94 76.94 0 0111.81 2.12z"
            style={{
              transformOrigin: "158.674667px 179.277405px",
            }}
            id="elqmpc8ir2zuq"
            className="animable"
            fill="#263238"
          />
          <path
            style={{
              transformOrigin: "163.100006px 395.25499px",
            }}
            className="animable"
            opacity={0.2}
            id="elzk9euw0jto"
            d="M167.13 390.68L166.74 399.83 159.07 399.83 159.46 390.68 167.13 390.68z"
          />
          <path
            style={{
              transformOrigin: "111.300003px 393.419998px",
            }}
            className="animable"
            opacity={0.2}
            id="elwb44qhllvln"
            d="M108.85 388.35L116.55 389.97 113.74 398.49 106.05 396.87 108.85 388.35z"
          />
          <path
            d="M147.59 119.93c2 7.34 2.74 11.74 7.27 14.79 6.82 4.6 15.31-.67 15.57-8.46.24-7-3-17.83-10.9-19.31a10.36 10.36 0 00-11.94 12.98z"
            style={{
              transformOrigin: "158.82209px 121.598408px",
            }}
            id="elrezppin5jk"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            d="M154.91 133.87h-.1a7.44 7.44 0 01-6.07-3.08c-2.3-3.21-5.53-8.78-5.9-15.21 2.92-3.08 13.89-5.11 18.78-4 4.07.93 3.48 12.52-.37 18.79a7.47 7.47 0 01-6.34 3.5z"
            style={{
              transformOrigin: "153.627945px 122.57156px",
            }}
            id="elxsp4xou2uhq"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            d="M154.91 133.87h-.1a7.44 7.44 0 01-6.07-3.08c-2.3-3.21-5.53-8.78-5.9-15.21 2.92-3.08 13.89-5.11 18.78-4 4.07.93 3.48 12.52-.37 18.79a7.47 7.47 0 01-6.34 3.5z"
            style={{
              transformOrigin: "153.627945px 122.57156px",
            }}
            className="animable"
            fill="#263238"
            opacity={0.8}
            id="elkaajdcu0l6"
          />
          <path
            d="M147.29 118.19a6.61 6.61 0 01-6-4.27c-.87-2.6.05-8.09 5.28-8.25s5.77-3.14 4.16-3.75c3.42.25 2.34 2.64 2.34 2.64.91 0 1.77-2.45 7-2.25s7.78-.92 7.11-2.09c2.47.5 1.78 3.5-.31 4.31 0 0 4.95 3.75 6.12 1.69.83 1.5-.81 3.3-7.06 2.48 2.49 3.49-3.41 2.71-5.92 4.29s-9.27 5.45-12.72 5.2z"
            style={{
              transformOrigin: "157.096024px 109.210789px",
            }}
            id="ell0bci9ujmhh"
            className="animable"
            fill="#263238"
          />
          <path
            d="M160.08 119.59a8.66 8.66 0 003.09 4.57c2 1.48 3.48 0 3.34-2.24-.12-2-1.32-5.25-3.51-5.85s-3.49 1.31-2.92 3.52z"
            style={{
              transformOrigin: "163.232582px 120.356827px",
            }}
            id="el0cser08murr"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            d="M164.19 213.57s-8.7 58.54-13.47 80.47c-5.22 24-33.25 103.47-33.25 103.47l-13.71-3s19.6-64.76 24-98.33c3.37-25.79 10-82.65 10-82.65z"
            style={{
              transformOrigin: "133.975002px 305.520004px",
            }}
            id="ele1w0kfytwj5"
            className="animable"
            fill="#263238"
          />
          <path
            d="M120.76 392.94l-2.56 5-15.43-3.34 1.3-4.64z"
            style={{
              transformOrigin: "111.765003px 393.949997px",
            }}
            id="el818e17tsrp5"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M158.69 234.43c-10.05 2-9 39.45-7.77 58.73 2.89-13.63 7.14-39.85 10-58.46a3.46 3.46 0 00-2.23-.27z"
            style={{
              transformOrigin: "155.536163px 263.756943px",
            }}
            className="animable"
            opacity={0.3}
            id="elmnhyndnrsyj"
          />
          <path
            d="M178.72 213.57s1.06 57.27.22 80.63c-.86 24.3-9.35 103.86-9.35 103.86h-13s-.47-78.14-1.05-102c-.63-26-3.77-82.49-3.77-82.49z"
            style={{
              transformOrigin: "165.516869px 305.815002px",
            }}
            id="el525kkjo6bnc"
            className="animable"
            fill="#263238"
          />
          <path
            d="M172.09 392.89c.06 0-.74 5.38-.74 5.38h-15.78l-.43-4.8z"
            style={{
              transformOrigin: "163.616623px 395.580017px",
            }}
            id="elkxhkzkxwrip"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M178.46 146.35c2 .83 3.93 2.32 4.51 7.27.56 4.75-.52 46.38-1.82 59.67a1.92 1.92 0 01-1.69 1.83c-8.19.35-33.56-3.07-42.12-1.57-1 .18-1.87-.92-1.81-1.92.67-12.95-.3-33.85-1.88-47.12-1-8.67-1-17.74 6.23-19.28 5.38-1.14 30.76-2.14 38.58 1.12z"
            style={{
              transformOrigin: "158.151375px 179.642502px",
            }}
            id="elv1epwhy1wnq"
            className="animable"
            fill="#3d50fa"
          />
          <path
            d="M181.63 157.85c.46 2.2 1 4.59 1.5 6.87s1.12 4.59 1.76 6.84 1.3 4.46 2.06 6.57a36.22 36.22 0 002.56 5.79 11.49 11.49 0 001.33 2c.19.21.34.35.38.39s0 .09.19.15a5.33 5.33 0 002 .26 24 24 0 003-.32 88.11 88.11 0 0013.26-3.9l2.91 5a50.42 50.42 0 01-13.72 7.84 25 25 0 01-4.26 1.15 15.57 15.57 0 01-5.32.08 10.87 10.87 0 01-3.25-1.1 13.13 13.13 0 01-2.54-1.85 20.73 20.73 0 01-3.21-3.82 60.81 60.81 0 01-6.51-14.87c-.75-2.47-1.44-4.94-2-7.43s-1.12-4.91-1.55-7.56z"
            style={{
              transformOrigin: "191.400002px 177.305336px",
            }}
            id="el1zgtcryovcr"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            d="M164.78 154.85c-6.22 12.51 8.56 26.65 8.56 26.65l16.48-8.6a90.25 90.25 0 00-5.63-19.32c-4.8-10.68-15.83-5.92-19.41 1.27z"
            style={{
              transformOrigin: "176.536018px 164.415077px",
            }}
            id="elmyjp0iownf9"
            className="animable"
            fill="#263238"
          />
          <path
            d="M208.14 183.2l5.59-3.68 2.32 8.36s-2.5 2.93-7.08-.26z"
            style={{
              transformOrigin: "212.095001px 184.31424px",
            }}
            id="elpchqx1eand"
            className="animable"
            fill="#ffc3bd"
          />
          <path
            d="M218.6 182.9l-.18 1.76a3.36 3.36 0 01-1.14 2.18l-1.23 1-2.32-8.36.71-.18a3.34 3.34 0 014.16 3.6z"
            style={{
              transformOrigin: "216.175163px 183.516235px",
            }}
            id="elilon807tx6h"
            className="animable"
            fill="#ffc3bd"
          />
        </g>
        <defs>
          <filter id="active" height="200%">
            <feMorphology
              in="SourceAlpha"
              result="DILATED"
              operator="dilate"
              radius={2}
            />
            <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
            <feComposite
              in="PINK"
              in2="DILATED"
              operator="in"
              result="OUTLINE"
            />
            <feMerge>
              <feMergeNode in="OUTLINE" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <filter id="hover" height="200%">
            <feMorphology
              in="SourceAlpha"
              result="DILATED"
              operator="dilate"
              radius={2}
            />
            <feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
            <feComposite
              in="PINK"
              in2="DILATED"
              operator="in"
              result="OUTLINE"
            />
            <feMerge>
              <feMergeNode in="OUTLINE" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
            <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          </filter>
        </defs>
      </svg>
    );
  }

  return (
    <RegularPage
      isDataExist={1}
      hasBaseCard={false}
      header={"Справочный центр"}
      isPrimaryPage={true}
    >
      <div className="row">
        <div className="col s12 l4">
          {/* <Link to="how-to-check-tickets"> */}
          <a
            className="iq-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.nott.one/2023/11/17/how-to-check-tickets"
          >
            <div className="card">
              <div className="card-content">
                <div className="row nm">
                  <div className="col s12 l500 mb10">
                    Как проверять билеты участников мероприятия?
                  </div>
                  <div className="col s12">{SvgComponent()}</div>
                  <div className="col s12 tertiary-color">
                    Узнайте, как корректно сканировать билеты участников
                    мероприятия, чтобы на входе не возникало проблем
                  </div>
                </div>
              </div>
            </div>
          </a>
          {/* </Link> */}
        </div>
      </div>
    </RegularPage>
  );
};

export default Help;
