import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Icon, TextInput } from "react-materialize";
import { useNavigate, useParams } from "react-router-dom";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { AuthContext } from "../context";
import { parseForm } from "../utils/service";
import useConfirm from "../context/ConfirmDialog";

const PasswordRecovery = () => {
  const params = useParams();
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [recoveryState, setRecoveryState] = useState(0);

  useEffect(() => {
    checkRecoveryData();
  }, []);

  const setPassword = async (e) => {
    e.preventDefault();

    setFullScreenLoading(true);
    let htmlForm = e.currentTarget;
    let sendFormData = parseForm(htmlForm);
    sendFormData.append("email", params.email);
    sendFormData.append("key", params.key);
    const response = (
      await RequestService.sendForm(sendFormData, "/profile/recoveryPassword")
    ).data;
    // console.log(response);
    setFullScreenLoading(false);

    if (!response.response_code) {
      await confirm({
        description: response.message,
        isAlert: true,
      });
      // alert(response.message);
      return;
    }

    navigate("/login?after=recovery");
  };

  const checkRecoveryData = async () => {
    let response = (
      await RequestService.checkRecoveryData(params.email, params.key)
    ).data;
    if (!response.response_code) {
      // console.error(response);
      navigate("/login");
      return;
    }
    setRecoveryState(response.result);
    // console.log(response);
  };

  return (
    <RegularPage isDataExist={recoveryState} hasBaseCard={false}>
      <div className="row nm">
        <div className="col l3"></div>
        <div className="col s12 l6">
          <div className="card">
            <div className="card-content">
              <div className="row">
                <div className="col s12 xl500 center-align">
                  Придумайте новый пароль
                </div>
              </div>
              <form onSubmit={setPassword}>
                <div className="row nm">
                  <TextInput
                    id="password-1"
                    name="password-1"
                    type="password"
                    label="Новый пароль"
                    s={12}
                    icon={<Icon>lock_outlined</Icon>}
                    onChange={(e) => {
                      //   setLoginData({ ...loginData, login: e.target.value });
                    }}
                    required
                  />

                  <TextInput
                    id="password-2"
                    name="password-2"
                    type="password"
                    label="Подтвердите пароль"
                    s={12}
                    icon={<Icon>lock</Icon>}
                    onChange={(e) => {
                      //   setLoginData({ ...loginData, login: e.target.value });
                    }}
                    required
                  />

                  <div className="col s12 center-align mt15">
                    <input
                      type="submit"
                      className="btn iq-btn btn-flat"
                      value="Сохранить"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col l3"></div>
      </div>

      {/* <div className="col l4"></div>
      <div className="col l4 s12">
        <div className="card light-shadow">
          <div className="card-content">
            <div className="row">
              <div className="col s12 xl500 center-align">Изменение пароля</div>
            </div>

            <form id="recovery-form">
              <div className="row nm">
                <div className="input-field col s12">
                  <i className="material-icons-outlined grey-text prefix">
                    lock
                  </i>
                  <input
                    id="input-password-1"
                    name="input-password-1"
                    type="password"
                    required
                  />
                  <label for="input-password-1">Новый пароль</label>
                </div>

                <div className="input-field col s12">
                  <i className="material-icons grey-text prefix">lock</i>
                  <input
                    id="input-password-2"
                    name="input-password-2"
                    type="password"
                    required
                  />
                  <label for="input-password-2">Подтвердите пароль</label>
                </div>
              </div>

              <div className="row nm center-align">
                <div className="input-field col s12">
                  <button name="send-button" className="btn btn-flat iq-btn">
                    Сохранить
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col l4"></div> */}
    </RegularPage>
  );
};

export default PasswordRecovery;
