import React, { useState, useEffect } from "react";
import RequestService from "../api/RequestService";
import RegularPage from "../components/UI/RegularPage/RegularPage";
import { formatNumber } from "../utils/service";
import noImage from "../images/no-image.png";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../context";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import IconLogoMono from "../images/icon-logo-mono.png";
import { Tab, Tabs, Button } from "react-materialize";
import useConfirm from "../context/ConfirmDialog";

const MyTickets = () => {
  const [ticketsError, setTicketsError] = useState(null);
  const [ticketsData, setTicketsData] = useState(null);
  const [ticketList, setTicketList] = useState(null);
  const [ticketActiveList, setTicketActiveList] = useState(null);
  const [ticketPastList, setTicketPastList] = useState(null);
  const [ticketCanceledList, setTicketCanceledList] = useState(null);
  const [ticketNotExistList, setTicketNotExistList] = useState(null);
  const [ticketProjectRequestsList, setTicketProjectRequestsList] =
    useState(null);
  const { isFullScreenLoading, setFullScreenLoading } = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    getTickets();
  }, []);

  const getTickets = async () => {
    const response = (await RequestService.getUserTickets()).data;
    // console.log(response);
    if (!response.response_code) {
      setTicketsError(response.message);
      return;
    }
    setTicketsData(response.result);
    createTickets(response.result);
  };

  const cancelOrder = async (ticketId, notRefundableHours = null) => {
    // if (
    //   window.confirm(
    //     "Вы действительно хотите отменить участие и вернуть уплаченые денежные средства?"
    //   )
    // ) {
    const choice = await confirm({
      description:
        (notRefundableHours
          ? "По данному событию денежные средства не возвращаются, если участие отменено менее, чем за " +
            notRefundableHours +
            " часов до начала. "
          : "") +
        "Вы действительно хотите отменить участие и вернуть уплаченые денежные средства (если это возможно по условиям события)?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.cancelTicket(ticketId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      await confirm({
        description:
          "Если вы вносили плату за участие, то оно будет аннулировано в течение суток. В течение этого времени участие продолжит отображаться в данном списке. Повторно подавать заявку на отмену участия НЕ НУЖНО.",
        isAlert: true,
      });
      // alert(
      //   "Если вы вносили плату за участие, то оно будет аннулировано в течение суток. В течение этого времени участие продолжит отображаться в данном списке. Повторно подавать заявку на отмену участия НЕ НУЖНО."
      // );

      getTickets();
    }
  };

  const goBroadcast = async (ticketId) => {
    // if (
    //   window.confirm(
    //     "Вы будете перенаправлены на внешний сервис, где организатор собирается провести трансляцию мероприятия. Nott.one не несет ответственности за содержимое ссылки, которую предоставил организатор. Вы уверены, что хотите продолжить?"
    //   )
    // ) {
    const choice = await confirm({
      description:
        "Вы будете перенаправлены на внешний сервис, где организатор собирается провести трансляцию мероприятия. Nott.one не несет ответственности за содержимое ссылки, которую предоставил организатор. Вы уверены, что хотите продолжить?",
    });
    if (choice) {
      setFullScreenLoading(true);
      const response = (await RequestService.goBroadcast(ticketId)).data;
      setFullScreenLoading(false);
      // console.log(response);
      if (!response.response_code) {
        await confirm({
          description: response.message,
          isAlert: true,
        });
        // alert(response.message);
        return;
      }

      if (response.result) {
        window.location.href = response.result;
      }
    }
  };

  const createTickets = (tickets) => {
    let list = [];
    let activeList = [];
    let pastList = [];
    let cancelledList = [];
    let notExistList = [];
    let projectRequestsList = [];

    tickets.map((elem) => {
      elem.ts = new Date(moment(elem.object_datetime)).getTime();
    });
    tickets.sort((a, b) => a.ts - b.ts);

    // console.log(tickets);
    // console.log(
    //   (new Date().getTime() - new Date(tickets[0].object_datetime).getTime()) /
    //     1000 /
    //     60 /
    //     60 /
    //     24 >=
    //     1
    // );
    tickets.forEach((ticket) => {
      let linkTo = "/events";
      if (ticket.object_type == 2) {
        linkTo = "/events/" + ticket.parent_id;
      } else if (ticket.object_type == 3) {
        linkTo = "/projects/" + ticket.parent_id;
      }

      // console.log(ticket);
      // console.log(
      //   (new Date().getTime() - new Date(ticket.object_datetime).getTime()) /
      //     1000 /
      //     60 /
      //     60 /
      //     24
      // );

      let ticketElement = (
        <div className="col s12 mb25" key={ticket.id}>
          <div className="card bg animation-appear">
            <div className="card-content">
              <div className="row nm">
                {ticket.object_name && ticket.object_type == 2 ? (
                  <div className="col s12 l3 center-align">
                    <QRCodeCanvas
                      value={ticket.check_link}
                      fgColor="#030303"
                      bgColor={"transparent"}
                      size={256}
                      level={"M"}
                      style={{ height: "auto", maxWidth: "100%", width: "80%" }}
                      className="image-theme-filter mt10 mb10"
                      imageSettings={{
                        src: IconLogoMono,
                        height: 50,
                        width: 50,
                        excavate: true,
                      }}
                    />
                  </div>
                ) : null}

                {/* <div className="col s12 l3">
              <img
                src={
                  ticket.object_cover_img
                    ? ticket.object_cover_img
                    : noImage
                }
                alt="Обложка билета"
                style={{ width: "100%" }}
                className={
                  !ticket.object_cover_img ? "image-theme-filter" : ""
                }
              />
            </div> */}
                <div
                  className={
                    ticket.object_name && ticket.object_type == 2
                      ? "col s12 l9"
                      : "col s12"
                  }
                >
                  <div className="row nm">
                    <div className="col s12 l500 mb15">
                      {ticket.object_name ? (
                        ticket.object_has_deleted ? (
                          <div>
                            <span>
                              {ticket.object_type == 3 ? "Проект «" : ""}
                              {ticket.object_name}
                              {ticket.object_type == 3 ? "»" : ""}
                            </span>{" "}
                            <span className="tertiary-color">
                              (закрыто организатором)
                            </span>
                          </div>
                        ) : (
                          <Link to={linkTo} className="iq-link">
                            {ticket.object_type == 3 ? "Проект «" : ""}
                            {ticket.object_name}
                            {ticket.object_type == 3 ? "»" : ""}
                          </Link>
                        )
                      ) : (
                        <div className="tertiary-color">
                          Нет информации о событии
                        </div>
                      )}
                    </div>
                    {ticket.object_type == 2 ? (
                      <div className="col s12">
                        <span className="m500">Место:</span>{" "}
                        {!ticket.object_location
                          ? "Не указано"
                          : ticket.object_location +
                            (ticket.object_address
                              ? ", " + ticket.object_address
                              : "") +
                            (ticket.object_place
                              ? ", " + ticket.object_place
                              : "")}
                      </div>
                    ) : null}
                    <div className="col s12">
                      <span className="m500">Пакет участия:</span>{" "}
                      {ticket.package_name}
                    </div>
                    {ticket.object_type == 2 ? (
                      <div className="col s12">
                        <span className="m500">Дата и время:</span>{" "}
                        <span
                          className={
                            new Date().getTime() >
                            new Date(ticket.object_datetime).getTime()
                              ? "error-color"
                              : ""
                          }
                        >
                          {ticket.object_datetime
                            ? moment(ticket.object_datetime).format(
                                "DD.MM.YYYY HH:mm"
                              ) +
                              " (" +
                              (ticket.object_timezone_description
                                ? ticket.object_timezone_description
                                : "время местное") +
                              ")"
                            : "Не указано"}
                        </span>
                      </div>
                    ) : null}
                    <div className="col s12">
                      <span className="m500">
                        {ticket.object_type == 3 ? "Сумма заявки:" : "Цена:"}
                      </span>{" "}
                      {formatNumber(ticket.price)}{" "}
                      {ticket.currency_symbol
                        ? ticket.currency_symbol
                        : ticket.currency_code}
                    </div>
                    {ticket.object_type == 2 ? (
                      <div
                        className={
                          ticket.status == 1
                            ? "col s12 success-color"
                            : "col s12 alert-color"
                        }
                      >
                        <span className="m500">Статус:</span>{" "}
                        {ticket.status == 1 ? "Оплачено" : "Не оплачено"}
                      </div>
                    ) : (
                      <div className="col s12">
                        <span className="m500">Статус:</span> Заявка отправлена
                      </div>
                    )}

                    {ticket.object_type == 2 && ticket.package_psp_mode ? (
                      <div className="col s12 mt10">
                        <Link
                          to={"/packages/" + ticket.package_code + "/users"}
                          className="iq-link"
                        >
                          Смотреть список участников встречи
                        </Link>
                      </div>
                    ) : null}

                    {ticket.cancelled_at ||
                    (new Date().getTime() -
                      new Date(ticket.object_datetime).getTime()) /
                      1000 /
                      60 /
                      60 /
                      24 >=
                      3 ? null : (
                      <div className="col s12 mt15">
                        <Button
                          className={
                            ticket.has_participation_link
                              ? "iq-btn"
                              : "iq-btn disabled"
                          }
                          onClick={() => {
                            goBroadcast(ticket.id);
                          }}
                        >
                          {ticket.has_participation_link
                            ? "Перейти к трансляции"
                            : "Трансляция откроется позже*"}
                        </Button>
                      </div>
                    )}

                    {!ticket.has_participation_link &&
                    ticket.object_type == 2 ? (
                      <div className="col s12 mt15">
                        *Событием или пакетом участия может быть не
                        предусмотрена онлайн-трансляция — в этом случае
                        трансляция не откроется даже после начала мероприятия. О
                        планировании онлайн-трансляции узнавайте на странице
                        события, в подробностях пакета участия или напрямую у
                        организатора.
                      </div>
                    ) : null}

                    {ticket.cancelled_at ||
                    (ticket.object_type == 2 &&
                      new Date().getTime() >
                        new Date(ticket.object_datetime).getTime()) ? null : (
                      <div className="col s12 mt15 right-align">
                        <Button
                          className="white-btn"
                          onClick={() => {
                            cancelOrder(
                              ticket.id,
                              ticket.package_not_refundable_hours
                            );
                          }}
                        >
                          Отменить участие
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      list.push(ticketElement);

      if (!ticket.object_name) {
        notExistList.push(ticketElement);
      } else if (ticket.cancelled_at) {
        cancelledList.push(ticketElement);
      } else if (ticket.object_type == 3) {
        projectRequestsList.push(ticketElement);
        // console.log(ticket);
      } else if (
        (new Date().getTime() - new Date(ticket.object_datetime).getTime()) /
          1000 /
          60 /
          60 /
          24 >=
        3
      ) {
        pastList.push(ticketElement);
      } else {
        activeList.push(ticketElement);
      }
    });

    setTicketList(list);
    setTicketActiveList(activeList);
    setTicketPastList(pastList);
    setTicketCanceledList(cancelledList);
    setTicketNotExistList(notExistList);
    setTicketProjectRequestsList(projectRequestsList);
  };

  return (
    <RegularPage
      error={ticketsError}
      isDataExist={ticketsData}
      header={"Мои участия"}
      isPrimaryPage={true}
      hasBaseCard={true}
    >
      <Tabs className="tabs-fixed-width" scope="tabs-tickets">
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title="Активные"
          className="mt30"
        >
          <div className="row nm">
            {!ticketActiveList || ticketActiveList.length == 0 ? (
              <div className="col s12 center-align">
                У вас пока нет активных участий
              </div>
            ) : (
              ticketActiveList
            )}
          </div>
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Прошедшие"}
          className="mt30"
        >
          <div className="row nm">
            {!ticketPastList || ticketPastList.length == 0 ? (
              <div className="col s12 center-align">
                У вас пока нет прошедших участий
              </div>
            ) : (
              ticketPastList
            )}
          </div>
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Отмененные"}
          className="mt30"
        >
          <div className="row nm">
            {!ticketCanceledList || ticketCanceledList.length == 0 ? (
              <div className="col s12 center-align">
                У вас пока нет отмененных участий
              </div>
            ) : (
              ticketCanceledList
            )}
          </div>
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Заявки в проекты"}
          className="mt30"
        >
          <div className="row nm">
            {!ticketProjectRequestsList ||
            ticketProjectRequestsList.length == 0 ? (
              <div className="col s12 center-align">
                У вас пока нет заявок в проекты
              </div>
            ) : (
              ticketProjectRequestsList
            )}
          </div>
        </Tab>
        <Tab
          options={{
            duration: 300,
            onShow: null,
            responsiveThreshold: Infinity,
            swipeable: false,
          }}
          title={"Несуществующие"}
          className="mt30"
        >
          <div className="row nm">
            {!ticketNotExistList || ticketNotExistList.length == 0 ? (
              <div className="col s12 center-align">
                У вас пока нет участий в несуществующих событиях. Сюда попадают
                участия в событиях, история о которых была полностью уделена из
                системы Nott.one
              </div>
            ) : (
              ticketNotExistList
            )}
          </div>
        </Tab>
      </Tabs>
    </RegularPage>
  );
};

export default MyTickets;
